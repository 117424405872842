import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    margin: 4px;
    border: 3px solid gray;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: gray transparent transparent transparent;
  }
  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

export const Spinner = () => (
  <Container>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </Container>
);
