import React from 'react';
import styled from 'styled-components';

type BtnType = 'primary' | 'secondary';

interface ButtonProps {
  title: string;
  type: BtnType;
  disabled: boolean;
  color: string;
  onPress: () => void;
}

const Container = styled.button<{ btnType: BtnType; color: string }>`
  font-size: 20px;
  font-family: ProximaNova-Bold;
  background-color: ${(props) =>
    props.btnType === 'secondary' ? 'transparent' : props.color};
  border-radius: 10px;
  padding: 10px 16px;
  letter-spacing: ${(props) =>
    props.btnType === 'secondary' ? 'initial' : '0.1em'};
  color: ${(props) => (props.btnType === 'secondary' ? props.color : 'white')};
  border: none;
  width: 100%;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`;

export const Button: React.FC<ButtonProps> = ({
  title,
  type,
  disabled,
  onPress,
  color,
}) => {
  return (
    <Container
      onClick={onPress}
      color={color}
      disabled={disabled}
      btnType={type}
    >
      {title}
    </Container>
  );
};
