// Generated by purs version 0.13.8
"use strict";

var $foreign = require("./foreign.js");

var Data_Function_Uncurried = require("../Data.Function.Uncurried/index.js");

var unsafeUnion = Data_Function_Uncurried.runFn2($foreign.unsafeUnionFn);
module.exports = {
  unsafeUnion: unsafeUnion,
  unsafeUnionFn: $foreign.unsafeUnionFn
};