import React from 'react';
import styled from 'styled-components';
import { Button } from '../../components/Button';
import { InputGroup } from '../../components/InputGroup';
import { getVendorLogo } from 'js/utils';
import { zenv } from 'js/constants';
import { Eff } from 'js/types';
import { Container } from './Common';
import { HoldingTheme } from 'js/client/contexts/HoldingTheme';

interface Props {
  vendorId: string;
  initialCode: string;
  onSubmit: (v: string) => Eff<void>;
  codeLength: number;
}

const VendorLogo = styled.img`
  max-height: 100px;
  max-width: 200px;
`;
const Msg = styled.p`
  font-size: 18px;
  font-family: ProximaNova-Regular;
  letter-spacing: 0.05em;
  margin-top: 40px;
  margin-bottom: 25px;
  text-align: center;
`;

const RedemptionPreview = styled.img`
  height: 121px;
  margin-bottom: 60px;
`;




export const EnterRedemptionCode: React.FC<Props> = ({
  onSubmit,
  vendorId,
  initialCode,
  codeLength,
}) => {
  const placeholder = Array(codeLength)
      .fill('X')
      .join('');
  const [code, onCodeChange] = React.useState(initialCode);
  const { color } = React.useContext(HoldingTheme);
  return (
    <Container>
      <VendorLogo src={getVendorLogo(zenv, vendorId)} />
      <Msg>Input the redemption code found in your rewards inventory</Msg>
      <RedemptionPreview
        src={require('assets/images/redemption-code-preview.png')}
      />
      <InputGroup
        placeholder={placeholder}
        label="Redemption Code"
        value={code}
        maxLength={codeLength}
        onChange={(v) => () => onCodeChange(v)}
      />
      <br />
      <br />
      <br />
      <Button
        title="SUBMIT"
        onPress={() => {
          onSubmit(code)();
        }}
        type="primary"
        disabled={code.length < codeLength}
        color={color}
      />
    </Container>
  );
};
