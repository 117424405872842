// Generated by purs version 0.13.8
"use strict";

var $foreign = require("./foreign.js");

var Control_Apply = require("../Control.Apply/index.js");

var Data_Boolean = require("../Data.Boolean/index.js");

var Data_Bounded = require("../Data.Bounded/index.js");

var Data_CommutativeRing = require("../Data.CommutativeRing/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");

var Data_Int = require("../Data.Int/index.js");

var Data_Long_FFI = require("../Data.Long.FFI/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Nullable = require("../Data.Nullable/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Ordering = require("../Data.Ordering/index.js");

var Data_Ring = require("../Data.Ring/index.js");

var Data_Semiring = require("../Data.Semiring/index.js");

var Data_Show = require("../Data.Show/index.js");

var Test_QuickCheck_Arbitrary = require("../Test.QuickCheck.Arbitrary/index.js");

var Test_QuickCheck_Gen = require("../Test.QuickCheck.Gen/index.js");

var SignProxy = function () {
  function SignProxy() {}

  ;
  SignProxy.value = new SignProxy();
  return SignProxy;
}();

var Long$prime = function (x) {
  return x;
};

var SInfo = function (ffiSignedness, toInt) {
  this.ffiSignedness = ffiSignedness;
  this.toInt = toInt;
};

var zshr = function (v) {
  return function (v1) {
    return Long$prime(Data_Long_FFI.shiftRightUnsigned(v)(v1));
  };
};

var xor = function (v) {
  return function (v1) {
    return Long$prime(Data_Long_FFI.xor(v)(v1));
  };
};

var unsignedToSigned = function (v) {
  return Long$prime(Data_Long_FFI.toSigned(v));
};

var unsignedProxy = SignProxy.value;

var toStringAs = function (r) {
  return function (v) {
    return Data_Long_FFI.toString(v)(r);
  };
};

var toString = toStringAs(Data_Int.decimal);

var toNumber = function (v) {
  return Data_Long_FFI.toNumber(v);
};

var toInt = function (dict) {
  return dict.toInt;
};

var signedToUnsigned = function (v) {
  return Long$prime(Data_Long_FFI.toUnsigned(v));
};

var signedProxy = SignProxy.value;

var shr = function (v) {
  return function (v1) {
    return Long$prime(Data_Long_FFI.shiftRight(v)(v1));
  };
};

var showLong$prime = new Data_Show.Show(function (v) {
  return Data_Show.show(Data_Long_FFI.showLong)(v);
});

var shl = function (v) {
  return function (v1) {
    return Long$prime(Data_Long_FFI.shiftLeft(v)(v1));
  };
};

var semiringLong$primeUnsigned = new Data_Semiring.Semiring(function (v) {
  return function (v1) {
    return Long$prime(Data_Long_FFI.add(v)(v1));
  };
}, function (v) {
  return function (v1) {
    return Long$prime(Data_Long_FFI.multiply(v)(v1));
  };
}, Long$prime(Data_Long_FFI.uone), Long$prime(Data_Long_FFI.uzero));
var semiringLong$primeSigned = new Data_Semiring.Semiring(function (v) {
  return function (v1) {
    return Long$prime(Data_Long_FFI.add(v)(v1));
  };
}, function (v) {
  return function (v1) {
    return Long$prime(Data_Long_FFI.multiply(v)(v1));
  };
}, Long$prime(Data_Long_FFI.one), Long$prime(Data_Long_FFI.zero));

var safeReadLong = function (s) {
  return function (isSigned) {
    return function (radix) {
      return Data_Nullable.toMaybe($foreign["_safeReadLong"](s, isSigned, radix));
    };
  };
};

var ringLong$primeUnsigned = new Data_Ring.Ring(function () {
  return semiringLong$primeUnsigned;
}, function (v) {
  return function (v1) {
    return Long$prime(Data_Long_FFI.subtract(v)(v1));
  };
});
var ringLong$primeSigned = new Data_Ring.Ring(function () {
  return semiringLong$primeSigned;
}, function (v) {
  return function (v1) {
    return Long$prime(Data_Long_FFI.subtract(v)(v1));
  };
});

var rem = function (dictSemiring) {
  return function (v) {
    return function (v1) {
      if (Data_Long_FFI.isZero(v1)) {
        return Data_Semiring.zero(dictSemiring);
      }

      ;

      if (Data_Boolean.otherwise) {
        return Long$prime(Data_Long_FFI.modulo(v)(v1));
      }

      ;
      throw new Error("Failed pattern match at Data.Long.Internal (line 220, column 1 - line 220, column 69): " + [v.constructor.name, v1.constructor.name]);
    };
  };
};

var quot = function (dictSemiring) {
  return function (v) {
    return function (v1) {
      if (Data_Long_FFI.isZero(v1)) {
        return Data_Semiring.zero(dictSemiring);
      }

      ;

      if (Data_Boolean.otherwise) {
        return Long$prime(Data_Long_FFI.divide(v)(v1));
      }

      ;
      throw new Error("Failed pattern match at Data.Long.Internal (line 215, column 1 - line 215, column 70): " + [v.constructor.name, v1.constructor.name]);
    };
  };
};

var positive = function (v) {
  return Data_Long_FFI.isPositive(v);
};

var or = function (v) {
  return function (v1) {
    return Long$prime(Data_Long_FFI.or(v)(v1));
  };
};

var negative = function (v) {
  return Data_Long_FFI.isNegative(v);
};

var lowBits = function (v) {
  return $foreign.numberBitsToInt(Data_Long_FFI.getLowBits(v));
};

var isNumberInLongRange = function (dictBounded) {
  return function (p) {
    return function (n) {
      var longTopValueN = toNumber(Data_Bounded.top(dictBounded));
      var longBottomValueN = toNumber(Data_Bounded.bottom(dictBounded));
      return longBottomValueN <= n && n <= longTopValueN;
    };
  };
};

var highBits = function (v) {
  return $foreign.numberBitsToInt(Data_Long_FFI.getHighBits(v));
};

var infoSigned = new SInfo(function (v) {
  return false;
}, function (l) {
  var low = lowBits(l);
  var high = highBits(l);
  var $93 = high === 0 && low >= 0 || high === (-1 | 0) && low < 0;

  if ($93) {
    return new Data_Maybe.Just(low);
  }

  ;
  return Data_Maybe.Nothing.value;
});
var infoUnsigned = new SInfo(function (v) {
  return true;
}, function (l) {
  var low = lowBits(l);
  var high = highBits(l);
  var $94 = high === 0 && low > 0;

  if ($94) {
    return new Data_Maybe.Just(low);
  }

  ;
  return Data_Maybe.Nothing.value;
});

var ffiSignedness = function (dict) {
  return dict.ffiSignedness;
};

var fromLowHighBits = function (dictSInfo) {
  return function (l) {
    return function (h) {
      return Long$prime(Data_Long_FFI.fromBits(l, h, ffiSignedness(dictSInfo)(SignProxy.value)));
    };
  };
};

var fromNumber = function (dictSInfo) {
  return function (dictBounded) {
    return function (n) {
      var isValidNumber = $foreign.isWholeNumber(n) && isNumberInLongRange(dictBounded)(SignProxy.value)(n);

      if (isValidNumber) {
        return Data_Maybe.Just.create(Long$prime(Data_Long_FFI.fromNumber(n, ffiSignedness(dictSInfo)(SignProxy.value))));
      }

      ;
      return Data_Maybe.Nothing.value;
    };
  };
};

var fromStringAs = function (dictSInfo) {
  return function (radix) {
    return function (s) {
      return Data_Functor.map(Data_Maybe.functorMaybe)(Long$prime)(safeReadLong(s)(ffiSignedness(dictSInfo)(SignProxy.value))(radix));
    };
  };
};

var fromString = function (dictSInfo) {
  return fromStringAs(dictSInfo)(Data_Int.decimal);
};

var unsafeFromInt = function (dictSInfo) {
  return function (i) {
    return Long$prime(Data_Long_FFI.fromInt(i, ffiSignedness(dictSInfo)(SignProxy.value)));
  };
};

var signedLongFromInt = unsafeFromInt(infoSigned);

var unsignedLongFromInt = function (i) {
  if (i >= 0) {
    return Data_Maybe.Just.create(unsafeFromInt(infoUnsigned)(i));
  }

  ;

  if (Data_Boolean.otherwise) {
    return Data_Maybe.Nothing.value;
  }

  ;
  throw new Error("Failed pattern match at Data.Long.Internal (line 154, column 1 - line 154, column 53): " + [i.constructor.name]);
};

var even = function (v) {
  return Data_Long_FFI.isEven(v);
};

var odd = function () {
  var $107 = Data_HeytingAlgebra.not(Data_HeytingAlgebra.heytingAlgebraBoolean);
  return function ($108) {
    return $107(even($108));
  };
}();

var parity = function (l) {
  if (even(l)) {
    return Data_Int.Even.value;
  }

  ;

  if (Data_Boolean.otherwise) {
    return Data_Int.Odd.value;
  }

  ;
  throw new Error("Failed pattern match at Data.Long.Internal (line 199, column 1 - line 199, column 38): " + [l.constructor.name]);
};

var eqLong$prime = new Data_Eq.Eq(function (v) {
  return function (v1) {
    return Data_Long_FFI.equals(v)(v1);
  };
});
var ordLong$prime = new Data_Ord.Ord(function () {
  return eqLong$prime;
}, function (v) {
  return function (v1) {
    var v2 = Data_Long_FFI.compare(v)(v1);

    if (v2 === 0) {
      return Data_Ordering.EQ.value;
    }

    ;

    if (v2 > 0) {
      return Data_Ordering.GT.value;
    }

    ;
    return Data_Ordering.LT.value;
  };
});

var complement = function (v) {
  return Long$prime(Data_Long_FFI.not(v));
};

var commutativeRingLong$primeUnsigned = new Data_CommutativeRing.CommutativeRing(function () {
  return ringLong$primeUnsigned;
});
var euclideanRingLong$primeUnsigned = new Data_EuclideanRing.EuclideanRing(function () {
  return commutativeRingLong$primeUnsigned;
}, function () {
  var $109 = Data_Ord.abs(ordLong$prime)(ringLong$primeUnsigned);
  return function ($110) {
    return Data_Int.floor(toNumber($109($110)));
  };
}(), quot(semiringLong$primeUnsigned), rem(semiringLong$primeUnsigned));
var commutativeRingLong$primeSigned = new Data_CommutativeRing.CommutativeRing(function () {
  return ringLong$primeSigned;
});
var euclideanRingLong$primeSigned = new Data_EuclideanRing.EuclideanRing(function () {
  return commutativeRingLong$primeSigned;
}, function () {
  var $111 = Data_Ord.abs(ordLong$prime)(ringLong$primeSigned);
  return function ($112) {
    return Data_Int.floor(toNumber($111($112)));
  };
}(), function (l1) {
  return function (l2) {
    return quot(semiringLong$primeSigned)(Data_Ring.sub(ringLong$primeSigned)(l1)(Data_EuclideanRing.mod(euclideanRingLong$primeSigned)(l1)(l2)))(l2);
  };
}, function (l1) {
  return function (l2) {
    var l2$prime = Data_Ord.abs(ordLong$prime)(ringLong$primeSigned)(l2);
    return rem(semiringLong$primeSigned)(Data_Semiring.add(semiringLong$primeSigned)(rem(semiringLong$primeSigned)(l1)(l2$prime))(l2$prime))(l2$prime);
  };
});
var boundedLong$primeUnsigned = new Data_Bounded.Bounded(function () {
  return ordLong$prime;
}, Long$prime(Data_Long_FFI.uzero), Long$prime(Data_Long_FFI.maxUnsignedValue));
var boundedLong$primeSigned = new Data_Bounded.Bounded(function () {
  return ordLong$prime;
}, Long$prime(Data_Long_FFI.minValue), Long$prime(Data_Long_FFI.maxValue));

var arbitraryLong$prime = function (dictSInfo) {
  return new Test_QuickCheck_Arbitrary.Arbitrary(Control_Apply.apply(Test_QuickCheck_Gen.applyGen)(Data_Functor.map(Test_QuickCheck_Gen.functorGen)(fromLowHighBits(dictSInfo))(Test_QuickCheck_Arbitrary.arbitrary(Test_QuickCheck_Arbitrary.arbInt)))(Test_QuickCheck_Arbitrary.arbitrary(Test_QuickCheck_Arbitrary.arbInt)));
};

var and = function (v) {
  return function (v1) {
    return Long$prime(Data_Long_FFI.and(v)(v1));
  };
};

module.exports = {
  SInfo: SInfo,
  ffiSignedness: ffiSignedness,
  toInt: toInt,
  SignProxy: SignProxy,
  signedLongFromInt: signedLongFromInt,
  unsignedLongFromInt: unsignedLongFromInt,
  unsafeFromInt: unsafeFromInt,
  fromLowHighBits: fromLowHighBits,
  fromNumber: fromNumber,
  fromString: fromString,
  fromStringAs: fromStringAs,
  highBits: highBits,
  lowBits: lowBits,
  toString: toString,
  toStringAs: toStringAs,
  toNumber: toNumber,
  parity: parity,
  even: even,
  odd: odd,
  positive: positive,
  negative: negative,
  quot: quot,
  rem: rem,
  and: and,
  or: or,
  xor: xor,
  shl: shl,
  shr: shr,
  zshr: zshr,
  complement: complement,
  signedToUnsigned: signedToUnsigned,
  unsignedToSigned: unsignedToSigned,
  infoSigned: infoSigned,
  infoUnsigned: infoUnsigned,
  "showLong'": showLong$prime,
  "eqLong'": eqLong$prime,
  "ordLong'": ordLong$prime,
  "boundedLong'Signed": boundedLong$primeSigned,
  "boundedLong'Unsigned": boundedLong$primeUnsigned,
  "semiringLong'Signed": semiringLong$primeSigned,
  "semiringLong'Unsigned": semiringLong$primeUnsigned,
  "ringLong'Signed": ringLong$primeSigned,
  "ringLong'Unsigned": ringLong$primeUnsigned,
  "commutativeRingLong'Signed": commutativeRingLong$primeSigned,
  "commutativeRingLong'Unsigned": commutativeRingLong$primeUnsigned,
  "euclideanRingLong'Signed": euclideanRingLong$primeSigned,
  "euclideanRingLong'Unsigned": euclideanRingLong$primeUnsigned,
  "arbitraryLong'": arbitraryLong$prime,
  numberBitsToInt: $foreign.numberBitsToInt
};