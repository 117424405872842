// Generated by purs version 0.13.8
"use strict";

var $foreign = require("./foreign.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");

var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");

var Control_Promise = require("../Control.Promise/index.js");

var Data_Bifunctor = require("../Data.Bifunctor/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_Symbol = require("../Data.Symbol/index.js");

var Effect = require("../Effect/index.js");

var Effect_Aff = require("../Effect.Aff/index.js");

var Effect_Class = require("../Effect.Class/index.js");

var React_Basic = require("../React.Basic/index.js");

var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");

var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");

var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");

var Type_Equality = require("../Type.Equality/index.js");

var ZAP_Trovex_Client_ApiClient = require("../ZAP.Trovex.Client.ApiClient/index.js");

var ZAP_Trovex_Client_Hooks_UseGoogleRecaptcha = require("../ZAP.Trovex.Client.Hooks.UseGoogleRecaptcha/index.js");

var ZAP_Trovex_Client_Hooks_UseScreenManager = require("../ZAP.Trovex.Client.Hooks.UseScreenManager/index.js");

var ZAP_Trovex_Client_Utils_PathDupe = require("../ZAP.Trovex.Client.Utils.PathDupe/index.js");

var ZAP_Trovex_Common = require("../ZAP.Trovex.Common/index.js");

var ApiErr = function () {
  function ApiErr(value0) {
    this.value0 = value0;
  }

  ;

  ApiErr.create = function (value0) {
    return new ApiErr(value0);
  };

  return ApiErr;
}();

var IncompleteData = function () {
  function IncompleteData() {}

  ;
  IncompleteData.value = new IncompleteData();
  return IncompleteData;
}();

var CaptchaErr = function () {
  function CaptchaErr() {}

  ;
  CaptchaErr.value = new CaptchaErr();
  return CaptchaErr;
}();

var InvalidMobile = function () {
  function InvalidMobile() {}

  ;
  InvalidMobile.value = new InvalidMobile();
  return InvalidMobile;
}();

var EnterRedemptionCode = function () {
  function EnterRedemptionCode() {}

  ;
  EnterRedemptionCode.value = new EnterRedemptionCode();
  return EnterRedemptionCode;
}();

var EnterMobile = function () {
  function EnterMobile() {}

  ;
  EnterMobile.value = new EnterMobile();
  return EnterMobile;
}();

var ConfirmRedemption = function () {
  function ConfirmRedemption() {}

  ;
  ConfirmRedemption.value = new ConfirmRedemption();
  return ConfirmRedemption;
}();

var ViewSuccessRedeem = function () {
  function ViewSuccessRedeem() {}

  ;
  ViewSuccessRedeem.value = new ViewSuccessRedeem();
  return ViewSuccessRedeem;
}();

var smart = ZAP_Trovex_Common.Smart.value;
var initialState = {
  mobile: Data_Maybe.Nothing.value,
  productId: Data_Maybe.Nothing.value,
  productImg: Data_Maybe.Nothing.value,
  productName: Data_Maybe.Nothing.value,
  code: Data_Maybe.Nothing.value,
  secret: Data_Maybe.Nothing.value,
  expiry: Data_Maybe.Nothing.value,
  status: Data_Maybe.Nothing.value
};
var initialScreen = EnterRedemptionCode.value;
var globe = ZAP_Trovex_Common.Globe.value;
var genericScreens = new Data_Generic_Rep.Generic(function (x) {
  if (x instanceof EnterRedemptionCode) {
    return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
  }

  ;

  if (x instanceof EnterMobile) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
  }

  ;

  if (x instanceof ConfirmRedemption) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
  }

  ;

  if (x instanceof ViewSuccessRedeem) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
  }

  ;
  throw new Error("Failed pattern match at ZAP.Trovex.Client.Flows.RedemptionFlow (line 36, column 1 - line 36, column 54): " + [x.constructor.name]);
}, function (x) {
  if (x instanceof Data_Generic_Rep.Inl) {
    return EnterRedemptionCode.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
    return EnterMobile.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl) {
    return ConfirmRedemption.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr) {
    return ViewSuccessRedeem.value;
  }

  ;
  throw new Error("Failed pattern match at ZAP.Trovex.Client.Flows.RedemptionFlow (line 36, column 1 - line 36, column 54): " + [x.constructor.name]);
});
var pathDupeScreens = new ZAP_Trovex_Client_Utils_PathDupe.PathDupe(ZAP_Trovex_Client_Utils_PathDupe.genericFromPath(genericScreens)(ZAP_Trovex_Client_Utils_PathDupe.sumPathDupe(ZAP_Trovex_Client_Utils_PathDupe.constructorNoArgPathDup(new Data_Symbol.IsSymbol(function () {
  return "EnterRedemptionCode";
})))(ZAP_Trovex_Client_Utils_PathDupe.sumPathDupe(ZAP_Trovex_Client_Utils_PathDupe.constructorNoArgPathDup(new Data_Symbol.IsSymbol(function () {
  return "EnterMobile";
})))(ZAP_Trovex_Client_Utils_PathDupe.sumPathDupe(ZAP_Trovex_Client_Utils_PathDupe.constructorNoArgPathDup(new Data_Symbol.IsSymbol(function () {
  return "ConfirmRedemption";
})))(ZAP_Trovex_Client_Utils_PathDupe.constructorNoArgPathDup(new Data_Symbol.IsSymbol(function () {
  return "ViewSuccessRedeem";
})))))), ZAP_Trovex_Client_Utils_PathDupe.genericToPath(genericScreens)(ZAP_Trovex_Client_Utils_PathDupe.sumPathDupe(ZAP_Trovex_Client_Utils_PathDupe.constructorNoArgPathDup(new Data_Symbol.IsSymbol(function () {
  return "EnterRedemptionCode";
})))(ZAP_Trovex_Client_Utils_PathDupe.sumPathDupe(ZAP_Trovex_Client_Utils_PathDupe.constructorNoArgPathDup(new Data_Symbol.IsSymbol(function () {
  return "EnterMobile";
})))(ZAP_Trovex_Client_Utils_PathDupe.sumPathDupe(ZAP_Trovex_Client_Utils_PathDupe.constructorNoArgPathDup(new Data_Symbol.IsSymbol(function () {
  return "ConfirmRedemption";
})))(ZAP_Trovex_Client_Utils_PathDupe.constructorNoArgPathDup(new Data_Symbol.IsSymbol(function () {
  return "ViewSuccessRedeem";
})))))));

var convertApiM = function (api) {
  return Control_Monad_Except_Trans.ExceptT(Data_Functor.mapFlipped(Effect_Aff.functorAff)(Control_Monad_Except_Trans.runExceptT(api))(Data_Bifunctor.lmap(Data_Either.bifunctorEither)(function (clientErr) {
    return new ApiErr(clientErr);
  })));
};

var redemptionFlow = React_Basic_Hooks.component()()("RedemptionFlow")(function (v) {
  return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(initialState))(function (v1) {
    return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(false))(function (v2) {
      return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(Data_Maybe.Nothing.value))(function (v3) {
        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(ZAP_Trovex_Client_Hooks_UseScreenManager.useScreenManager(pathDupeScreens)(initialScreen)(v.baseUrl))(function (v4) {
          return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(ZAP_Trovex_Client_Hooks_UseGoogleRecaptcha.useGoogleRecaptcha)(function (v5) {
            var vendorName = Data_Show.show(ZAP_Trovex_Common.showVendor)(v.vendor);

            var status = function () {
              if (v1.value0.status instanceof Data_Maybe.Just) {
                return {
                  title: Data_Maybe.fromMaybe("")(v1.value0.status.value0.title),
                  message: Data_Maybe.fromMaybe("")(v1.value0.status.value0.message),
                  type: Data_Maybe.fromMaybe("")(v1.value0.status.value0.type)
                };
              }

              ;
              return {
                title: "",
                message: "",
                type: ""
              };
            }();

            var setAD = function (v6) {
              return v3.value1(Data_Function["const"](Data_Maybe.Just.create({
                onClose: v3.value1(Data_Function["const"](Data_Maybe.Nothing.value)),
                onAction: v3.value1(Data_Function["const"](Data_Maybe.Nothing.value)),
                actionLabel: "Dismiss",
                title: v6.title,
                message: v6.message
              })));
            };

            var sessionExpiredErr = {
              title: "Session Expired",
              message: "Your session has expired due to inactivity"
            };
            var secret = Data_Maybe.fromMaybe("")(v1.value0.secret);
            var restart = Control_Apply.applySecond(Effect.applyEffect)(Control_Apply.applySecond(Effect.applyEffect)(v1.value1(Data_Function["const"](initialState)))(v3.value1(Data_Function["const"](Data_Maybe.Nothing.value))))(v4.reset);

            var redeemCode = function () {
              var unformatMobileNumber = function (m) {
                return Control_Monad_Except_Trans.ExceptT(Data_Functor.mapFlipped(Effect_Aff.functorAff)(Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(Data_Functor.mapFlipped(Effect_Aff.functorAff)(Effect_Class.liftEffect(Effect_Aff.monadEffectAff)($foreign.getNationalNumber(m)))(function (nm) {
                  return "63" + nm;
                })))(Data_Bifunctor.lmap(Data_Either.bifunctorEither)(Data_Function["const"](InvalidMobile.value))));
              };

              var getPayload = Control_Monad_Except_Trans.ExceptT(Control_Applicative.pure(Effect_Aff.applicativeAff)(Data_Either.note(IncompleteData.value)(Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(function (c) {
                return function (s) {
                  return function (e) {
                    return function (m) {
                      return {
                        code: c,
                        secret: s,
                        expiry: e,
                        mobile: m
                      };
                    };
                  };
                };
              })(v1.value0.code))(v1.value0.secret))(v1.value0.expiry))(v1.value0.mobile))));
              return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff))(getPayload)(function (payload) {
                return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff))(unformatMobileNumber(payload.mobile))(function (unformatMobileNum) {
                  return convertApiM(ZAP_Trovex_Client_ApiClient.apiClient.redeemCode({
                    vendor: v.vendor,
                    code: payload.code,
                    secret: payload.secret,
                    expiry: payload.expiry,
                    mobileNumber: unformatMobileNum
                  }));
                });
              });
            }();

            var productName = Data_Maybe.fromMaybe("--")(v1.value0.productName);
            var productImg = Data_Maybe.fromMaybe("")(v1.value0.productImg);
            var mobile = Data_Maybe.fromMaybe("")(v1.value0.mobile);
            var invalidMobileErr = {
              title: "Invalid Mobile",
              message: "Make sure to enter a valid " + (vendorName + " number")
            };
            var invalidCodeErr = {
              title: "Sorry, we don't recognize that code",
              message: "Input codes for " + (vendorName + " rewards only")
            };
            var getCaptchaToken = Control_Promise.toAffE(v5.executeRecaptcha);

            var validateCode = function (c) {
              return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Effect_Aff.monadAff))(Control_Monad_Except_Trans.ExceptT(Data_Functor.mapFlipped(Effect_Aff.functorAff)(Control_Monad_Error_Class["try"](Effect_Aff.monadErrorAff)(getCaptchaToken))(Data_Bifunctor.lmap(Data_Either.bifunctorEither)(Data_Function["const"](CaptchaErr.value)))))(function (captchaToken) {
                return convertApiM(ZAP_Trovex_Client_ApiClient.apiClient.validateCode({
                  captchaToken: captchaToken,
                  code: c,
                  vendor: v.vendor
                }));
              });
            };

            var genericErrMessage = {
              title: "Something went wrong",
              message: "Unable to process your request"
            };
            var code = Data_Maybe.fromMaybe("")(v1.value0.code);
            var captchaErr = {
              title: "Verification Failed",
              message: "Failed to verify ReCaptcha. Please try again."
            };

            var runSubM = function (subCall) {
              return function (onResolve) {
                return Control_Apply.applySecond(Effect.applyEffect)(v2.value1(Data_Function["const"](true)))(Effect_Aff.runAff_(function (e) {
                  return Control_Apply.applySecond(Effect.applyEffect)(v2.value1(Data_Function["const"](false)))(function () {
                    if (e instanceof Data_Either.Left) {
                      return setAD({
                        title: "We've lost connection",
                        message: "Please make sure you're connected to the internet"
                      });
                    }

                    ;

                    if (e instanceof Data_Either.Right) {
                      if (e.value0 instanceof Data_Either.Left && e.value0.value0 instanceof ApiErr) {
                        return setAD(genericErrMessage);
                      }

                      ;

                      if (e.value0 instanceof Data_Either.Left && e.value0.value0 instanceof IncompleteData) {
                        return v3.value1(Data_Function["const"](new Data_Maybe.Just({
                          title: "Incomplete input",
                          message: "Please make sure you've given all necessary input",
                          actionLabel: "Restart",
                          onClose: v3.value1(Data_Function["const"](Data_Maybe.Nothing.value)),
                          onAction: restart
                        })));
                      }

                      ;

                      if (e.value0 instanceof Data_Either.Left && e.value0.value0 instanceof CaptchaErr) {
                        return setAD(captchaErr);
                      }

                      ;

                      if (e.value0 instanceof Data_Either.Left && e.value0.value0 instanceof InvalidMobile) {
                        return setAD(invalidMobileErr);
                      }

                      ;

                      if (e.value0 instanceof Data_Either.Right) {
                        return onResolve(e.value0.value0);
                      }

                      ;
                      throw new Error("Failed pattern match at ZAP.Trovex.Client.Flows.RedemptionFlow (line 236, column 38 - line 252, column 59): " + [e.value0.constructor.name]);
                    }

                    ;
                    throw new Error("Failed pattern match at ZAP.Trovex.Client.Flows.RedemptionFlow (line 234, column 24 - line 252, column 59): " + [e.constructor.name]);
                  }());
                })(Control_Monad_Except_Trans.runExceptT(subCall)));
              };
            };

            var alreadyUsedErr = {
              title: "Nothing's here",
              message: "Looks like this code has already been used"
            };
            var onRedeem = runSubM(redeemCode)(function (v6) {
              if (v6 instanceof Data_Either.Left) {
                if (v6.value0 instanceof ZAP_Trovex_Common.RedeemCodeErrInvalidMobileNumber) {
                  return setAD(invalidMobileErr);
                }

                ;

                if (v6.value0 instanceof ZAP_Trovex_Common.RedeemCodeErrInvalidCode) {
                  return setAD(invalidCodeErr);
                }

                ;

                if (v6.value0 instanceof ZAP_Trovex_Common.RedeemCodeErrRedeemFailed) {
                  return setAD(genericErrMessage);
                }

                ;

                if (v6.value0 instanceof ZAP_Trovex_Common.RedeemCodeErrAlreadyUsed) {
                  return setAD(alreadyUsedErr);
                }

                ;

                if (v6.value0 instanceof ZAP_Trovex_Common.RedeemCodeErrSessionTimeout) {
                  return v3.value1(Data_Function["const"](new Data_Maybe.Just({
                    title: "Session Expired",
                    message: "Your session has expired due to inactivity",
                    onClose: v3.value1(Data_Function["const"](Data_Maybe.Nothing.value)),
                    onAction: restart,
                    actionLabel: "Try again"
                  })));
                }

                ;

                if (v6.value0 instanceof ZAP_Trovex_Common.RedeemCodeErrUnauthorized) {
                  return setAD(genericErrMessage);
                }

                ;
                throw new Error("Failed pattern match at ZAP.Trovex.Client.Flows.RedemptionFlow (line 281, column 29 - line 297, column 71): " + [v6.value0.constructor.name]);
              }

              ;

              if (v6 instanceof Data_Either.Right) {
                return function __do() {
                  v1.value1(function (st) {
                    return {
                      mobile: new Data_Maybe.Just($foreign.formatToInternational(v6.value0.mobileNumber)),
                      productId: new Data_Maybe.Just(v6.value0.productId),
                      productName: st.productName,
                      productImg: v6.value0.productImg,
                      code: st.code,
                      secret: st.secret,
                      expiry: st.expiry,
                      status: new Data_Maybe.Just({
                        title: v6.value0.status.title,
                        message: v6.value0.status.message,
                        type: function () {
                          if (v6.value0.status.type instanceof ZAP_Trovex_Common.RedemptionStatusSuccess) {
                            return new Data_Maybe.Just("Success");
                          }

                          ;

                          if (v6.value0.status.type instanceof ZAP_Trovex_Common.RedemptionStatusFailed) {
                            return new Data_Maybe.Just("Failed");
                          }

                          ;
                          return Data_Maybe.Nothing.value;
                        }()
                      })
                    };
                  })();
                  return v4["switch"](ViewSuccessRedeem.value)();
                };
              }

              ;
              throw new Error("Failed pattern match at ZAP.Trovex.Client.Flows.RedemptionFlow (line 280, column 15 - line 317, column 43): " + [v6.constructor.name]);
            });

            var onValidate = function (rcode) {
              return runSubM(validateCode(rcode))(function (v6) {
                if (v6 instanceof Data_Either.Left) {
                  if (v6.value0 instanceof ZAP_Trovex_Common.ValidateCodeErrInvalidCode) {
                    return setAD(invalidCodeErr);
                  }

                  ;

                  if (v6.value0 instanceof ZAP_Trovex_Common.ValidateCodeErrAlreadyUsed) {
                    return setAD(alreadyUsedErr);
                  }

                  ;

                  if (v6.value0 instanceof ZAP_Trovex_Common.ValidateCodeErrInvalidUser) {
                    return setAD(captchaErr);
                  }

                  ;
                  throw new Error("Failed pattern match at ZAP.Trovex.Client.Flows.RedemptionFlow (line 259, column 27 - line 262, column 63): " + [v6.value0.constructor.name]);
                }

                ;

                if (v6 instanceof Data_Either.Right) {
                  return function __do() {
                    v1.value1(function (st) {
                      return {
                        mobile: st.mobile,
                        productId: new Data_Maybe.Just(v6.value0.productId),
                        productName: v6.value0.productName,
                        productImg: v6.value0.productImg,
                        code: new Data_Maybe.Just(rcode),
                        secret: new Data_Maybe.Just(v6.value0.secret),
                        expiry: new Data_Maybe.Just(v6.value0.expiry),
                        status: st.status
                      };
                    })();
                    return v4["switch"](EnterMobile.value)();
                  };
                }

                ;
                throw new Error("Failed pattern match at ZAP.Trovex.Client.Flows.RedemptionFlow (line 258, column 13 - line 275, column 35): " + [v6.constructor.name]);
              });
            };

            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.div_([React_Basic.element(v.fullPageSpinner)({
              show: v2.value0
            }), function () {
              if (v3.value0 instanceof Data_Maybe.Just) {
                return React_Basic.element(v.alertDialog)({
                  open: true,
                  title: v3.value0.value0.title,
                  message: v3.value0.value0.message,
                  onClose: v3.value0.value0.onClose,
                  actionLabel: v3.value0.value0.actionLabel,
                  onAction: v3.value0.value0.onAction
                });
              }

              ;

              if (v3.value0 instanceof Data_Maybe.Nothing) {
                return Data_Monoid.mempty(React_Basic.monoidJSX);
              }

              ;
              throw new Error("Failed pattern match at ZAP.Trovex.Client.Flows.RedemptionFlow (line 322, column 13 - line 324, column 32): " + [v3.value0.constructor.name]);
            }(), Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(React_Basic.monoidJSX)(function (screen) {
              if (screen instanceof EnterRedemptionCode) {
                return React_Basic.element(v.screens.enterRedemptionCode)({
                  vendorId: v.vendorId,
                  initialCode: code,
                  onSubmit: onValidate,
                  codeLength: ZAP_Trovex_Common.redemptionCodeLength
                });
              }

              ;

              if (screen instanceof EnterMobile) {
                return React_Basic.element(v.screens.enterMobile)({
                  vendorId: v.vendorId,
                  vendorName: vendorName,
                  productName: productName,
                  productImg: productImg,
                  initialMobile: mobile,
                  onSubmit: function (m) {
                    return function __do() {
                      v1.value1(function (st) {
                        return {
                          mobile: new Data_Maybe.Just(m),
                          productId: st.productId,
                          productName: st.productName,
                          productImg: st.productImg,
                          code: st.code,
                          secret: st.secret,
                          expiry: st.expiry,
                          status: st.status
                        };
                      })();
                      return v4["switch"](ConfirmRedemption.value)();
                    };
                  },
                  onCancel: v4.goBack
                });
              }

              ;

              if (screen instanceof ConfirmRedemption) {
                return React_Basic.element(v.screens.confirmRedemption)({
                  mobile: mobile,
                  vendorId: v.vendorId,
                  vendorName: vendorName,
                  productName: productName,
                  productImg: productImg,
                  onSubmit: onRedeem,
                  onCancel: v4.goBack
                });
              }

              ;

              if (screen instanceof ViewSuccessRedeem) {
                return React_Basic.element(v.screens.viewSuccessRedeem)({
                  vendorId: v.vendorId,
                  vendorName: vendorName,
                  productName: productName,
                  productImg: productImg,
                  mobile: mobile,
                  status: status
                });
              }

              ;
              throw new Error("Failed pattern match at ZAP.Trovex.Client.Flows.RedemptionFlow (line 326, column 28 - line 365, column 24): " + [screen.constructor.name]);
            })(v4.currScreen)]));
          });
        });
      });
    });
  });
});
module.exports = {
  EnterRedemptionCode: EnterRedemptionCode,
  EnterMobile: EnterMobile,
  ConfirmRedemption: ConfirmRedemption,
  ViewSuccessRedeem: ViewSuccessRedeem,
  globe: globe,
  smart: smart,
  ApiErr: ApiErr,
  IncompleteData: IncompleteData,
  CaptchaErr: CaptchaErr,
  InvalidMobile: InvalidMobile,
  convertApiM: convertApiM,
  initialState: initialState,
  initialScreen: initialScreen,
  redemptionFlow: redemptionFlow,
  genericScreens: genericScreens,
  pathDupeScreens: pathDupeScreens,
  getNationalNumber: $foreign.getNationalNumber,
  formatToInternational: $foreign.formatToInternational
};