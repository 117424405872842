// Generated by purs version 0.13.8
"use strict";

var Control_Apply = require("../Control.Apply/index.js");

var Control_Category = require("../Control.Category/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_Ord_Max = require("../Data.Ord.Max/index.js");

var Data_Ord_Min = require("../Data.Ord.Min/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var JoinWith = function (x) {
  return x;
};

var Act = function (x) {
  return x;
};

var Foldable1 = function (Foldable0, fold1, foldMap1) {
  this.Foldable0 = Foldable0;
  this.fold1 = fold1;
  this.foldMap1 = foldMap1;
};

var semigroupJoinWith = function (dictSemigroup) {
  return new Data_Semigroup.Semigroup(function (v) {
    return function (v1) {
      return JoinWith(function (j) {
        return Data_Semigroup.append(dictSemigroup)(v(j))(Data_Semigroup.append(dictSemigroup)(j)(v1(j)));
      });
    };
  });
};

var semigroupAct = function (dictApply) {
  return new Data_Semigroup.Semigroup(function (v) {
    return function (v1) {
      return Control_Apply.applySecond(dictApply)(v)(v1);
    };
  });
};

var joinee = function (v) {
  return v;
};

var getAct = function (v) {
  return v;
};

var foldMap1 = function (dict) {
  return dict.foldMap1;
};

var intercalateMap = function (dictFoldable1) {
  return function (dictSemigroup) {
    return function (j) {
      return function (f) {
        return function (foldable) {
          return joinee(foldMap1(dictFoldable1)(semigroupJoinWith(dictSemigroup))(function ($43) {
            return JoinWith(Data_Function["const"](f($43)));
          })(foldable))(j);
        };
      };
    };
  };
};

var intercalate = function (dictFoldable1) {
  return function (dictSemigroup) {
    return Data_Function.flip(intercalateMap(dictFoldable1)(dictSemigroup))(Control_Category.identity(Control_Category.categoryFn));
  };
};

var maximum = function (dictOrd) {
  return function (dictFoldable1) {
    return Data_Newtype.ala(Data_Functor.functorFn)(Data_Ord_Max.newtypeMax)(Data_Ord_Max.newtypeMax)(Data_Ord_Max.Max)(foldMap1(dictFoldable1)(Data_Ord_Max.semigroupMax(dictOrd)));
  };
};

var minimum = function (dictOrd) {
  return function (dictFoldable1) {
    return Data_Newtype.ala(Data_Functor.functorFn)(Data_Ord_Min.newtypeMin)(Data_Ord_Min.newtypeMin)(Data_Ord_Min.Min)(foldMap1(dictFoldable1)(Data_Ord_Min.semigroupMin(dictOrd)));
  };
};

var traverse1_ = function (dictFoldable1) {
  return function (dictApply) {
    return function (f) {
      return function (t) {
        return Data_Functor.voidRight(dictApply.Functor0())(Data_Unit.unit)(getAct(foldMap1(dictFoldable1)(semigroupAct(dictApply))(function ($44) {
          return Act(f($44));
        })(t)));
      };
    };
  };
};

var for1_ = function (dictFoldable1) {
  return function (dictApply) {
    return Data_Function.flip(traverse1_(dictFoldable1)(dictApply));
  };
};

var sequence1_ = function (dictFoldable1) {
  return function (dictApply) {
    return traverse1_(dictFoldable1)(dictApply)(Control_Category.identity(Control_Category.categoryFn));
  };
};

var fold1Default = function (dictFoldable1) {
  return function (dictSemigroup) {
    return foldMap1(dictFoldable1)(dictSemigroup)(Control_Category.identity(Control_Category.categoryFn));
  };
};

var foldableDual = new Foldable1(function () {
  return Data_Foldable.foldableDual;
}, function (dictSemigroup) {
  return fold1Default(foldableDual)(dictSemigroup);
}, function (dictSemigroup) {
  return function (f) {
    return function (v) {
      return f(v);
    };
  };
});
var foldableMultiplicative = new Foldable1(function () {
  return Data_Foldable.foldableMultiplicative;
}, function (dictSemigroup) {
  return fold1Default(foldableMultiplicative)(dictSemigroup);
}, function (dictSemigroup) {
  return function (f) {
    return function (v) {
      return f(v);
    };
  };
});

var fold1 = function (dict) {
  return dict.fold1;
};

var foldMap1Default = function (dictFoldable1) {
  return function (dictFunctor) {
    return function (dictSemigroup) {
      return function (f) {
        var $45 = fold1(dictFoldable1)(dictSemigroup);
        var $46 = Data_Functor.map(dictFunctor)(f);
        return function ($47) {
          return $45($46($47));
        };
      };
    };
  };
};

module.exports = {
  Foldable1: Foldable1,
  foldMap1: foldMap1,
  fold1: fold1,
  traverse1_: traverse1_,
  for1_: for1_,
  sequence1_: sequence1_,
  foldMap1Default: foldMap1Default,
  fold1Default: fold1Default,
  intercalate: intercalate,
  intercalateMap: intercalateMap,
  maximum: maximum,
  minimum: minimum,
  foldableDual: foldableDual,
  foldableMultiplicative: foldableMultiplicative
};