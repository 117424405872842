"use strict";

exports.arrayMap = function (f) {
  return function (arr) {
    var l = arr.length;
    var result = new Array(l);

    for (var i = 0; i < l; i++) {
      result[i] = f(arr[i]);
    }

    return result;
  };
};