import React from 'react';
import styled from 'styled-components';

const Container = styled.header`
  height: 60px;
  background: white;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.09);
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  height: 85%;
`;

export const Header: React.FC<{ holdingLogoUrl: string }> = ({
  holdingLogoUrl,
}) => {
  return (
    <Container>
      <Logo src={holdingLogoUrl} />
    </Container>
  );
};
