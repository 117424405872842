import * as React from 'react';
import { hot } from 'react-hot-loader/root';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Header } from './Header';
import queryString from 'query-string';
import { Jollibee } from './vendors/Jollibee';
import { getHoldingLogoUrl } from '../utils';
import { zenv, recaptchaKey } from '../constants';
import styled from 'styled-components';
import { Globe } from './vendors/Globe';
import { Smart } from './vendors/Smart';
import { HoldingThemeProvider } from './contexts/HoldingTheme';

const Container = styled.div`
  & > div {
    margin: 0 auto;
    max-width: 600px;
  }
`;
const App: React.FC = () => {
  const { holdingId } = queryString.parse(document.location.search);
  const holdingLogoUrl =
    typeof holdingId === 'string'
      ? getHoldingLogoUrl(zenv, parseInt(holdingId))
      : null;
  return (
    <HoldingThemeProvider>
      <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
        <Router>
          {holdingLogoUrl && <Header holdingLogoUrl={holdingLogoUrl} />}
          <Container>
            <Switch>
              <Route path="/vendors/jollibee/branches" component={Jollibee} />
              <Route path="/vendors/globe" component={Globe} />
              <Route path="/vendors/smart" component={Smart} />
            </Switch>
          </Container>
        </Router>
      </GoogleReCaptchaProvider>
    </HoldingThemeProvider>
  );
};

export default hot(App);
