import React from 'react';
import styled from 'styled-components';
import { Eff } from 'js/types';

const InputLbl = styled.label`
  font-family: ProximaNova-ExtraBold;
  font-size: 12px;
  margin-bottom: 8px;
`;

const InputTxt = styled.input`
  border-width: 0.5px;
  border-color: black;
  border-style: solid;
  border-radius: 5px;
  background-color: transparent;
  margin: 0;
  font-size: 14px;
  letter-spacing: 1.2px;
  font-family: ProximaNova-Regular;
  color: black;
  padding: 16px 16px;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface Props {
  placeholder: string;
  label: string;
  value: string;
  maxLength?: number;
  onFocus?: Eff<void>;
  onChange: (v: string) => Eff<void>;
}

export const InputGroup: React.FC<Props> = ({
  placeholder,
  label,
  value,
  maxLength,
  onChange,
  onFocus,
}) => {
  return (
    <Wrapper>
      <InputLbl>{label}</InputLbl>
      <InputTxt
        placeholder={placeholder}
        value={value}
        onFocus={onFocus}
        maxLength={maxLength}
        onChange={(e) => {
          onChange(e.currentTarget.value)();
        }}
      />
    </Wrapper>
  );
};
