// Generated by purs version 0.13.8
"use strict";

var Data_Symbol = require("../Data.Symbol/index.js");

var ExpressRpc_MkApiClient = require("../ExpressRpc.MkApiClient/index.js");

var ZAP_Trovex_Client_Constants = require("../ZAP.Trovex.Client.Constants/index.js");

var ZAP_Trovex_Common = require("../ZAP.Trovex.Common/index.js");

var ZRPC_ZRPCRead = require("../ZRPC.ZRPCRead/index.js");

var ZRPC_ZRPCWrite = require("../ZRPC.ZRPCWrite/index.js");

var apiClient = ExpressRpc_MkApiClient.mkApiClient()(ExpressRpc_MkApiClient.mkFetcherRLCons(new Data_Symbol.IsSymbol(function () {
  return "redeemCode";
}))(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "code";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "expiry";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteDateTime))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "mobileNumber";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "secret";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "vendor";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZAP_Trovex_Common.zrpcWriteVendor))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())())())()))(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(ZAP_Trovex_Common.zrpcReadRedeemCodeErr))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "mobileNumber";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "productId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "productImg";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "productName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "status";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "message";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "title";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "type";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZAP_Trovex_Common.zrpcReadRedemptionStatusType))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()()))))()()(ExpressRpc_MkApiClient.mkFetcherRLCons(new Data_Symbol.IsSymbol(function () {
  return "validateCode";
}))(ZRPC_ZRPCWrite.zrpcWriteRec()(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "captchaToken";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "code";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZRPC_ZRPCWrite.zrpcWriteString))(ZRPC_ZRPCWrite.zrpcWriteFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "vendor";
}))(ZRPC_ZRPCWrite.zrpcWriteFieldId(ZAP_Trovex_Common.zrpcWriteVendor))(ZRPC_ZRPCWrite.zrpcWriteFieldsNil)())())()))(ZRPC_ZRPCRead.zrpcReadEither(ZRPC_ZRPCRead.zrpcReadFieldId(ZAP_Trovex_Common.zrpcReadValidateCodeErr))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.readRecord()(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "code";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "expiry";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadDateTime))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "productId";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "productImg";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "productName";
}))(ZRPC_ZRPCRead.zrpcReadFieldMaybe(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsCons(new Data_Symbol.IsSymbol(function () {
  return "secret";
}))(ZRPC_ZRPCRead.zrpcReadFieldId(ZRPC_ZRPCRead.zrpcReadString))(ZRPC_ZRPCRead.zrpcReadFieldsNil)()())()())()())()())()())()()))))()()(ExpressRpc_MkApiClient.mkFetcherRLNil)))(ZAP_Trovex_Client_Constants.apiUrl);
module.exports = {
  apiClient: apiClient
};