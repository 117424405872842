import React from 'react';
import styled from 'styled-components';
import {
  getCountryCallingCode,
  formatIncompletePhoneNumber,
  parsePhoneNumberFromString,
} from 'libphonenumber-js';
import { Button } from '../../components/Button';
import { InputGroup } from '../../components/InputGroup';
import { getVendorLogo } from 'js/utils';
import { zenv } from 'js/constants';
import { Eff } from 'js/types';
import {
  Container,
  regFont,
  displayStyle,
  SmallVendorLogo,
  PImg,
} from './Common';
import { HoldingTheme } from 'js/client/contexts/HoldingTheme';

interface Props {
  vendorId: string;
  vendorName: string;
  initialMobile: string;
  productName: string;
  productImg: string;
  onSubmit: (mobile: string) => Eff<void>;
  onCancel: Eff<void>;
}

const Header = styled.p`
  font-size: 24px;
  font-family: ProximaNova-Semibold;
  letter-spacing: 0.05em;
  margin-top: 45px;
  margin-bottom: 25px;
  text-align: center;
`;

const Display = styled.div`
  ${displayStyle}
  min-height: 220px;
  margin-bottom: 40px;
`;

const VName = styled.p`
  ${regFont}
  margin: 0;
  margin-bottom: 4px;
`;
const PId = styled.p`
  font-family: ProximaNova-Semibold;
  font-size: 16px;
  margin: 0;
  margin-bottom: 20px;
`;
const Msg = styled.p`
  ${regFont}
  margin-bottom: 45px;
  text-align: center;
`;

const country = 'PH';
const countryCC = '+' + getCountryCallingCode(country);
const phoneNumberLength = 7;
const carrierLength = 3;
const spaces = 3;
const mobilePlaceholder = '+63 XXX XXXX XXXX';
const formatMobile = (m: string) => formatIncompletePhoneNumber(m, country);

export const EnterMobile: React.FC<Props> = ({
  vendorId,
  vendorName,
  initialMobile,
  productName,
  productImg,
  onSubmit,
  onCancel,
}) => {
  const [mobile, onMobileChange] = React.useState(
    formatMobile(initialMobile.startsWith(countryCC) ? initialMobile : '')
  );
  const phoneNumber = parsePhoneNumberFromString(mobile, country);
  const { color } = React.useContext(HoldingTheme);
  return (
    <Container>
      <SmallVendorLogo src={getVendorLogo(zenv, vendorId)} />
      <Header>Redeem this reward</Header>
      <Display>
        <VName>{vendorName.toUpperCase()}</VName>
        <PId>{productName}</PId>
        <PImg alt={productName} src={productImg} />
      </Display>
      <Msg>
        Enter the mobile number where you <br /> would like to redeem this
        reward
      </Msg>
      <InputGroup
        placeholder={mobilePlaceholder}
        label="Mobile Number"
        onFocus={() => {
          if (!mobile.startsWith(countryCC)) {
            onMobileChange(formatMobile(countryCC + mobile));
          }
        }}
        value={mobile}
        onChange={(v) => () => {
          if (
            v !== countryCC &&
            v.length > countryCC.length &&
            v.length <=
              countryCC.length + phoneNumberLength + carrierLength + spaces
          ) {
            const fm = formatMobile(v);
            onMobileChange(
              fm === '' ? formatMobile(countryCC + [...v.split('+')][0]) : fm
            );
          }
          if (v === '' || v.length < countryCC.length) {
            const fm = formatMobile(countryCC + v);
            onMobileChange(fm === '' ? countryCC : fm);
          }
        }}
      />
      <br />
      <br />
      <br />
      <Button
        title="SUBMIT"
        type="primary"
        color={color}
        disabled={phoneNumber ? !phoneNumber.isValid() : true}
        onPress={() => onSubmit(mobile)()}
      />
      <Button
        title="Cancel"
        type="secondary"
        color={color}
        disabled={false}
        onPress={onCancel}
      />
    </Container>
  );
};
