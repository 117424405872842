// Generated by purs version 0.13.8
"use strict";

var $foreign = require("./foreign.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Comonad = require("../Control.Comonad/index.js");

var Control_Extend = require("../Control.Extend/index.js");

var Control_Lazy = require("../Control.Lazy/index.js");

var Control_Monad = require("../Control.Monad/index.js");

var Data_BooleanAlgebra = require("../Data.BooleanAlgebra/index.js");

var Data_Bounded = require("../Data.Bounded/index.js");

var Data_CommutativeRing = require("../Data.CommutativeRing/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_EuclideanRing = require("../Data.EuclideanRing/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_FoldableWithIndex = require("../Data.FoldableWithIndex/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Functor_Invariant = require("../Data.Functor.Invariant/index.js");

var Data_FunctorWithIndex = require("../Data.FunctorWithIndex/index.js");

var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Ring = require("../Data.Ring/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Semigroup_Foldable = require("../Data.Semigroup.Foldable/index.js");

var Data_Semigroup_Traversable = require("../Data.Semigroup.Traversable/index.js");

var Data_Semiring = require("../Data.Semiring/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_Traversable = require("../Data.Traversable/index.js");

var Data_TraversableWithIndex = require("../Data.TraversableWithIndex/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var showLazy = function (dictShow) {
  return new Data_Show.Show(function (x) {
    return "(defer \\_ -> " + (Data_Show.show(dictShow)($foreign.force(x)) + ")");
  });
};

var semiringLazy = function (dictSemiring) {
  return new Data_Semiring.Semiring(function (a) {
    return function (b) {
      return $foreign.defer(function (v) {
        return Data_Semiring.add(dictSemiring)($foreign.force(a))($foreign.force(b));
      });
    };
  }, function (a) {
    return function (b) {
      return $foreign.defer(function (v) {
        return Data_Semiring.mul(dictSemiring)($foreign.force(a))($foreign.force(b));
      });
    };
  }, $foreign.defer(function (v) {
    return Data_Semiring.one(dictSemiring);
  }), $foreign.defer(function (v) {
    return Data_Semiring.zero(dictSemiring);
  }));
};

var semigroupLazy = function (dictSemigroup) {
  return new Data_Semigroup.Semigroup(function (a) {
    return function (b) {
      return $foreign.defer(function (v) {
        return Data_Semigroup.append(dictSemigroup)($foreign.force(a))($foreign.force(b));
      });
    };
  });
};

var ringLazy = function (dictRing) {
  return new Data_Ring.Ring(function () {
    return semiringLazy(dictRing.Semiring0());
  }, function (a) {
    return function (b) {
      return $foreign.defer(function (v) {
        return Data_Ring.sub(dictRing)($foreign.force(a))($foreign.force(b));
      });
    };
  });
};

var monoidLazy = function (dictMonoid) {
  return new Data_Monoid.Monoid(function () {
    return semigroupLazy(dictMonoid.Semigroup0());
  }, $foreign.defer(function (v) {
    return Data_Monoid.mempty(dictMonoid);
  }));
};

var lazyLazy = new Control_Lazy.Lazy(function (f) {
  return $foreign.defer(function (v) {
    return $foreign.force(f(Data_Unit.unit));
  });
});
var functorLazy = new Data_Functor.Functor(function (f) {
  return function (l) {
    return $foreign.defer(function (v) {
      return f($foreign.force(l));
    });
  };
});
var functorWithIndexLazy = new Data_FunctorWithIndex.FunctorWithIndex(function () {
  return functorLazy;
}, function (f) {
  return Data_Functor.map(functorLazy)(f(Data_Unit.unit));
});
var invariantLazy = new Data_Functor_Invariant.Invariant(Data_Functor_Invariant.imapF(functorLazy));
var foldableLazy = new Data_Foldable.Foldable(function (dictMonoid) {
  return function (f) {
    return function (l) {
      return f($foreign.force(l));
    };
  };
}, function (f) {
  return function (z) {
    return function (l) {
      return f(z)($foreign.force(l));
    };
  };
}, function (f) {
  return function (z) {
    return function (l) {
      return f($foreign.force(l))(z);
    };
  };
});
var foldableWithIndexLazy = new Data_FoldableWithIndex.FoldableWithIndex(function () {
  return foldableLazy;
}, function (dictMonoid) {
  return function (f) {
    return Data_Foldable.foldMap(foldableLazy)(dictMonoid)(f(Data_Unit.unit));
  };
}, function (f) {
  return Data_Foldable.foldl(foldableLazy)(f(Data_Unit.unit));
}, function (f) {
  return Data_Foldable.foldr(foldableLazy)(f(Data_Unit.unit));
});
var traversableLazy = new Data_Traversable.Traversable(function () {
  return foldableLazy;
}, function () {
  return functorLazy;
}, function (dictApplicative) {
  return function (l) {
    return Data_Functor.map(dictApplicative.Apply0().Functor0())(function ($42) {
      return $foreign.defer(Data_Function["const"]($42));
    })($foreign.force(l));
  };
}, function (dictApplicative) {
  return function (f) {
    return function (l) {
      return Data_Functor.map(dictApplicative.Apply0().Functor0())(function ($43) {
        return $foreign.defer(Data_Function["const"]($43));
      })(f($foreign.force(l)));
    };
  };
});
var traversableWithIndexLazy = new Data_TraversableWithIndex.TraversableWithIndex(function () {
  return foldableWithIndexLazy;
}, function () {
  return functorWithIndexLazy;
}, function () {
  return traversableLazy;
}, function (dictApplicative) {
  return function (f) {
    return Data_Traversable.traverse(traversableLazy)(dictApplicative)(f(Data_Unit.unit));
  };
});
var foldable1Lazy = new Data_Semigroup_Foldable.Foldable1(function () {
  return foldableLazy;
}, function (dictSemigroup) {
  return Data_Semigroup_Foldable.fold1Default(foldable1Lazy)(dictSemigroup);
}, function (dictSemigroup) {
  return function (f) {
    return function (l) {
      return f($foreign.force(l));
    };
  };
});
var traversable1Lazy = new Data_Semigroup_Traversable.Traversable1(function () {
  return foldable1Lazy;
}, function () {
  return traversableLazy;
}, function (dictApply) {
  return function (l) {
    return Data_Functor.map(dictApply.Functor0())(function ($44) {
      return $foreign.defer(Data_Function["const"]($44));
    })($foreign.force(l));
  };
}, function (dictApply) {
  return function (f) {
    return function (l) {
      return Data_Functor.map(dictApply.Functor0())(function ($45) {
        return $foreign.defer(Data_Function["const"]($45));
      })(f($foreign.force(l)));
    };
  };
});
var extendLazy = new Control_Extend.Extend(function () {
  return functorLazy;
}, function (f) {
  return function (x) {
    return $foreign.defer(function (v) {
      return f(x);
    });
  };
});

var eqLazy = function (dictEq) {
  return new Data_Eq.Eq(function (x) {
    return function (y) {
      return Data_Eq.eq(dictEq)($foreign.force(x))($foreign.force(y));
    };
  });
};

var ordLazy = function (dictOrd) {
  return new Data_Ord.Ord(function () {
    return eqLazy(dictOrd.Eq0());
  }, function (x) {
    return function (y) {
      return Data_Ord.compare(dictOrd)($foreign.force(x))($foreign.force(y));
    };
  });
};

var eq1Lazy = new Data_Eq.Eq1(function (dictEq) {
  return Data_Eq.eq(eqLazy(dictEq));
});
var ord1Lazy = new Data_Ord.Ord1(function () {
  return eq1Lazy;
}, function (dictOrd) {
  return Data_Ord.compare(ordLazy(dictOrd));
});
var comonadLazy = new Control_Comonad.Comonad(function () {
  return extendLazy;
}, $foreign.force);

var commutativeRingLazy = function (dictCommutativeRing) {
  return new Data_CommutativeRing.CommutativeRing(function () {
    return ringLazy(dictCommutativeRing.Ring0());
  });
};

var euclideanRingLazy = function (dictEuclideanRing) {
  return new Data_EuclideanRing.EuclideanRing(function () {
    return commutativeRingLazy(dictEuclideanRing.CommutativeRing0());
  }, function () {
    var $46 = Data_EuclideanRing.degree(dictEuclideanRing);
    return function ($47) {
      return $46($foreign.force($47));
    };
  }(), function (a) {
    return function (b) {
      return $foreign.defer(function (v) {
        return Data_EuclideanRing.div(dictEuclideanRing)($foreign.force(a))($foreign.force(b));
      });
    };
  }, function (a) {
    return function (b) {
      return $foreign.defer(function (v) {
        return Data_EuclideanRing.mod(dictEuclideanRing)($foreign.force(a))($foreign.force(b));
      });
    };
  });
};

var boundedLazy = function (dictBounded) {
  return new Data_Bounded.Bounded(function () {
    return ordLazy(dictBounded.Ord0());
  }, $foreign.defer(function (v) {
    return Data_Bounded.bottom(dictBounded);
  }), $foreign.defer(function (v) {
    return Data_Bounded.top(dictBounded);
  }));
};

var applyLazy = new Control_Apply.Apply(function () {
  return functorLazy;
}, function (f) {
  return function (x) {
    return $foreign.defer(function (v) {
      return $foreign.force(f)($foreign.force(x));
    });
  };
});
var bindLazy = new Control_Bind.Bind(function () {
  return applyLazy;
}, function (l) {
  return function (f) {
    return $foreign.defer(function (v) {
      return $foreign.force(f($foreign.force(l)));
    });
  };
});

var heytingAlgebraLazy = function (dictHeytingAlgebra) {
  return new Data_HeytingAlgebra.HeytingAlgebra(function (a) {
    return function (b) {
      return Control_Apply.apply(applyLazy)(Data_Functor.map(functorLazy)(Data_HeytingAlgebra.conj(dictHeytingAlgebra))(a))(b);
    };
  }, function (a) {
    return function (b) {
      return Control_Apply.apply(applyLazy)(Data_Functor.map(functorLazy)(Data_HeytingAlgebra.disj(dictHeytingAlgebra))(a))(b);
    };
  }, $foreign.defer(function (v) {
    return Data_HeytingAlgebra.ff(dictHeytingAlgebra);
  }), function (a) {
    return function (b) {
      return Control_Apply.apply(applyLazy)(Data_Functor.map(functorLazy)(Data_HeytingAlgebra.implies(dictHeytingAlgebra))(a))(b);
    };
  }, function (a) {
    return Data_Functor.map(functorLazy)(Data_HeytingAlgebra.not(dictHeytingAlgebra))(a);
  }, $foreign.defer(function (v) {
    return Data_HeytingAlgebra.tt(dictHeytingAlgebra);
  }));
};

var booleanAlgebraLazy = function (dictBooleanAlgebra) {
  return new Data_BooleanAlgebra.BooleanAlgebra(function () {
    return heytingAlgebraLazy(dictBooleanAlgebra.HeytingAlgebra0());
  });
};

var applicativeLazy = new Control_Applicative.Applicative(function () {
  return applyLazy;
}, function (a) {
  return $foreign.defer(function (v) {
    return a;
  });
});
var monadLazy = new Control_Monad.Monad(function () {
  return applicativeLazy;
}, function () {
  return bindLazy;
});
module.exports = {
  semiringLazy: semiringLazy,
  ringLazy: ringLazy,
  commutativeRingLazy: commutativeRingLazy,
  euclideanRingLazy: euclideanRingLazy,
  eqLazy: eqLazy,
  eq1Lazy: eq1Lazy,
  ordLazy: ordLazy,
  ord1Lazy: ord1Lazy,
  boundedLazy: boundedLazy,
  semigroupLazy: semigroupLazy,
  monoidLazy: monoidLazy,
  heytingAlgebraLazy: heytingAlgebraLazy,
  booleanAlgebraLazy: booleanAlgebraLazy,
  functorLazy: functorLazy,
  functorWithIndexLazy: functorWithIndexLazy,
  foldableLazy: foldableLazy,
  foldableWithIndexLazy: foldableWithIndexLazy,
  foldable1Lazy: foldable1Lazy,
  traversableLazy: traversableLazy,
  traversableWithIndexLazy: traversableWithIndexLazy,
  traversable1Lazy: traversable1Lazy,
  invariantLazy: invariantLazy,
  applyLazy: applyLazy,
  applicativeLazy: applicativeLazy,
  bindLazy: bindLazy,
  monadLazy: monadLazy,
  extendLazy: extendLazy,
  comonadLazy: comonadLazy,
  showLazy: showLazy,
  lazyLazy: lazyLazy,
  defer: $foreign.defer,
  force: $foreign.force
};