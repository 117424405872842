// Generated by purs version 0.13.8
"use strict";

var Data_Tuple = require("../Data.Tuple/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var MonadState = function (Monad0, state) {
  this.Monad0 = Monad0;
  this.state = state;
};

var state = function (dict) {
  return dict.state;
};

var put = function (dictMonadState) {
  return function (s) {
    return state(dictMonadState)(function (v) {
      return new Data_Tuple.Tuple(Data_Unit.unit, s);
    });
  };
};

var modify_ = function (dictMonadState) {
  return function (f) {
    return state(dictMonadState)(function (s) {
      return new Data_Tuple.Tuple(Data_Unit.unit, f(s));
    });
  };
};

var modify = function (dictMonadState) {
  return function (f) {
    return state(dictMonadState)(function (s) {
      var s$prime = f(s);
      return new Data_Tuple.Tuple(s$prime, s$prime);
    });
  };
};

var gets = function (dictMonadState) {
  return function (f) {
    return state(dictMonadState)(function (s) {
      return new Data_Tuple.Tuple(f(s), s);
    });
  };
};

var get = function (dictMonadState) {
  return state(dictMonadState)(function (s) {
    return new Data_Tuple.Tuple(s, s);
  });
};

module.exports = {
  state: state,
  MonadState: MonadState,
  get: get,
  gets: gets,
  put: put,
  modify: modify,
  modify_: modify_
};