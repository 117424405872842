// Generated by purs version 0.13.8
"use strict";

var $foreign = require("./foreign.js");

var Control_Promise = require("../Control.Promise/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_Show = require("../Data.Show/index.js");

var URL = function (x) {
  return x;
};

var url = function (response) {
  return response.url;
};

var text = function (res) {
  return Control_Promise.toAffE($foreign.textImpl(res));
};

var statusCode = function (response) {
  return response.status;
};

var showURL = Data_Show.showString;
var sameOriginCredentials = "same-origin";
var putMethod = "PUT";
var postMethod = "POST";
var omitCredentials = "omit";
var newtypeURL = new Data_Newtype.Newtype(function (n) {
  return n;
}, URL);

var json = function (res) {
  return Control_Promise.toAffE($foreign.jsonImpl(res));
};

var includeCredentials = "include";
var headers = $foreign.headersImpl;
var headMethod = "HEAD";
var getMethod = "GET";

var fromRecord = function (dictHomogeneous) {
  return $foreign.fromRecordImpl;
};

var makeHeaders = function (dictHomogeneous) {
  return fromRecord();
};

var fetch = function (impl) {
  return function (dictUnion) {
    return function (url$prime) {
      return function (opts) {
        return Control_Promise.toAffE($foreign["_fetch"](impl)(url$prime)(opts));
      };
    };
  };
};

var eqURL = new Data_Eq.Eq(function (x) {
  return function (y) {
    return x === y;
  };
});
var deleteMethod = "DELETE";
var defaultFetchOptions = {
  method: getMethod
};

var arrayBuffer = function (res) {
  return Control_Promise.toAffE($foreign.arrayBufferImpl(res));
};

module.exports = {
  URL: URL,
  defaultFetchOptions: defaultFetchOptions,
  getMethod: getMethod,
  postMethod: postMethod,
  putMethod: putMethod,
  deleteMethod: deleteMethod,
  headMethod: headMethod,
  omitCredentials: omitCredentials,
  sameOriginCredentials: sameOriginCredentials,
  includeCredentials: includeCredentials,
  fetch: fetch,
  json: json,
  text: text,
  headers: headers,
  arrayBuffer: arrayBuffer,
  makeHeaders: makeHeaders,
  statusCode: statusCode,
  url: url,
  newtypeURL: newtypeURL,
  showURL: showURL,
  eqURL: eqURL
};