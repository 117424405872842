// Generated by purs version 0.13.8
"use strict";

var $foreign = require("./foreign.js");

var runFn1 = function (f) {
  return f;
};

var mkFn1 = function (f) {
  return f;
};

module.exports = {
  mkFn1: mkFn1,
  runFn1: runFn1,
  mkFn0: $foreign.mkFn0,
  mkFn2: $foreign.mkFn2,
  mkFn3: $foreign.mkFn3,
  mkFn4: $foreign.mkFn4,
  mkFn5: $foreign.mkFn5,
  mkFn6: $foreign.mkFn6,
  mkFn7: $foreign.mkFn7,
  mkFn8: $foreign.mkFn8,
  mkFn9: $foreign.mkFn9,
  mkFn10: $foreign.mkFn10,
  runFn0: $foreign.runFn0,
  runFn2: $foreign.runFn2,
  runFn3: $foreign.runFn3,
  runFn4: $foreign.runFn4,
  runFn5: $foreign.runFn5,
  runFn6: $foreign.runFn6,
  runFn7: $foreign.runFn7,
  runFn8: $foreign.runFn8,
  runFn9: $foreign.runFn9,
  runFn10: $foreign.runFn10
};