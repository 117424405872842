// Generated by purs version 0.13.8
"use strict";

var $foreign = require("./foreign.js");

var Control_Bind = require("../Control.Bind/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Function_Uncurried = require("../Data.Function.Uncurried/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_Nullable = require("../Data.Nullable/index.js");

var Data_Tuple = require("../Data.Tuple/index.js");

var Effect = require("../Effect/index.js");

var Effect_Uncurried = require("../Effect.Uncurried/index.js");

var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");

var Unsafe_Coerce = require("../Unsafe.Coerce/index.js");

var Unsafe_Reference = require("../Unsafe.Reference/index.js");

var UnsafeReference = function (x) {
  return x;
};

var writeRef = Effect_Uncurried.runEffectFn2($foreign.writeRef_);

var useState = function (initialState) {
  return React_Basic_Hooks_Internal.unsafeHook(function () {
    return $foreign.useState_(Data_Function_Uncurried.mkFn2(Data_Tuple.Tuple.create), initialState);
  });
};

var useRef = function (initialValue) {
  return React_Basic_Hooks_Internal.unsafeHook(function () {
    return $foreign.useRef_(initialValue);
  });
};

var useReducer = function (initialState) {
  return function (reducer) {
    return React_Basic_Hooks_Internal.unsafeHook(function () {
      return $foreign.useReducer_(Data_Function_Uncurried.mkFn2(Data_Tuple.Tuple.create), Data_Function_Uncurried.mkFn2(reducer), initialState);
    });
  };
};

var useMemo = function (dictEq) {
  return function (key) {
    return function (computeA) {
      return React_Basic_Hooks_Internal.unsafeHook(function () {
        return $foreign.useMemo_(Data_Function_Uncurried.mkFn2(Data_Eq.eq(dictEq)), key, computeA);
      });
    };
  };
};

var useLayoutEffect = function (dictEq) {
  return function (keys) {
    return function (effect) {
      return React_Basic_Hooks_Internal.unsafeHook(function () {
        return $foreign.useLayoutEffect_(Data_Function_Uncurried.mkFn2(Data_Eq.eq(dictEq)), keys, effect);
      });
    };
  };
};

var useEqCache = function (dictEq) {
  return function (a) {
    return React_Basic_Hooks_Internal.unsafeHook(function () {
      return $foreign.useEqCache_(Data_Function_Uncurried.mkFn2(Data_Eq.eq(dictEq)), a);
    });
  };
};

var useEffect = function (dictEq) {
  return function (key) {
    return function (effect) {
      return React_Basic_Hooks_Internal.unsafeHook(function () {
        return $foreign.useEffect_(Data_Function_Uncurried.mkFn2(Data_Eq.eq(dictEq)), key, effect);
      });
    };
  };
};

var useContext = function (context) {
  return React_Basic_Hooks_Internal.unsafeHook(function () {
    return $foreign.useContext_(context);
  });
};

var useCallback = function (dictEq) {
  return function (key) {
    return function (computeA) {
      return React_Basic_Hooks_Internal.unsafeHook(function () {
        return $foreign.useCallback_(Data_Function_Uncurried.mkFn2(Data_Eq.eq(dictEq)), key, computeA);
      });
    };
  };
};

var unsafeReactFunctionComponent = Unsafe_Coerce.unsafeCoerce;
var unsafeDiscardRenderEffects = Unsafe_Coerce.unsafeCoerce;
var readRef = Effect_Uncurried.runEffectFn1($foreign.readRef_);

var readRefMaybe = function (a) {
  return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)(readRef(a));
};

var renderRefMaybe = function (a) {
  return React_Basic_Hooks_Internal.unsafeRenderEffect(readRefMaybe(a));
};

var renderRef = function (ref) {
  return React_Basic_Hooks_Internal.unsafeRenderEffect(readRef(ref));
};

var newtypeUnsafeReference = new Data_Newtype.Newtype(function (n) {
  return n;
}, UnsafeReference);
var memo = Data_Function.flip(Control_Bind.bind(Effect.bindEffect))(Effect_Uncurried.runEffectFn1($foreign.memo_));
var eqUnsafeReference = new Data_Eq.Eq(Unsafe_Reference.unsafeRefEq);

var component = function (dictLacks) {
  return function (dictLacks1) {
    return function (name) {
      return function (renderFn) {
        var c = unsafeReactFunctionComponent(function (props) {
          return unsafeDiscardRenderEffects(renderFn(props))();
        });
        return function () {
          return $foreign.unsafeSetDisplayName(name, c);
        };
      };
    };
  };
};

module.exports = {
  component: component,
  memo: memo,
  useState: useState,
  useEffect: useEffect,
  useLayoutEffect: useLayoutEffect,
  useReducer: useReducer,
  readRef: readRef,
  readRefMaybe: readRefMaybe,
  writeRef: writeRef,
  renderRef: renderRef,
  renderRefMaybe: renderRefMaybe,
  useRef: useRef,
  useContext: useContext,
  useMemo: useMemo,
  useCallback: useCallback,
  useEqCache: useEqCache,
  UnsafeReference: UnsafeReference,
  newtypeUnsafeReference: newtypeUnsafeReference,
  eqUnsafeReference: eqUnsafeReference,
  displayName: $foreign.displayName
};