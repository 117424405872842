// Generated by purs version 0.13.8
"use strict";

var Comonad = function (Extend0, extract) {
  this.Extend0 = Extend0;
  this.extract = extract;
};

var extract = function (dict) {
  return dict.extract;
};

module.exports = {
  Comonad: Comonad,
  extract: extract
};