// Generated by purs version 0.13.8
"use strict";

var $foreign = require("./foreign.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Int = require("../Data.Int/index.js");

var Effect = require("../Effect/index.js");

var randomRange = function (min) {
  return function (max) {
    return function __do() {
      var n = $foreign.random();
      return n * (max - min) + min;
    };
  };
};

var randomInt = function (low) {
  return function (high) {
    return function __do() {
      var n = $foreign.random();
      var asNumber = (Data_Int.toNumber(high) - Data_Int.toNumber(low) + 1) * n + Data_Int.toNumber(low);
      return Data_Int.floor(asNumber);
    };
  };
};

var randomBool = Data_Functor.map(Effect.functorEffect)(function (v) {
  return v < 0.5;
})($foreign.random);
module.exports = {
  randomInt: randomInt,
  randomRange: randomRange,
  randomBool: randomBool,
  random: $foreign.random
};