import React from 'react';

interface HoldingT {
  color: string;
}
// FUTURE TODO: this should have an api call to get holding theme?
export const HoldingTheme = React.createContext<HoldingT>({
  color: 'white',
});

export class HoldingThemeProvider extends React.Component {
  state = {
    color: 'rgba(96, 175, 175)',
  };
  render() {
    return (
      <HoldingTheme.Provider value={this.state}>
        {this.props.children}
      </HoldingTheme.Provider>
    );
  }
}
