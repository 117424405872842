import { Stage } from './types';
import { subDomainName, domainName } from './constants';

export const getAssetsBucketName = (stage: Stage) => {
  return `assets.${
    stage === 'prod' ? subDomainName : `${subDomainName}.${stage}`
  }.${domainName}`;
};

export const getAssetsUrl = (stage: Stage) => {
  return `https://s3-ap-southeast-1.amazonaws.com/${getAssetsBucketName(
    stage
  )}`;
};

export const getHoldingLogoUrl = (stage: Stage, holdingId: number) => {
  return `https://zap-trove-resources.s3-ap-southeast-1.amazonaws.com/${
    stage === Stage.PREPROD ? Stage.PROD : stage
  }/holdings/${holdingId}/colored.png`;
};

export const getVendorLogo = (stage: Stage, vendorId: string) => {
  return `https://zap-trove-resources.s3-ap-southeast-1.amazonaws.com/${
    stage === Stage.PREPROD ? Stage.PROD : Stage.PROD
  }/vendors/${vendorId}/colored.png
  `;
};
