// Generated by purs version 0.13.8
"use strict";

var MonadTrans = function (lift) {
  this.lift = lift;
};

var lift = function (dict) {
  return dict.lift;
};

module.exports = {
  lift: lift,
  MonadTrans: MonadTrans
};