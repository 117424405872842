// Generated by purs version 0.13.8
"use strict";

var $foreign = require("./foreign.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Traversable = require("../Data.Traversable/index.js");

var Data_Tuple = require("../Data.Tuple/index.js");

var Data_Unfoldable1 = require("../Data.Unfoldable1/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Unfoldable = function (Unfoldable10, unfoldr) {
  this.Unfoldable10 = Unfoldable10;
  this.unfoldr = unfoldr;
};

var unfoldr = function (dict) {
  return dict.unfoldr;
};

var unfoldableArray = new Unfoldable(function () {
  return Data_Unfoldable1.unfoldable1Array;
}, $foreign.unfoldrArrayImpl(Data_Maybe.isNothing)(Data_Maybe.fromJust())(Data_Tuple.fst)(Data_Tuple.snd));

var replicate = function (dictUnfoldable) {
  return function (n) {
    return function (v) {
      var step = function (i) {
        var $7 = i <= 0;

        if ($7) {
          return Data_Maybe.Nothing.value;
        }

        ;
        return new Data_Maybe.Just(new Data_Tuple.Tuple(v, i - 1 | 0));
      };

      return unfoldr(dictUnfoldable)(step)(n);
    };
  };
};

var replicateA = function (dictApplicative) {
  return function (dictUnfoldable) {
    return function (dictTraversable) {
      return function (n) {
        return function (m) {
          return Data_Traversable.sequence(dictTraversable)(dictApplicative)(replicate(dictUnfoldable)(n)(m));
        };
      };
    };
  };
};

var none = function (dictUnfoldable) {
  return unfoldr(dictUnfoldable)(Data_Function["const"](Data_Maybe.Nothing.value))(Data_Unit.unit);
};

var fromMaybe = function (dictUnfoldable) {
  return unfoldr(dictUnfoldable)(function (b) {
    return Data_Functor.map(Data_Maybe.functorMaybe)(Data_Function.flip(Data_Tuple.Tuple.create)(Data_Maybe.Nothing.value))(b);
  });
};

module.exports = {
  Unfoldable: Unfoldable,
  unfoldr: unfoldr,
  replicate: replicate,
  replicateA: replicateA,
  none: none,
  fromMaybe: fromMaybe,
  unfoldableArray: unfoldableArray
};