// Generated by purs version 0.13.8
"use strict";

var RProxy = function () {
  function RProxy() {}

  ;
  RProxy.value = new RProxy();
  return RProxy;
}();

module.exports = {
  RProxy: RProxy
};