// Generated by purs version 0.13.8
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Monad = require("../Control.Monad/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Semiring = require("../Data.Semiring/index.js");

var Data_Show = require("../Data.Show/index.js");

var Conj = function (x) {
  return x;
};

var showConj = function (dictShow) {
  return new Data_Show.Show(function (v) {
    return "(Conj " + (Data_Show.show(dictShow)(v) + ")");
  });
};

var semiringConj = function (dictHeytingAlgebra) {
  return new Data_Semiring.Semiring(function (v) {
    return function (v1) {
      return Data_HeytingAlgebra.conj(dictHeytingAlgebra)(v)(v1);
    };
  }, function (v) {
    return function (v1) {
      return Data_HeytingAlgebra.disj(dictHeytingAlgebra)(v)(v1);
    };
  }, Data_HeytingAlgebra.ff(dictHeytingAlgebra), Data_HeytingAlgebra.tt(dictHeytingAlgebra));
};

var semigroupConj = function (dictHeytingAlgebra) {
  return new Data_Semigroup.Semigroup(function (v) {
    return function (v1) {
      return Data_HeytingAlgebra.conj(dictHeytingAlgebra)(v)(v1);
    };
  });
};

var ordConj = function (dictOrd) {
  return dictOrd;
};

var monoidConj = function (dictHeytingAlgebra) {
  return new Data_Monoid.Monoid(function () {
    return semigroupConj(dictHeytingAlgebra);
  }, Data_HeytingAlgebra.tt(dictHeytingAlgebra));
};

var functorConj = new Data_Functor.Functor(function (f) {
  return function (m) {
    return f(m);
  };
});

var eqConj = function (dictEq) {
  return dictEq;
};

var eq1Conj = new Data_Eq.Eq1(function (dictEq) {
  return Data_Eq.eq(eqConj(dictEq));
});
var ord1Conj = new Data_Ord.Ord1(function () {
  return eq1Conj;
}, function (dictOrd) {
  return Data_Ord.compare(ordConj(dictOrd));
});

var boundedConj = function (dictBounded) {
  return dictBounded;
};

var applyConj = new Control_Apply.Apply(function () {
  return functorConj;
}, function (v) {
  return function (v1) {
    return v(v1);
  };
});
var bindConj = new Control_Bind.Bind(function () {
  return applyConj;
}, function (v) {
  return function (f) {
    return f(v);
  };
});
var applicativeConj = new Control_Applicative.Applicative(function () {
  return applyConj;
}, Conj);
var monadConj = new Control_Monad.Monad(function () {
  return applicativeConj;
}, function () {
  return bindConj;
});
module.exports = {
  Conj: Conj,
  eqConj: eqConj,
  eq1Conj: eq1Conj,
  ordConj: ordConj,
  ord1Conj: ord1Conj,
  boundedConj: boundedConj,
  showConj: showConj,
  functorConj: functorConj,
  applyConj: applyConj,
  applicativeConj: applicativeConj,
  bindConj: bindConj,
  monadConj: monadConj,
  semigroupConj: semigroupConj,
  monoidConj: monoidConj,
  semiringConj: semiringConj
};