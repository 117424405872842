var libpn = require('libphonenumber-js');

exports.getNationalNumber = function (txt) {
  // this function actually throws
  return function () {
    return libpn.parsePhoneNumber(txt, 'PH').nationalNumber;
  };
};

exports.formatToInternational = function (mobile) {
  var ppn = libpn.parsePhoneNumberFromString('+' + mobile, 'PH');
  return ppn ? ppn.formatInternational() : mobile;
};