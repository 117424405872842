// Generated by purs version 0.13.8
"use strict";

var Data_Argonaut_Core = require("../Data.Argonaut.Core/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_Show = require("../Data.Show/index.js");

var ZJson = function (x) {
  return x;
};

var showZJson = new Data_Show.Show(function (v) {
  return Data_Argonaut_Core.stringify(v);
});
var ordZJson = Data_Argonaut_Core.ordJson;
var newtypeZJson = new Data_Newtype.Newtype(function (n) {
  return n;
}, ZJson);
var eqZJson = Data_Argonaut_Core.eqJson;
module.exports = {
  ZJson: ZJson,
  newtypeZJson: newtypeZJson,
  showZJson: showZJson,
  eqZJson: eqZJson,
  ordZJson: ordZJson
};