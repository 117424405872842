import React from 'react';
import { RouteComponentProps } from 'react-router';
import {
  redemptionFlow as RFlow,
  globe,
} from 'purs/ZAP.Trovex.Client.Flows.RedemptionFlow';
import { EnterRedemptionCode } from 'js/client/screens/redemption/EnterRedemptionCode';
import { zenv } from 'js/constants';
import { EnterMobile } from 'js/client/screens/redemption/EnterMobile';
import { ConfirmRedemption } from 'js/client/screens/redemption/ConfirmRedemption';
import { ViewSuccessRedeem } from 'js/client/screens/redemption/ViewSuccessRedeem';
import { FullPageSpinner } from '../components/FullPageSpinner';
import { AlertDialog } from '../components/AlertDialog';

const RedemptionF = RFlow();
const vendorId = {
  staging: '2589ed6c-1996-4a31-8cab-14c0b7fbe123',
  preprod: '2589ed6c-1996-4a31-8cab-14c0b7fbe123',
  prod: '2589ed6c-1996-4a31-8cab-14c0b7fbe123',
};
export const Globe: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <RedemptionF
      screens={{
        enterRedemptionCode: EnterRedemptionCode,
        enterMobile: EnterMobile,
        confirmRedemption: ConfirmRedemption,
        viewSuccessRedeem: ViewSuccessRedeem,
      }}
      vendorId={vendorId[zenv]}
      vendor={globe}
      baseUrl={match.url}
      fullPageSpinner={FullPageSpinner}
      alertDialog={AlertDialog}
    />
  );
};
