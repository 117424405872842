// Generated by purs version 0.13.8
"use strict";

var $foreign = require("./foreign.js");

var Partial = require("../Partial/index.js");

var unsafePartialBecause = function (v) {
  return function (x) {
    return $foreign.unsafePartial(function (dictPartial) {
      return x();
    });
  };
};

var unsafeCrashWith = function (msg) {
  return $foreign.unsafePartial(function (dictPartial) {
    return Partial.crashWith()(msg);
  });
};

module.exports = {
  unsafePartialBecause: unsafePartialBecause,
  unsafeCrashWith: unsafeCrashWith,
  unsafePartial: $foreign.unsafePartial
};