// Generated by purs version 0.13.8
"use strict";

var MonadCont = function (Monad0, callCC) {
  this.Monad0 = Monad0;
  this.callCC = callCC;
};

var callCC = function (dict) {
  return dict.callCC;
};

module.exports = {
  MonadCont: MonadCont,
  callCC: callCC
};