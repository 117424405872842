// Generated by purs version 0.13.8
"use strict";

var Control_Category = require("../Control.Category/index.js");

var Bifunctor = function (bimap) {
  this.bimap = bimap;
};

var bimap = function (dict) {
  return dict.bimap;
};

var lmap = function (dictBifunctor) {
  return function (f) {
    return bimap(dictBifunctor)(f)(Control_Category.identity(Control_Category.categoryFn));
  };
};

var rmap = function (dictBifunctor) {
  return bimap(dictBifunctor)(Control_Category.identity(Control_Category.categoryFn));
};

module.exports = {
  bimap: bimap,
  Bifunctor: Bifunctor,
  lmap: lmap,
  rmap: rmap
};