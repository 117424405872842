// Generated by purs version 0.13.8
"use strict";

var $foreign = require("./foreign.js");

var crash = function (dictPartial) {
  return $foreign.crashWith()("Partial.crash: partial function");
};

module.exports = {
  crash: crash,
  crashWith: $foreign.crashWith
};