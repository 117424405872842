"use strict"; // module Math

exports.abs = Math.abs;
exports.acos = Math.acos;
exports.asin = Math.asin;
exports.atan = Math.atan;

exports.atan2 = function (y) {
  return function (x) {
    return Math.atan2(y, x);
  };
};

exports.ceil = Math.ceil;
exports.cos = Math.cos;
exports.exp = Math.exp;
exports.floor = Math.floor;

exports.trunc = Math.trunc || function (n) {
  return n < 0 ? Math.ceil(n) : Math.floor(n);
};

exports.log = Math.log;

exports.max = function (n1) {
  return function (n2) {
    return Math.max(n1, n2);
  };
};

exports.min = function (n1) {
  return function (n2) {
    return Math.min(n1, n2);
  };
};

exports.pow = function (n) {
  return function (p) {
    return Math.pow(n, p);
  };
};

exports.remainder = function (n) {
  return function (m) {
    return n % m;
  };
};

exports.round = Math.round;
exports.sin = Math.sin;
exports.sqrt = Math.sqrt;
exports.tan = Math.tan;
exports.e = Math.E;
exports.ln2 = Math.LN2;
exports.ln10 = Math.LN10;
exports.log2e = Math.LOG2E;
exports.log10e = Math.LOG10E;
exports.pi = Math.PI;
exports.tau = 2 * Math.PI;
exports.sqrt1_2 = Math.SQRT1_2;
exports.sqrt2 = Math.SQRT2;