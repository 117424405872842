"use strict";

exports.mapWithIndexArray = function (f) {
  return function (xs) {
    var l = xs.length;
    var result = Array(l);

    for (var i = 0; i < l; i++) {
      result[i] = f(i)(xs[i]);
    }

    return result;
  };
};