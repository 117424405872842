// Generated by purs version 0.13.8
"use strict";

var Control_Alt = require("../Control.Alt/index.js");

var Control_Alternative = require("../Control.Alternative/index.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Category = require("../Control.Category/index.js");

var Control_Extend = require("../Control.Extend/index.js");

var Control_Monad = require("../Control.Monad/index.js");

var Control_MonadZero = require("../Control.MonadZero/index.js");

var Control_Plus = require("../Control.Plus/index.js");

var Data_Bounded = require("../Data.Bounded/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Functor_Invariant = require("../Data.Functor.Invariant/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Ordering = require("../Data.Ordering/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Nothing = function () {
  function Nothing() {}

  ;
  Nothing.value = new Nothing();
  return Nothing;
}();

var Just = function () {
  function Just(value0) {
    this.value0 = value0;
  }

  ;

  Just.create = function (value0) {
    return new Just(value0);
  };

  return Just;
}();

var showMaybe = function (dictShow) {
  return new Data_Show.Show(function (v) {
    if (v instanceof Just) {
      return "(Just " + (Data_Show.show(dictShow)(v.value0) + ")");
    }

    ;

    if (v instanceof Nothing) {
      return "Nothing";
    }

    ;
    throw new Error("Failed pattern match at Data.Maybe (line 205, column 1 - line 207, column 28): " + [v.constructor.name]);
  });
};

var semigroupMaybe = function (dictSemigroup) {
  return new Data_Semigroup.Semigroup(function (v) {
    return function (v1) {
      if (v instanceof Nothing) {
        return v1;
      }

      ;

      if (v1 instanceof Nothing) {
        return v;
      }

      ;

      if (v instanceof Just && v1 instanceof Just) {
        return new Just(Data_Semigroup.append(dictSemigroup)(v.value0)(v1.value0));
      }

      ;
      throw new Error("Failed pattern match at Data.Maybe (line 174, column 1 - line 177, column 43): " + [v.constructor.name, v1.constructor.name]);
    };
  });
};

var optional = function (dictAlternative) {
  return function (a) {
    return Control_Alt.alt(dictAlternative.Plus1().Alt0())(Data_Functor.map(dictAlternative.Plus1().Alt0().Functor0())(Just.create)(a))(Control_Applicative.pure(dictAlternative.Applicative0())(Nothing.value));
  };
};

var monoidMaybe = function (dictSemigroup) {
  return new Data_Monoid.Monoid(function () {
    return semigroupMaybe(dictSemigroup);
  }, Nothing.value);
};

var maybe$prime = function (v) {
  return function (v1) {
    return function (v2) {
      if (v2 instanceof Nothing) {
        return v(Data_Unit.unit);
      }

      ;

      if (v2 instanceof Just) {
        return v1(v2.value0);
      }

      ;
      throw new Error("Failed pattern match at Data.Maybe (line 230, column 1 - line 230, column 62): " + [v.constructor.name, v1.constructor.name, v2.constructor.name]);
    };
  };
};

var maybe = function (v) {
  return function (v1) {
    return function (v2) {
      if (v2 instanceof Nothing) {
        return v;
      }

      ;

      if (v2 instanceof Just) {
        return v1(v2.value0);
      }

      ;
      throw new Error("Failed pattern match at Data.Maybe (line 217, column 1 - line 217, column 51): " + [v.constructor.name, v1.constructor.name, v2.constructor.name]);
    };
  };
};

var isNothing = maybe(true)(Data_Function["const"](false));
var isJust = maybe(false)(Data_Function["const"](true));
var functorMaybe = new Data_Functor.Functor(function (v) {
  return function (v1) {
    if (v1 instanceof Just) {
      return new Just(v(v1.value0));
    }

    ;
    return Nothing.value;
  };
});
var invariantMaybe = new Data_Functor_Invariant.Invariant(Data_Functor_Invariant.imapF(functorMaybe));

var fromMaybe$prime = function (a) {
  return maybe$prime(a)(Control_Category.identity(Control_Category.categoryFn));
};

var fromMaybe = function (a) {
  return maybe(a)(Control_Category.identity(Control_Category.categoryFn));
};

var fromJust = function (dictPartial) {
  return function (v) {
    if (v instanceof Just) {
      return v.value0;
    }

    ;
    throw new Error("Failed pattern match at Data.Maybe (line 268, column 1 - line 268, column 46): " + [v.constructor.name]);
  };
};

var extendMaybe = new Control_Extend.Extend(function () {
  return functorMaybe;
}, function (v) {
  return function (v1) {
    if (v1 instanceof Nothing) {
      return Nothing.value;
    }

    ;
    return new Just(v(v1));
  };
});

var eqMaybe = function (dictEq) {
  return new Data_Eq.Eq(function (x) {
    return function (y) {
      if (x instanceof Nothing && y instanceof Nothing) {
        return true;
      }

      ;

      if (x instanceof Just && y instanceof Just) {
        return Data_Eq.eq(dictEq)(x.value0)(y.value0);
      }

      ;
      return false;
    };
  });
};

var ordMaybe = function (dictOrd) {
  return new Data_Ord.Ord(function () {
    return eqMaybe(dictOrd.Eq0());
  }, function (x) {
    return function (y) {
      if (x instanceof Nothing && y instanceof Nothing) {
        return Data_Ordering.EQ.value;
      }

      ;

      if (x instanceof Nothing) {
        return Data_Ordering.LT.value;
      }

      ;

      if (y instanceof Nothing) {
        return Data_Ordering.GT.value;
      }

      ;

      if (x instanceof Just && y instanceof Just) {
        return Data_Ord.compare(dictOrd)(x.value0)(y.value0);
      }

      ;
      throw new Error("Failed pattern match at Data.Maybe (line 194, column 1 - line 194, column 51): " + [x.constructor.name, y.constructor.name]);
    };
  });
};

var eq1Maybe = new Data_Eq.Eq1(function (dictEq) {
  return Data_Eq.eq(eqMaybe(dictEq));
});
var ord1Maybe = new Data_Ord.Ord1(function () {
  return eq1Maybe;
}, function (dictOrd) {
  return Data_Ord.compare(ordMaybe(dictOrd));
});

var boundedMaybe = function (dictBounded) {
  return new Data_Bounded.Bounded(function () {
    return ordMaybe(dictBounded.Ord0());
  }, Nothing.value, new Just(Data_Bounded.top(dictBounded)));
};

var applyMaybe = new Control_Apply.Apply(function () {
  return functorMaybe;
}, function (v) {
  return function (v1) {
    if (v instanceof Just) {
      return Data_Functor.map(functorMaybe)(v.value0)(v1);
    }

    ;

    if (v instanceof Nothing) {
      return Nothing.value;
    }

    ;
    throw new Error("Failed pattern match at Data.Maybe (line 67, column 1 - line 69, column 30): " + [v.constructor.name, v1.constructor.name]);
  };
});
var bindMaybe = new Control_Bind.Bind(function () {
  return applyMaybe;
}, function (v) {
  return function (v1) {
    if (v instanceof Just) {
      return v1(v.value0);
    }

    ;

    if (v instanceof Nothing) {
      return Nothing.value;
    }

    ;
    throw new Error("Failed pattern match at Data.Maybe (line 125, column 1 - line 127, column 28): " + [v.constructor.name, v1.constructor.name]);
  };
});
var applicativeMaybe = new Control_Applicative.Applicative(function () {
  return applyMaybe;
}, Just.create);
var monadMaybe = new Control_Monad.Monad(function () {
  return applicativeMaybe;
}, function () {
  return bindMaybe;
});
var altMaybe = new Control_Alt.Alt(function () {
  return functorMaybe;
}, function (v) {
  return function (v1) {
    if (v instanceof Nothing) {
      return v1;
    }

    ;
    return v;
  };
});
var plusMaybe = new Control_Plus.Plus(function () {
  return altMaybe;
}, Nothing.value);
var alternativeMaybe = new Control_Alternative.Alternative(function () {
  return applicativeMaybe;
}, function () {
  return plusMaybe;
});
var monadZeroMaybe = new Control_MonadZero.MonadZero(function () {
  return alternativeMaybe;
}, function () {
  return monadMaybe;
});
module.exports = {
  Nothing: Nothing,
  Just: Just,
  maybe: maybe,
  "maybe'": maybe$prime,
  fromMaybe: fromMaybe,
  "fromMaybe'": fromMaybe$prime,
  isJust: isJust,
  isNothing: isNothing,
  fromJust: fromJust,
  optional: optional,
  functorMaybe: functorMaybe,
  applyMaybe: applyMaybe,
  applicativeMaybe: applicativeMaybe,
  altMaybe: altMaybe,
  plusMaybe: plusMaybe,
  alternativeMaybe: alternativeMaybe,
  bindMaybe: bindMaybe,
  monadMaybe: monadMaybe,
  monadZeroMaybe: monadZeroMaybe,
  extendMaybe: extendMaybe,
  invariantMaybe: invariantMaybe,
  semigroupMaybe: semigroupMaybe,
  monoidMaybe: monoidMaybe,
  eqMaybe: eqMaybe,
  eq1Maybe: eq1Maybe,
  ordMaybe: ordMaybe,
  ord1Maybe: ord1Maybe,
  boundedMaybe: boundedMaybe,
  showMaybe: showMaybe
};