// Generated by purs version 0.13.8
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Category = require("../Control.Category/index.js");

var Data_Bifoldable = require("../Data.Bifoldable/index.js");

var Data_Bifunctor = require("../Data.Bifunctor/index.js");

var Data_Bifunctor_Clown = require("../Data.Bifunctor.Clown/index.js");

var Data_Bifunctor_Flip = require("../Data.Bifunctor.Flip/index.js");

var Data_Bifunctor_Joker = require("../Data.Bifunctor.Joker/index.js");

var Data_Bifunctor_Product = require("../Data.Bifunctor.Product/index.js");

var Data_Bifunctor_Wrap = require("../Data.Bifunctor.Wrap/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Traversable = require("../Data.Traversable/index.js");

var Bitraversable = function (Bifoldable1, Bifunctor0, bisequence, bitraverse) {
  this.Bifoldable1 = Bifoldable1;
  this.Bifunctor0 = Bifunctor0;
  this.bisequence = bisequence;
  this.bitraverse = bitraverse;
};

var bitraverse = function (dict) {
  return dict.bitraverse;
};

var lfor = function (dictBitraversable) {
  return function (dictApplicative) {
    return function (t) {
      return function (f) {
        return bitraverse(dictBitraversable)(dictApplicative)(f)(Control_Applicative.pure(dictApplicative))(t);
      };
    };
  };
};

var ltraverse = function (dictBitraversable) {
  return function (dictApplicative) {
    return function (f) {
      return bitraverse(dictBitraversable)(dictApplicative)(f)(Control_Applicative.pure(dictApplicative));
    };
  };
};

var rfor = function (dictBitraversable) {
  return function (dictApplicative) {
    return function (t) {
      return function (f) {
        return bitraverse(dictBitraversable)(dictApplicative)(Control_Applicative.pure(dictApplicative))(f)(t);
      };
    };
  };
};

var rtraverse = function (dictBitraversable) {
  return function (dictApplicative) {
    return bitraverse(dictBitraversable)(dictApplicative)(Control_Applicative.pure(dictApplicative));
  };
};

var bitraversableJoker = function (dictTraversable) {
  return new Bitraversable(function () {
    return Data_Bifoldable.bifoldableJoker(dictTraversable.Foldable1());
  }, function () {
    return Data_Bifunctor_Joker.bifunctorJoker(dictTraversable.Functor0());
  }, function (dictApplicative) {
    return function (v) {
      return Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Bifunctor_Joker.Joker)(Data_Traversable.sequence(dictTraversable)(dictApplicative)(v));
    };
  }, function (dictApplicative) {
    return function (v) {
      return function (r) {
        return function (v1) {
          return Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Bifunctor_Joker.Joker)(Data_Traversable.traverse(dictTraversable)(dictApplicative)(r)(v1));
        };
      };
    };
  });
};

var bitraversableClown = function (dictTraversable) {
  return new Bitraversable(function () {
    return Data_Bifoldable.bifoldableClown(dictTraversable.Foldable1());
  }, function () {
    return Data_Bifunctor_Clown.bifunctorClown(dictTraversable.Functor0());
  }, function (dictApplicative) {
    return function (v) {
      return Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Bifunctor_Clown.Clown)(Data_Traversable.sequence(dictTraversable)(dictApplicative)(v));
    };
  }, function (dictApplicative) {
    return function (l) {
      return function (v) {
        return function (v1) {
          return Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Bifunctor_Clown.Clown)(Data_Traversable.traverse(dictTraversable)(dictApplicative)(l)(v1));
        };
      };
    };
  });
};

var bisequenceDefault = function (dictBitraversable) {
  return function (dictApplicative) {
    return bitraverse(dictBitraversable)(dictApplicative)(Control_Category.identity(Control_Category.categoryFn))(Control_Category.identity(Control_Category.categoryFn));
  };
};

var bisequence = function (dict) {
  return dict.bisequence;
};

var bitraversableFlip = function (dictBitraversable) {
  return new Bitraversable(function () {
    return Data_Bifoldable.bifoldableFlip(dictBitraversable.Bifoldable1());
  }, function () {
    return Data_Bifunctor_Flip.bifunctorFlip(dictBitraversable.Bifunctor0());
  }, function (dictApplicative) {
    return function (v) {
      return Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Bifunctor_Flip.Flip)(bisequence(dictBitraversable)(dictApplicative)(v));
    };
  }, function (dictApplicative) {
    return function (r) {
      return function (l) {
        return function (v) {
          return Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Bifunctor_Flip.Flip)(bitraverse(dictBitraversable)(dictApplicative)(l)(r)(v));
        };
      };
    };
  });
};

var bitraversableProduct = function (dictBitraversable) {
  return function (dictBitraversable1) {
    return new Bitraversable(function () {
      return Data_Bifoldable.bifoldableProduct(dictBitraversable.Bifoldable1())(dictBitraversable1.Bifoldable1());
    }, function () {
      return Data_Bifunctor_Product.bifunctorProduct(dictBitraversable.Bifunctor0())(dictBitraversable1.Bifunctor0());
    }, function (dictApplicative) {
      return function (v) {
        return Control_Apply.apply(dictApplicative.Apply0())(Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Bifunctor_Product.Product.create)(bisequence(dictBitraversable)(dictApplicative)(v.value0)))(bisequence(dictBitraversable1)(dictApplicative)(v.value1));
      };
    }, function (dictApplicative) {
      return function (l) {
        return function (r) {
          return function (v) {
            return Control_Apply.apply(dictApplicative.Apply0())(Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Bifunctor_Product.Product.create)(bitraverse(dictBitraversable)(dictApplicative)(l)(r)(v.value0)))(bitraverse(dictBitraversable1)(dictApplicative)(l)(r)(v.value1));
          };
        };
      };
    });
  };
};

var bitraversableWrap = function (dictBitraversable) {
  return new Bitraversable(function () {
    return Data_Bifoldable.bifoldableWrap(dictBitraversable.Bifoldable1());
  }, function () {
    return Data_Bifunctor_Wrap.bifunctorWrap(dictBitraversable.Bifunctor0());
  }, function (dictApplicative) {
    return function (v) {
      return Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Bifunctor_Wrap.Wrap)(bisequence(dictBitraversable)(dictApplicative)(v));
    };
  }, function (dictApplicative) {
    return function (l) {
      return function (r) {
        return function (v) {
          return Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Bifunctor_Wrap.Wrap)(bitraverse(dictBitraversable)(dictApplicative)(l)(r)(v));
        };
      };
    };
  });
};

var bitraverseDefault = function (dictBitraversable) {
  return function (dictApplicative) {
    return function (f) {
      return function (g) {
        return function (t) {
          return bisequence(dictBitraversable)(dictApplicative)(Data_Bifunctor.bimap(dictBitraversable.Bifunctor0())(f)(g)(t));
        };
      };
    };
  };
};

var bifor = function (dictBitraversable) {
  return function (dictApplicative) {
    return function (t) {
      return function (f) {
        return function (g) {
          return bitraverse(dictBitraversable)(dictApplicative)(f)(g)(t);
        };
      };
    };
  };
};

module.exports = {
  Bitraversable: Bitraversable,
  bitraverse: bitraverse,
  bisequence: bisequence,
  bitraverseDefault: bitraverseDefault,
  bisequenceDefault: bisequenceDefault,
  ltraverse: ltraverse,
  rtraverse: rtraverse,
  bifor: bifor,
  lfor: lfor,
  rfor: rfor,
  bitraversableClown: bitraversableClown,
  bitraversableJoker: bitraversableJoker,
  bitraversableFlip: bitraversableFlip,
  bitraversableProduct: bitraversableProduct,
  bitraversableWrap: bitraversableWrap
};