// Generated by purs version 0.13.8
"use strict";

var Control_Alt = require("../Control.Alt/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_String_Extra = require("../Data.String.Extra/index.js");

var Data_Symbol = require("../Data.Symbol/index.js");

var PathDupe = function (fromPath, toPath) {
  this.fromPath = fromPath;
  this.toPath = toPath;
};

var toPath = function (dict) {
  return dict.toPath;
};

var genericToPath = function (dictGeneric) {
  return function (dictPathDupe) {
    return function (screen) {
      return toPath(dictPathDupe)(Data_Generic_Rep.from(dictGeneric)(screen));
    };
  };
};

var fromPath = function (dict) {
  return dict.fromPath;
};

var genericFromPath = function (dictGeneric) {
  return function (dictPathDupe) {
    return function (p) {
      return Data_Functor.mapFlipped(Data_Maybe.functorMaybe)(fromPath(dictPathDupe)(p))(Data_Generic_Rep.to(dictGeneric));
    };
  };
};

var sumPathDupe = function (dictPathDupe) {
  return function (dictPathDupe1) {
    return new PathDupe(function (s) {
      return Control_Alt.alt(Data_Maybe.altMaybe)(Control_Bind.bind(Data_Maybe.bindMaybe)(fromPath(dictPathDupe)(s))(function ($14) {
        return Data_Maybe.Just.create(Data_Generic_Rep.Inl.create($14));
      }))(Control_Bind.bind(Data_Maybe.bindMaybe)(fromPath(dictPathDupe1)(s))(function ($15) {
        return Data_Maybe.Just.create(Data_Generic_Rep.Inr.create($15));
      }));
    }, function (v) {
      if (v instanceof Data_Generic_Rep.Inl) {
        return toPath(dictPathDupe)(v.value0);
      }

      ;

      if (v instanceof Data_Generic_Rep.Inr) {
        return toPath(dictPathDupe1)(v.value0);
      }

      ;
      throw new Error("Failed pattern match at ZAP.Trovex.Client.Utils.PathDupe (line 21, column 1 - line 24, column 79): " + [v.constructor.name]);
    });
  };
};

var constructorNoArgPathDup = function (dictIsSymbol) {
  return new PathDupe(function (path) {
    var strPath = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
    var $12 = Data_String_Extra.pascalCase(path) === strPath;

    if ($12) {
      return new Data_Maybe.Just(Data_Generic_Rep.NoArguments.value);
    }

    ;
    return Data_Maybe.Nothing.value;
  }, function (v) {
    return Data_String_Extra.kebabCase(Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value));
  });
};

module.exports = {
  fromPath: fromPath,
  toPath: toPath,
  PathDupe: PathDupe,
  genericToPath: genericToPath,
  genericFromPath: genericFromPath,
  sumPathDupe: sumPathDupe,
  constructorNoArgPathDup: constructorNoArgPathDup
};