import { Stage } from './types';

export const zoneId = 'Z4ORC6IKZFOCJ';
export const domainName = 'zap.com.ph';
export const subDomainName = 'catalog';

export const zenv: Stage = (() => {
  switch (process.env.ZENV) {
    case Stage.STAGING:
      return Stage.STAGING;
    case Stage.PREPROD:
      return Stage.PREPROD;
    case Stage.PROD:
      return Stage.PROD;
    default:
      return Stage.STAGING;
  }
})();

export const recaptchaKey = process.env.RECAPTCHA_KEY;
