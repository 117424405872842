import React from 'react';
import styled from 'styled-components';
import { Spinner } from './Spinner';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  height: 100%;
  width: 100vw;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.4);
  & > div {
    margin-top: -15vh;
  }
`;
interface Props {
  show: boolean;
}
export const FullPageSpinner: React.FC<Props> = ({ show }) => {
  return show ? (
    <Container>
      <Spinner />
    </Container>
  ) : null;
};
