// Generated by purs version 0.13.8
"use strict";

var Biapplicative = function (Biapply0, bipure) {
  this.Biapply0 = Biapply0;
  this.bipure = bipure;
};

var bipure = function (dict) {
  return dict.bipure;
};

module.exports = {
  bipure: bipure,
  Biapplicative: Biapplicative
};