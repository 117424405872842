// Generated by purs version 0.13.8
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Category = require("../Control.Category/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_FunctorWithIndex = require("../Data.FunctorWithIndex/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Monoid_Conj = require("../Data.Monoid.Conj/index.js");

var Data_Monoid_Disj = require("../Data.Monoid.Disj/index.js");

var Data_Monoid_Dual = require("../Data.Monoid.Dual/index.js");

var Data_Monoid_Endo = require("../Data.Monoid.Endo/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Tuple = function () {
  function Tuple(value0, value1) {
    this.value0 = value0;
    this.value1 = value1;
  }

  ;

  Tuple.create = function (value0) {
    return function (value1) {
      return new Tuple(value0, value1);
    };
  };

  return Tuple;
}();

var FoldableWithIndex = function (Foldable0, foldMapWithIndex, foldlWithIndex, foldrWithIndex) {
  this.Foldable0 = Foldable0;
  this.foldMapWithIndex = foldMapWithIndex;
  this.foldlWithIndex = foldlWithIndex;
  this.foldrWithIndex = foldrWithIndex;
};

var foldrWithIndex = function (dict) {
  return dict.foldrWithIndex;
};

var traverseWithIndex_ = function (dictApplicative) {
  return function (dictFoldableWithIndex) {
    return function (f) {
      return foldrWithIndex(dictFoldableWithIndex)(function (i) {
        var $46 = Control_Apply.applySecond(dictApplicative.Apply0());
        var $47 = f(i);
        return function ($48) {
          return $46($47($48));
        };
      })(Control_Applicative.pure(dictApplicative)(Data_Unit.unit));
    };
  };
};

var forWithIndex_ = function (dictApplicative) {
  return function (dictFoldableWithIndex) {
    return Data_Function.flip(traverseWithIndex_(dictApplicative)(dictFoldableWithIndex));
  };
};

var foldrDefault = function (dictFoldableWithIndex) {
  return function (f) {
    return foldrWithIndex(dictFoldableWithIndex)(Data_Function["const"](f));
  };
};

var foldlWithIndex = function (dict) {
  return dict.foldlWithIndex;
};

var foldlDefault = function (dictFoldableWithIndex) {
  return function (f) {
    return foldlWithIndex(dictFoldableWithIndex)(Data_Function["const"](f));
  };
};

var foldableWithIndexMultiplicative = new FoldableWithIndex(function () {
  return Data_Foldable.foldableMultiplicative;
}, function (dictMonoid) {
  return function (f) {
    return Data_Foldable.foldMap(Data_Foldable.foldableMultiplicative)(dictMonoid)(f(Data_Unit.unit));
  };
}, function (f) {
  return Data_Foldable.foldl(Data_Foldable.foldableMultiplicative)(f(Data_Unit.unit));
}, function (f) {
  return Data_Foldable.foldr(Data_Foldable.foldableMultiplicative)(f(Data_Unit.unit));
});
var foldableWithIndexMaybe = new FoldableWithIndex(function () {
  return Data_Foldable.foldableMaybe;
}, function (dictMonoid) {
  return function (f) {
    return Data_Foldable.foldMap(Data_Foldable.foldableMaybe)(dictMonoid)(f(Data_Unit.unit));
  };
}, function (f) {
  return Data_Foldable.foldl(Data_Foldable.foldableMaybe)(f(Data_Unit.unit));
}, function (f) {
  return Data_Foldable.foldr(Data_Foldable.foldableMaybe)(f(Data_Unit.unit));
});
var foldableWithIndexLast = new FoldableWithIndex(function () {
  return Data_Foldable.foldableLast;
}, function (dictMonoid) {
  return function (f) {
    return Data_Foldable.foldMap(Data_Foldable.foldableLast)(dictMonoid)(f(Data_Unit.unit));
  };
}, function (f) {
  return Data_Foldable.foldl(Data_Foldable.foldableLast)(f(Data_Unit.unit));
}, function (f) {
  return Data_Foldable.foldr(Data_Foldable.foldableLast)(f(Data_Unit.unit));
});
var foldableWithIndexFirst = new FoldableWithIndex(function () {
  return Data_Foldable.foldableFirst;
}, function (dictMonoid) {
  return function (f) {
    return Data_Foldable.foldMap(Data_Foldable.foldableFirst)(dictMonoid)(f(Data_Unit.unit));
  };
}, function (f) {
  return Data_Foldable.foldl(Data_Foldable.foldableFirst)(f(Data_Unit.unit));
}, function (f) {
  return Data_Foldable.foldr(Data_Foldable.foldableFirst)(f(Data_Unit.unit));
});
var foldableWithIndexDual = new FoldableWithIndex(function () {
  return Data_Foldable.foldableDual;
}, function (dictMonoid) {
  return function (f) {
    return Data_Foldable.foldMap(Data_Foldable.foldableDual)(dictMonoid)(f(Data_Unit.unit));
  };
}, function (f) {
  return Data_Foldable.foldl(Data_Foldable.foldableDual)(f(Data_Unit.unit));
}, function (f) {
  return Data_Foldable.foldr(Data_Foldable.foldableDual)(f(Data_Unit.unit));
});
var foldableWithIndexDisj = new FoldableWithIndex(function () {
  return Data_Foldable.foldableDisj;
}, function (dictMonoid) {
  return function (f) {
    return Data_Foldable.foldMap(Data_Foldable.foldableDisj)(dictMonoid)(f(Data_Unit.unit));
  };
}, function (f) {
  return Data_Foldable.foldl(Data_Foldable.foldableDisj)(f(Data_Unit.unit));
}, function (f) {
  return Data_Foldable.foldr(Data_Foldable.foldableDisj)(f(Data_Unit.unit));
});
var foldableWithIndexConj = new FoldableWithIndex(function () {
  return Data_Foldable.foldableConj;
}, function (dictMonoid) {
  return function (f) {
    return Data_Foldable.foldMap(Data_Foldable.foldableConj)(dictMonoid)(f(Data_Unit.unit));
  };
}, function (f) {
  return Data_Foldable.foldl(Data_Foldable.foldableConj)(f(Data_Unit.unit));
}, function (f) {
  return Data_Foldable.foldr(Data_Foldable.foldableConj)(f(Data_Unit.unit));
});
var foldableWithIndexAdditive = new FoldableWithIndex(function () {
  return Data_Foldable.foldableAdditive;
}, function (dictMonoid) {
  return function (f) {
    return Data_Foldable.foldMap(Data_Foldable.foldableAdditive)(dictMonoid)(f(Data_Unit.unit));
  };
}, function (f) {
  return Data_Foldable.foldl(Data_Foldable.foldableAdditive)(f(Data_Unit.unit));
}, function (f) {
  return Data_Foldable.foldr(Data_Foldable.foldableAdditive)(f(Data_Unit.unit));
});

var foldWithIndexM = function (dictFoldableWithIndex) {
  return function (dictMonad) {
    return function (f) {
      return function (a0) {
        return foldlWithIndex(dictFoldableWithIndex)(function (i) {
          return function (ma) {
            return function (b) {
              return Control_Bind.bind(dictMonad.Bind1())(ma)(Data_Function.flip(f(i))(b));
            };
          };
        })(Control_Applicative.pure(dictMonad.Applicative0())(a0));
      };
    };
  };
};

var foldMapWithIndexDefaultR = function (dictFoldableWithIndex) {
  return function (dictMonoid) {
    return function (f) {
      return foldrWithIndex(dictFoldableWithIndex)(function (i) {
        return function (x) {
          return function (acc) {
            return Data_Semigroup.append(dictMonoid.Semigroup0())(f(i)(x))(acc);
          };
        };
      })(Data_Monoid.mempty(dictMonoid));
    };
  };
};

var foldableWithIndexArray = new FoldableWithIndex(function () {
  return Data_Foldable.foldableArray;
}, function (dictMonoid) {
  return foldMapWithIndexDefaultR(foldableWithIndexArray)(dictMonoid);
}, function (f) {
  return function (z) {
    var $49 = Data_Foldable.foldl(Data_Foldable.foldableArray)(function (y) {
      return function (v) {
        return f(v.value0)(y)(v.value1);
      };
    })(z);
    var $50 = Data_FunctorWithIndex.mapWithIndex(Data_FunctorWithIndex.functorWithIndexArray)(Tuple.create);
    return function ($51) {
      return $49($50($51));
    };
  };
}, function (f) {
  return function (z) {
    var $52 = Data_Foldable.foldr(Data_Foldable.foldableArray)(function (v) {
      return function (y) {
        return f(v.value0)(v.value1)(y);
      };
    })(z);
    var $53 = Data_FunctorWithIndex.mapWithIndex(Data_FunctorWithIndex.functorWithIndexArray)(Tuple.create);
    return function ($54) {
      return $52($53($54));
    };
  };
});

var foldMapWithIndexDefaultL = function (dictFoldableWithIndex) {
  return function (dictMonoid) {
    return function (f) {
      return foldlWithIndex(dictFoldableWithIndex)(function (i) {
        return function (acc) {
          return function (x) {
            return Data_Semigroup.append(dictMonoid.Semigroup0())(acc)(f(i)(x));
          };
        };
      })(Data_Monoid.mempty(dictMonoid));
    };
  };
};

var foldMapWithIndex = function (dict) {
  return dict.foldMapWithIndex;
};

var foldlWithIndexDefault = function (dictFoldableWithIndex) {
  return function (c) {
    return function (u) {
      return function (xs) {
        return Data_Newtype.unwrap(Data_Newtype.newtypeEndo)(Data_Newtype.unwrap(Data_Newtype.newtypeDual)(foldMapWithIndex(dictFoldableWithIndex)(Data_Monoid_Dual.monoidDual(Data_Monoid_Endo.monoidEndo(Control_Category.categoryFn)))(function (i) {
          var $55 = Data_Function.flip(c(i));
          return function ($56) {
            return Data_Monoid_Dual.Dual(Data_Monoid_Endo.Endo($55($56)));
          };
        })(xs)))(u);
      };
    };
  };
};

var foldrWithIndexDefault = function (dictFoldableWithIndex) {
  return function (c) {
    return function (u) {
      return function (xs) {
        return Data_Newtype.unwrap(Data_Newtype.newtypeEndo)(foldMapWithIndex(dictFoldableWithIndex)(Data_Monoid_Endo.monoidEndo(Control_Category.categoryFn))(function (i) {
          var $57 = c(i);
          return function ($58) {
            return Data_Monoid_Endo.Endo($57($58));
          };
        })(xs))(u);
      };
    };
  };
};

var surroundMapWithIndex = function (dictFoldableWithIndex) {
  return function (dictSemigroup) {
    return function (d) {
      return function (t) {
        return function (f) {
          var joined = function (i) {
            return function (a) {
              return function (m) {
                return Data_Semigroup.append(dictSemigroup)(d)(Data_Semigroup.append(dictSemigroup)(t(i)(a))(m));
              };
            };
          };

          return Data_Newtype.unwrap(Data_Newtype.newtypeEndo)(foldMapWithIndex(dictFoldableWithIndex)(Data_Monoid_Endo.monoidEndo(Control_Category.categoryFn))(joined)(f))(d);
        };
      };
    };
  };
};

var foldMapDefault = function (dictFoldableWithIndex) {
  return function (dictMonoid) {
    return function (f) {
      return foldMapWithIndex(dictFoldableWithIndex)(dictMonoid)(Data_Function["const"](f));
    };
  };
};

var findWithIndex = function (dictFoldableWithIndex) {
  return function (p) {
    var go = function (v) {
      return function (v1) {
        return function (v2) {
          if (v1 instanceof Data_Maybe.Nothing && p(v)(v2)) {
            return new Data_Maybe.Just({
              index: v,
              value: v2
            });
          }

          ;
          return v1;
        };
      };
    };

    return foldlWithIndex(dictFoldableWithIndex)(go)(Data_Maybe.Nothing.value);
  };
};

var anyWithIndex = function (dictFoldableWithIndex) {
  return function (dictHeytingAlgebra) {
    return function (t) {
      var $59 = Data_Newtype.unwrap(Data_Newtype.newtypeDisj);
      var $60 = foldMapWithIndex(dictFoldableWithIndex)(Data_Monoid_Disj.monoidDisj(dictHeytingAlgebra))(function (i) {
        var $62 = t(i);
        return function ($63) {
          return Data_Monoid_Disj.Disj($62($63));
        };
      });
      return function ($61) {
        return $59($60($61));
      };
    };
  };
};

var allWithIndex = function (dictFoldableWithIndex) {
  return function (dictHeytingAlgebra) {
    return function (t) {
      var $64 = Data_Newtype.unwrap(Data_Newtype.newtypeConj);
      var $65 = foldMapWithIndex(dictFoldableWithIndex)(Data_Monoid_Conj.monoidConj(dictHeytingAlgebra))(function (i) {
        var $67 = t(i);
        return function ($68) {
          return Data_Monoid_Conj.Conj($67($68));
        };
      });
      return function ($66) {
        return $64($65($66));
      };
    };
  };
};

module.exports = {
  FoldableWithIndex: FoldableWithIndex,
  foldrWithIndex: foldrWithIndex,
  foldlWithIndex: foldlWithIndex,
  foldMapWithIndex: foldMapWithIndex,
  foldrWithIndexDefault: foldrWithIndexDefault,
  foldlWithIndexDefault: foldlWithIndexDefault,
  foldMapWithIndexDefaultR: foldMapWithIndexDefaultR,
  foldMapWithIndexDefaultL: foldMapWithIndexDefaultL,
  foldWithIndexM: foldWithIndexM,
  traverseWithIndex_: traverseWithIndex_,
  forWithIndex_: forWithIndex_,
  surroundMapWithIndex: surroundMapWithIndex,
  allWithIndex: allWithIndex,
  anyWithIndex: anyWithIndex,
  findWithIndex: findWithIndex,
  foldrDefault: foldrDefault,
  foldlDefault: foldlDefault,
  foldMapDefault: foldMapDefault,
  foldableWithIndexArray: foldableWithIndexArray,
  foldableWithIndexMaybe: foldableWithIndexMaybe,
  foldableWithIndexFirst: foldableWithIndexFirst,
  foldableWithIndexLast: foldableWithIndexLast,
  foldableWithIndexAdditive: foldableWithIndexAdditive,
  foldableWithIndexDual: foldableWithIndexDual,
  foldableWithIndexDisj: foldableWithIndexDisj,
  foldableWithIndexConj: foldableWithIndexConj,
  foldableWithIndexMultiplicative: foldableWithIndexMultiplicative
};