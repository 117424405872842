// Generated by purs version 0.13.8
"use strict";

var $foreign = require("./foreign.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Category = require("../Control.Category/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Maybe_First = require("../Data.Maybe.First/index.js");

var Data_Maybe_Last = require("../Data.Maybe.Last/index.js");

var Data_Monoid_Additive = require("../Data.Monoid.Additive/index.js");

var Data_Monoid_Conj = require("../Data.Monoid.Conj/index.js");

var Data_Monoid_Disj = require("../Data.Monoid.Disj/index.js");

var Data_Monoid_Dual = require("../Data.Monoid.Dual/index.js");

var Data_Monoid_Multiplicative = require("../Data.Monoid.Multiplicative/index.js");

var Data_Traversable_Accum_Internal = require("../Data.Traversable.Accum.Internal/index.js");

var Traversable = function (Foldable1, Functor0, sequence, traverse) {
  this.Foldable1 = Foldable1;
  this.Functor0 = Functor0;
  this.sequence = sequence;
  this.traverse = traverse;
};

var traverse = function (dict) {
  return dict.traverse;
};

var traversableMultiplicative = new Traversable(function () {
  return Data_Foldable.foldableMultiplicative;
}, function () {
  return Data_Monoid_Multiplicative.functorMultiplicative;
}, function (dictApplicative) {
  return function (v) {
    return Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Monoid_Multiplicative.Multiplicative)(v);
  };
}, function (dictApplicative) {
  return function (f) {
    return function (v) {
      return Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Monoid_Multiplicative.Multiplicative)(f(v));
    };
  };
});
var traversableMaybe = new Traversable(function () {
  return Data_Foldable.foldableMaybe;
}, function () {
  return Data_Maybe.functorMaybe;
}, function (dictApplicative) {
  return function (v) {
    if (v instanceof Data_Maybe.Nothing) {
      return Control_Applicative.pure(dictApplicative)(Data_Maybe.Nothing.value);
    }

    ;

    if (v instanceof Data_Maybe.Just) {
      return Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Maybe.Just.create)(v.value0);
    }

    ;
    throw new Error("Failed pattern match at Data.Traversable (line 86, column 1 - line 90, column 33): " + [v.constructor.name]);
  };
}, function (dictApplicative) {
  return function (v) {
    return function (v1) {
      if (v1 instanceof Data_Maybe.Nothing) {
        return Control_Applicative.pure(dictApplicative)(Data_Maybe.Nothing.value);
      }

      ;

      if (v1 instanceof Data_Maybe.Just) {
        return Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Maybe.Just.create)(v(v1.value0));
      }

      ;
      throw new Error("Failed pattern match at Data.Traversable (line 86, column 1 - line 90, column 33): " + [v.constructor.name, v1.constructor.name]);
    };
  };
});
var traversableDual = new Traversable(function () {
  return Data_Foldable.foldableDual;
}, function () {
  return Data_Monoid_Dual.functorDual;
}, function (dictApplicative) {
  return function (v) {
    return Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Monoid_Dual.Dual)(v);
  };
}, function (dictApplicative) {
  return function (f) {
    return function (v) {
      return Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Monoid_Dual.Dual)(f(v));
    };
  };
});
var traversableDisj = new Traversable(function () {
  return Data_Foldable.foldableDisj;
}, function () {
  return Data_Monoid_Disj.functorDisj;
}, function (dictApplicative) {
  return function (v) {
    return Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Monoid_Disj.Disj)(v);
  };
}, function (dictApplicative) {
  return function (f) {
    return function (v) {
      return Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Monoid_Disj.Disj)(f(v));
    };
  };
});
var traversableConj = new Traversable(function () {
  return Data_Foldable.foldableConj;
}, function () {
  return Data_Monoid_Conj.functorConj;
}, function (dictApplicative) {
  return function (v) {
    return Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Monoid_Conj.Conj)(v);
  };
}, function (dictApplicative) {
  return function (f) {
    return function (v) {
      return Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Monoid_Conj.Conj)(f(v));
    };
  };
});
var traversableAdditive = new Traversable(function () {
  return Data_Foldable.foldableAdditive;
}, function () {
  return Data_Monoid_Additive.functorAdditive;
}, function (dictApplicative) {
  return function (v) {
    return Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Monoid_Additive.Additive)(v);
  };
}, function (dictApplicative) {
  return function (f) {
    return function (v) {
      return Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Monoid_Additive.Additive)(f(v));
    };
  };
});

var sequenceDefault = function (dictTraversable) {
  return function (dictApplicative) {
    return traverse(dictTraversable)(dictApplicative)(Control_Category.identity(Control_Category.categoryFn));
  };
};

var traversableArray = new Traversable(function () {
  return Data_Foldable.foldableArray;
}, function () {
  return Data_Functor.functorArray;
}, function (dictApplicative) {
  return sequenceDefault(traversableArray)(dictApplicative);
}, function (dictApplicative) {
  return $foreign.traverseArrayImpl(Control_Apply.apply(dictApplicative.Apply0()))(Data_Functor.map(dictApplicative.Apply0().Functor0()))(Control_Applicative.pure(dictApplicative));
});

var sequence = function (dict) {
  return dict.sequence;
};

var traversableFirst = new Traversable(function () {
  return Data_Foldable.foldableFirst;
}, function () {
  return Data_Maybe_First.functorFirst;
}, function (dictApplicative) {
  return function (v) {
    return Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Maybe_First.First)(sequence(traversableMaybe)(dictApplicative)(v));
  };
}, function (dictApplicative) {
  return function (f) {
    return function (v) {
      return Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Maybe_First.First)(traverse(traversableMaybe)(dictApplicative)(f)(v));
    };
  };
});
var traversableLast = new Traversable(function () {
  return Data_Foldable.foldableLast;
}, function () {
  return Data_Maybe_Last.functorLast;
}, function (dictApplicative) {
  return function (v) {
    return Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Maybe_Last.Last)(sequence(traversableMaybe)(dictApplicative)(v));
  };
}, function (dictApplicative) {
  return function (f) {
    return function (v) {
      return Data_Functor.map(dictApplicative.Apply0().Functor0())(Data_Maybe_Last.Last)(traverse(traversableMaybe)(dictApplicative)(f)(v));
    };
  };
});

var traverseDefault = function (dictTraversable) {
  return function (dictApplicative) {
    return function (f) {
      return function (ta) {
        return sequence(dictTraversable)(dictApplicative)(Data_Functor.map(dictTraversable.Functor0())(f)(ta));
      };
    };
  };
};

var mapAccumR = function (dictTraversable) {
  return function (f) {
    return function (s0) {
      return function (xs) {
        return Data_Traversable_Accum_Internal.stateR(traverse(dictTraversable)(Data_Traversable_Accum_Internal.applicativeStateR)(function (a) {
          return function (s) {
            return f(s)(a);
          };
        })(xs))(s0);
      };
    };
  };
};

var scanr = function (dictTraversable) {
  return function (f) {
    return function (b0) {
      return function (xs) {
        return mapAccumR(dictTraversable)(function (b) {
          return function (a) {
            var b$prime = f(a)(b);
            return {
              accum: b$prime,
              value: b$prime
            };
          };
        })(b0)(xs).value;
      };
    };
  };
};

var mapAccumL = function (dictTraversable) {
  return function (f) {
    return function (s0) {
      return function (xs) {
        return Data_Traversable_Accum_Internal.stateL(traverse(dictTraversable)(Data_Traversable_Accum_Internal.applicativeStateL)(function (a) {
          return function (s) {
            return f(s)(a);
          };
        })(xs))(s0);
      };
    };
  };
};

var scanl = function (dictTraversable) {
  return function (f) {
    return function (b0) {
      return function (xs) {
        return mapAccumL(dictTraversable)(function (b) {
          return function (a) {
            var b$prime = f(b)(a);
            return {
              accum: b$prime,
              value: b$prime
            };
          };
        })(b0)(xs).value;
      };
    };
  };
};

var $$for = function (dictApplicative) {
  return function (dictTraversable) {
    return function (x) {
      return function (f) {
        return traverse(dictTraversable)(dictApplicative)(f)(x);
      };
    };
  };
};

module.exports = {
  Traversable: Traversable,
  traverse: traverse,
  sequence: sequence,
  traverseDefault: traverseDefault,
  sequenceDefault: sequenceDefault,
  "for": $$for,
  scanl: scanl,
  scanr: scanr,
  mapAccumL: mapAccumL,
  mapAccumR: mapAccumR,
  traversableArray: traversableArray,
  traversableMaybe: traversableMaybe,
  traversableFirst: traversableFirst,
  traversableLast: traversableLast,
  traversableAdditive: traversableAdditive,
  traversableDual: traversableDual,
  traversableConj: traversableConj,
  traversableDisj: traversableDisj,
  traversableMultiplicative: traversableMultiplicative
};