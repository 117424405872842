import React from 'react';
import { RouteComponentProps } from 'react-router';
import {
  redemptionFlow as RFlow,
  smart,
} from 'purs/ZAP.Trovex.Client.Flows.RedemptionFlow';
import { EnterRedemptionCode } from 'js/client/screens/redemption/EnterRedemptionCode';
import { zenv } from 'js/constants';
import { EnterMobile } from 'js/client/screens/redemption/EnterMobile';
import { ConfirmRedemption } from 'js/client/screens/redemption/ConfirmRedemption';
import { ViewSuccessRedeem } from 'js/client/screens/redemption/ViewSuccessRedeem';
import { FullPageSpinner } from '../components/FullPageSpinner';
import { AlertDialog } from '../components/AlertDialog';

const RedemptionF = RFlow();
const vendorId = {
  staging: '7ea5a3b9-4e15-4f8c-854e-f66087f0cc8e',
  preprod: 'eee43418-aede-419e-a72f-2296919b5095',
  prod: 'eee43418-aede-419e-a72f-2296919b5095',
};
export const Smart: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <RedemptionF
      screens={{
        enterRedemptionCode: EnterRedemptionCode,
        enterMobile: EnterMobile,
        confirmRedemption: ConfirmRedemption,
        viewSuccessRedeem: ViewSuccessRedeem,
      }}
      vendorId={vendorId[zenv]}
      vendor={smart}
      baseUrl={match.url}
      fullPageSpinner={FullPageSpinner}
      alertDialog={AlertDialog}
    />
  );
};
