// Generated by purs version 0.13.8
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Monad = require("../Control.Monad/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Show = require("../Data.Show/index.js");

var Dual = function (x) {
  return x;
};

var showDual = function (dictShow) {
  return new Data_Show.Show(function (v) {
    return "(Dual " + (Data_Show.show(dictShow)(v) + ")");
  });
};

var semigroupDual = function (dictSemigroup) {
  return new Data_Semigroup.Semigroup(function (v) {
    return function (v1) {
      return Data_Semigroup.append(dictSemigroup)(v1)(v);
    };
  });
};

var ordDual = function (dictOrd) {
  return dictOrd;
};

var monoidDual = function (dictMonoid) {
  return new Data_Monoid.Monoid(function () {
    return semigroupDual(dictMonoid.Semigroup0());
  }, Data_Monoid.mempty(dictMonoid));
};

var functorDual = new Data_Functor.Functor(function (f) {
  return function (m) {
    return f(m);
  };
});

var eqDual = function (dictEq) {
  return dictEq;
};

var eq1Dual = new Data_Eq.Eq1(function (dictEq) {
  return Data_Eq.eq(eqDual(dictEq));
});
var ord1Dual = new Data_Ord.Ord1(function () {
  return eq1Dual;
}, function (dictOrd) {
  return Data_Ord.compare(ordDual(dictOrd));
});

var boundedDual = function (dictBounded) {
  return dictBounded;
};

var applyDual = new Control_Apply.Apply(function () {
  return functorDual;
}, function (v) {
  return function (v1) {
    return v(v1);
  };
});
var bindDual = new Control_Bind.Bind(function () {
  return applyDual;
}, function (v) {
  return function (f) {
    return f(v);
  };
});
var applicativeDual = new Control_Applicative.Applicative(function () {
  return applyDual;
}, Dual);
var monadDual = new Control_Monad.Monad(function () {
  return applicativeDual;
}, function () {
  return bindDual;
});
module.exports = {
  Dual: Dual,
  eqDual: eqDual,
  eq1Dual: eq1Dual,
  ordDual: ordDual,
  ord1Dual: ord1Dual,
  boundedDual: boundedDual,
  showDual: showDual,
  functorDual: functorDual,
  applyDual: applyDual,
  applicativeDual: applicativeDual,
  bindDual: bindDual,
  monadDual: monadDual,
  semigroupDual: semigroupDual,
  monoidDual: monoidDual
};