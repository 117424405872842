import React from 'react';
import styled from 'styled-components';
import {
  Container,
  SmallVendorLogo,
  displayStyle,
  regFont,
  PImg,
  showMobile,
} from './Common';
import { getVendorLogo } from 'js/utils';
import { zenv } from 'js/constants';

interface Props {
  vendorId: string;
  vendorName: string;
  productName: string;
  productImg: string;
  mobile: string;
  status: {
    title: string;
    message: string;
    type: string;
  };
}

const Header = styled.h3`
  font-family: ProximaNova-Semibold;
  font-size: 24px;
  margin: 0;
  margin-top: 45px;
  margin-bottom: 25px;
  color: ${({ color }) => color};
`;

const SubHeader = styled.p`
  ${regFont}
  margin: 0;
  margin-bottom: 36px;
  text-align: center;
  max-width: 300px;
`;

const Display = styled.div`
  ${displayStyle}
  position: relative;
  min-height: 300px;
`;

const DSubH = styled.p`
  ${regFont}
  margin: 0;
  margin-bottom: 4px;
`;

const DHeader = styled.p`
  font-family: ProximaNova-Semibold;
  font-size: 16px;
  margin: 0;
`;

const DBottom = styled.p`
  ${regFont}
  margin: 0;
  margin-top: 20px;
  margin-bottom: 8px;
`;

const DSubBtm = styled.p`
  font-family: ProximaNova-Semibold;
  font-size: 16px;
  margin: 0;
`;

const SuccessCheck = styled.img`
  width: 108px;
  height: 108px;
  position: absolute;
  right: -30px;
  top: -30px;
`;

export const ViewSuccessRedeem: React.FC<Props> = ({
  vendorId,
  vendorName,
  productName,
  productImg,
  mobile,
  status,
}) => {
  return (
    <Container>
      <SmallVendorLogo src={getVendorLogo(zenv, vendorId)} />
      {status.title && <Header>{status.title}</Header>}
      {status.message && <SubHeader>{status.message}</SubHeader>}
      <Display>
        {status.type === 'Success' ? (
          <SuccessCheck
            alt="check"
            src={require('assets/svgs/success-white-bg.svg')}
          />
        ) : status.type === 'Failed' ? (
          <SuccessCheck
            alt="failed"
            src={require('assets/svgs/general-error-white-bg.svg')}
          />
        ) : null}
        <DSubH>{vendorName.toUpperCase()}</DSubH>
        <DHeader>{productName}</DHeader>
        <PImg alt={productName} src={productImg} />
        <DBottom>to</DBottom>
        <DSubBtm>{showMobile(mobile)}</DSubBtm>
      </Display>
    </Container>
  );
};
