// Generated by purs version 0.13.8
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Category = require("../Control.Category/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Monoid_Conj = require("../Data.Monoid.Conj/index.js");

var Data_Monoid_Disj = require("../Data.Monoid.Disj/index.js");

var Data_Monoid_Dual = require("../Data.Monoid.Dual/index.js");

var Data_Monoid_Endo = require("../Data.Monoid.Endo/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Bifoldable = function (bifoldMap, bifoldl, bifoldr) {
  this.bifoldMap = bifoldMap;
  this.bifoldl = bifoldl;
  this.bifoldr = bifoldr;
};

var bifoldr = function (dict) {
  return dict.bifoldr;
};

var bitraverse_ = function (dictBifoldable) {
  return function (dictApplicative) {
    return function (f) {
      return function (g) {
        return bifoldr(dictBifoldable)(function () {
          var $97 = Control_Apply.applySecond(dictApplicative.Apply0());
          return function ($98) {
            return $97(f($98));
          };
        }())(function () {
          var $99 = Control_Apply.applySecond(dictApplicative.Apply0());
          return function ($100) {
            return $99(g($100));
          };
        }())(Control_Applicative.pure(dictApplicative)(Data_Unit.unit));
      };
    };
  };
};

var bifor_ = function (dictBifoldable) {
  return function (dictApplicative) {
    return function (t) {
      return function (f) {
        return function (g) {
          return bitraverse_(dictBifoldable)(dictApplicative)(f)(g)(t);
        };
      };
    };
  };
};

var bisequence_ = function (dictBifoldable) {
  return function (dictApplicative) {
    return bitraverse_(dictBifoldable)(dictApplicative)(Control_Category.identity(Control_Category.categoryFn))(Control_Category.identity(Control_Category.categoryFn));
  };
};

var bifoldl = function (dict) {
  return dict.bifoldl;
};

var bifoldableJoker = function (dictFoldable) {
  return new Bifoldable(function (dictMonoid) {
    return function (v) {
      return function (r) {
        return function (v1) {
          return Data_Foldable.foldMap(dictFoldable)(dictMonoid)(r)(v1);
        };
      };
    };
  }, function (v) {
    return function (r) {
      return function (u) {
        return function (v1) {
          return Data_Foldable.foldl(dictFoldable)(r)(u)(v1);
        };
      };
    };
  }, function (v) {
    return function (r) {
      return function (u) {
        return function (v1) {
          return Data_Foldable.foldr(dictFoldable)(r)(u)(v1);
        };
      };
    };
  });
};

var bifoldableClown = function (dictFoldable) {
  return new Bifoldable(function (dictMonoid) {
    return function (l) {
      return function (v) {
        return function (v1) {
          return Data_Foldable.foldMap(dictFoldable)(dictMonoid)(l)(v1);
        };
      };
    };
  }, function (l) {
    return function (v) {
      return function (u) {
        return function (v1) {
          return Data_Foldable.foldl(dictFoldable)(l)(u)(v1);
        };
      };
    };
  }, function (l) {
    return function (v) {
      return function (u) {
        return function (v1) {
          return Data_Foldable.foldr(dictFoldable)(l)(u)(v1);
        };
      };
    };
  });
};

var bifoldMapDefaultR = function (dictBifoldable) {
  return function (dictMonoid) {
    return function (f) {
      return function (g) {
        return bifoldr(dictBifoldable)(function () {
          var $101 = Data_Semigroup.append(dictMonoid.Semigroup0());
          return function ($102) {
            return $101(f($102));
          };
        }())(function () {
          var $103 = Data_Semigroup.append(dictMonoid.Semigroup0());
          return function ($104) {
            return $103(g($104));
          };
        }())(Data_Monoid.mempty(dictMonoid));
      };
    };
  };
};

var bifoldMapDefaultL = function (dictBifoldable) {
  return function (dictMonoid) {
    return function (f) {
      return function (g) {
        return bifoldl(dictBifoldable)(function (m) {
          return function (a) {
            return Data_Semigroup.append(dictMonoid.Semigroup0())(m)(f(a));
          };
        })(function (m) {
          return function (b) {
            return Data_Semigroup.append(dictMonoid.Semigroup0())(m)(g(b));
          };
        })(Data_Monoid.mempty(dictMonoid));
      };
    };
  };
};

var bifoldMap = function (dict) {
  return dict.bifoldMap;
};

var bifoldableFlip = function (dictBifoldable) {
  return new Bifoldable(function (dictMonoid) {
    return function (r) {
      return function (l) {
        return function (v) {
          return bifoldMap(dictBifoldable)(dictMonoid)(l)(r)(v);
        };
      };
    };
  }, function (r) {
    return function (l) {
      return function (u) {
        return function (v) {
          return bifoldl(dictBifoldable)(l)(r)(u)(v);
        };
      };
    };
  }, function (r) {
    return function (l) {
      return function (u) {
        return function (v) {
          return bifoldr(dictBifoldable)(l)(r)(u)(v);
        };
      };
    };
  });
};

var bifoldableWrap = function (dictBifoldable) {
  return new Bifoldable(function (dictMonoid) {
    return function (l) {
      return function (r) {
        return function (v) {
          return bifoldMap(dictBifoldable)(dictMonoid)(l)(r)(v);
        };
      };
    };
  }, function (l) {
    return function (r) {
      return function (u) {
        return function (v) {
          return bifoldl(dictBifoldable)(l)(r)(u)(v);
        };
      };
    };
  }, function (l) {
    return function (r) {
      return function (u) {
        return function (v) {
          return bifoldr(dictBifoldable)(l)(r)(u)(v);
        };
      };
    };
  });
};

var bifoldlDefault = function (dictBifoldable) {
  return function (f) {
    return function (g) {
      return function (z) {
        return function (p) {
          return Data_Newtype.unwrap(Data_Newtype.newtypeEndo)(Data_Newtype.unwrap(Data_Newtype.newtypeDual)(bifoldMap(dictBifoldable)(Data_Monoid_Dual.monoidDual(Data_Monoid_Endo.monoidEndo(Control_Category.categoryFn)))(function () {
            var $105 = Data_Function.flip(f);
            return function ($106) {
              return Data_Monoid_Dual.Dual(Data_Monoid_Endo.Endo($105($106)));
            };
          }())(function () {
            var $107 = Data_Function.flip(g);
            return function ($108) {
              return Data_Monoid_Dual.Dual(Data_Monoid_Endo.Endo($107($108)));
            };
          }())(p)))(z);
        };
      };
    };
  };
};

var bifoldrDefault = function (dictBifoldable) {
  return function (f) {
    return function (g) {
      return function (z) {
        return function (p) {
          return Data_Newtype.unwrap(Data_Newtype.newtypeEndo)(bifoldMap(dictBifoldable)(Data_Monoid_Endo.monoidEndo(Control_Category.categoryFn))(function ($109) {
            return Data_Monoid_Endo.Endo(f($109));
          })(function ($110) {
            return Data_Monoid_Endo.Endo(g($110));
          })(p))(z);
        };
      };
    };
  };
};

var bifoldableProduct = function (dictBifoldable) {
  return function (dictBifoldable1) {
    return new Bifoldable(function (dictMonoid) {
      return function (l) {
        return function (r) {
          return function (v) {
            return Data_Semigroup.append(dictMonoid.Semigroup0())(bifoldMap(dictBifoldable)(dictMonoid)(l)(r)(v.value0))(bifoldMap(dictBifoldable1)(dictMonoid)(l)(r)(v.value1));
          };
        };
      };
    }, function (l) {
      return function (r) {
        return function (u) {
          return function (m) {
            return bifoldlDefault(bifoldableProduct(dictBifoldable)(dictBifoldable1))(l)(r)(u)(m);
          };
        };
      };
    }, function (l) {
      return function (r) {
        return function (u) {
          return function (m) {
            return bifoldrDefault(bifoldableProduct(dictBifoldable)(dictBifoldable1))(l)(r)(u)(m);
          };
        };
      };
    });
  };
};

var bifold = function (dictBifoldable) {
  return function (dictMonoid) {
    return bifoldMap(dictBifoldable)(dictMonoid)(Control_Category.identity(Control_Category.categoryFn))(Control_Category.identity(Control_Category.categoryFn));
  };
};

var biany = function (dictBifoldable) {
  return function (dictBooleanAlgebra) {
    return function (p) {
      return function (q) {
        var $111 = Data_Newtype.unwrap(Data_Newtype.newtypeDisj);
        var $112 = bifoldMap(dictBifoldable)(Data_Monoid_Disj.monoidDisj(dictBooleanAlgebra.HeytingAlgebra0()))(function ($114) {
          return Data_Monoid_Disj.Disj(p($114));
        })(function ($115) {
          return Data_Monoid_Disj.Disj(q($115));
        });
        return function ($113) {
          return $111($112($113));
        };
      };
    };
  };
};

var biall = function (dictBifoldable) {
  return function (dictBooleanAlgebra) {
    return function (p) {
      return function (q) {
        var $116 = Data_Newtype.unwrap(Data_Newtype.newtypeConj);
        var $117 = bifoldMap(dictBifoldable)(Data_Monoid_Conj.monoidConj(dictBooleanAlgebra.HeytingAlgebra0()))(function ($119) {
          return Data_Monoid_Conj.Conj(p($119));
        })(function ($120) {
          return Data_Monoid_Conj.Conj(q($120));
        });
        return function ($118) {
          return $116($117($118));
        };
      };
    };
  };
};

module.exports = {
  bifoldMap: bifoldMap,
  bifoldl: bifoldl,
  bifoldr: bifoldr,
  Bifoldable: Bifoldable,
  bifoldrDefault: bifoldrDefault,
  bifoldlDefault: bifoldlDefault,
  bifoldMapDefaultR: bifoldMapDefaultR,
  bifoldMapDefaultL: bifoldMapDefaultL,
  bifold: bifold,
  bitraverse_: bitraverse_,
  bifor_: bifor_,
  bisequence_: bisequence_,
  biany: biany,
  biall: biall,
  bifoldableClown: bifoldableClown,
  bifoldableJoker: bifoldableJoker,
  bifoldableFlip: bifoldableFlip,
  bifoldableProduct: bifoldableProduct,
  bifoldableWrap: bifoldableWrap
};