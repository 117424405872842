import React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { HoldingTheme } from '../contexts/HoldingTheme';
import { Eff } from 'js/types';

const ModalS = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  padding: 0px 12px;
  background-color: white;
  border-color: rgba(0, 0, 0, 0.1);
  width: 100%;
`;

const TopRow = styled.div`
  position: relative;
  margin-top: 16px;
  height: 16px;
  width: 100%;
`;

const CloseBtn = styled.button`
  position: absolute;
  right: 0;
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  box-sizing: border-box;
`;
const Btn = styled.button`
  border: none;
  width: 100%;
  padding: 16px 20px;
  color: ${(props) => props.color};
  letter-spacing: 1.8px;
  font-size: 18px;
  font-family: ProximaNova-Semibold;
  background: white;
`;

const Title = styled.h3`
  font-size: 20px;
  text-align: center;
  font-family: ProximaNova-Semibold;
  letter-spacing: 2px;
  line-height: 24px;
`;

const Msg = styled.p`
  margin-top: 4px;
  font-size: 16px;
  text-align: center;
  letter-spacing: 1.5px;
  line-height: 18px;
  font-family: ProximaNova-Regular;
`;

const Divider = styled.div`
  background: rgba(200, 200, 200, 0.7);
  height: 1px;
  align-self: stretch;
`;

interface Props {
  open: boolean;
  title: string;
  message: string;
  actionLabel: string;
  onAction: Eff<void>;
  onClose: Eff<void>;
}

export const AlertDialog: React.FC<Props> = ({
  title,
  message,
  open,
  onClose,
  onAction,
  actionLabel
}) => {
  const { color } = React.useContext(HoldingTheme);
  return (
    <ModalS overlayClassName="overlay-modal" isOpen={open}>
      <TopRow>
        <CloseBtn onClick={onClose}>
          <img alt="down" src={require('assets/svgs/exit_outlined.svg')} />
        </CloseBtn>
      </TopRow>
      <ContentRow>
        <Title>{title}</Title>
        <Msg>{message}</Msg>
      </ContentRow>
      <Divider />
      <Btn color={color} onClick={onAction}>
        {actionLabel}
      </Btn>
    </ModalS>
  );
};
