// Generated by purs version 0.13.8
"use strict";

var $foreign = require("./foreign.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Monad_Error_Class = require("../Control.Monad.Error.Class/index.js");

var Control_Monad_Except = require("../Control.Monad.Except/index.js");

var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");

var Data_Boolean = require("../Data.Boolean/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Identity = require("../Data.Identity/index.js");

var Data_Int = require("../Data.Int/index.js");

var Data_List_NonEmpty = require("../Data.List.NonEmpty/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Ordering = require("../Data.Ordering/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");

var ForeignError = function () {
  function ForeignError(value0) {
    this.value0 = value0;
  }

  ;

  ForeignError.create = function (value0) {
    return new ForeignError(value0);
  };

  return ForeignError;
}();

var TypeMismatch = function () {
  function TypeMismatch(value0, value1) {
    this.value0 = value0;
    this.value1 = value1;
  }

  ;

  TypeMismatch.create = function (value0) {
    return function (value1) {
      return new TypeMismatch(value0, value1);
    };
  };

  return TypeMismatch;
}();

var ErrorAtIndex = function () {
  function ErrorAtIndex(value0, value1) {
    this.value0 = value0;
    this.value1 = value1;
  }

  ;

  ErrorAtIndex.create = function (value0) {
    return function (value1) {
      return new ErrorAtIndex(value0, value1);
    };
  };

  return ErrorAtIndex;
}();

var ErrorAtProperty = function () {
  function ErrorAtProperty(value0, value1) {
    this.value0 = value0;
    this.value1 = value1;
  }

  ;

  ErrorAtProperty.create = function (value0) {
    return function (value1) {
      return new ErrorAtProperty(value0, value1);
    };
  };

  return ErrorAtProperty;
}();

var showForeignError = new Data_Show.Show(function (v) {
  if (v instanceof ForeignError) {
    return "(ForeignError " + (Data_Show.show(Data_Show.showString)(v.value0) + ")");
  }

  ;

  if (v instanceof ErrorAtIndex) {
    return "(ErrorAtIndex " + (Data_Show.show(Data_Show.showInt)(v.value0) + (" " + (Data_Show.show(showForeignError)(v.value1) + ")")));
  }

  ;

  if (v instanceof ErrorAtProperty) {
    return "(ErrorAtProperty " + (Data_Show.show(Data_Show.showString)(v.value0) + (" " + (Data_Show.show(showForeignError)(v.value1) + ")")));
  }

  ;

  if (v instanceof TypeMismatch) {
    return "(TypeMismatch " + (Data_Show.show(Data_Show.showString)(v.value0) + (" " + (Data_Show.show(Data_Show.showString)(v.value1) + ")")));
  }

  ;
  throw new Error("Failed pattern match at Foreign (line 63, column 1 - line 67, column 89): " + [v.constructor.name]);
});

var renderForeignError = function (v) {
  if (v instanceof ForeignError) {
    return v.value0;
  }

  ;

  if (v instanceof ErrorAtIndex) {
    return "Error at array index " + (Data_Show.show(Data_Show.showInt)(v.value0) + (": " + renderForeignError(v.value1)));
  }

  ;

  if (v instanceof ErrorAtProperty) {
    return "Error at property " + (Data_Show.show(Data_Show.showString)(v.value0) + (": " + renderForeignError(v.value1)));
  }

  ;

  if (v instanceof TypeMismatch) {
    return "Type mismatch: expected " + (v.value0 + (", found " + v.value1));
  }

  ;
  throw new Error("Failed pattern match at Foreign (line 72, column 1 - line 72, column 45): " + [v.constructor.name]);
};

var readUndefined = function (value) {
  if ($foreign.isUndefined(value)) {
    return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Maybe.Nothing.value);
  }

  ;

  if (Data_Boolean.otherwise) {
    return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(new Data_Maybe.Just(value));
  }

  ;
  throw new Error("Failed pattern match at Foreign (line 157, column 1 - line 157, column 46): " + [value.constructor.name]);
};

var readNullOrUndefined = function (value) {
  if ($foreign.isNull(value) || $foreign.isUndefined(value)) {
    return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Maybe.Nothing.value);
  }

  ;

  if (Data_Boolean.otherwise) {
    return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(new Data_Maybe.Just(value));
  }

  ;
  throw new Error("Failed pattern match at Foreign (line 162, column 1 - line 162, column 52): " + [value.constructor.name]);
};

var readNull = function (value) {
  if ($foreign.isNull(value)) {
    return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Maybe.Nothing.value);
  }

  ;

  if (Data_Boolean.otherwise) {
    return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(new Data_Maybe.Just(value));
  }

  ;
  throw new Error("Failed pattern match at Foreign (line 152, column 1 - line 152, column 41): " + [value.constructor.name]);
};

var fail = function () {
  var $107 = Control_Monad_Error_Class.throwError(Control_Monad_Except_Trans.monadThrowExceptT(Data_Identity.monadIdentity));
  return function ($108) {
    return $107(Data_List_NonEmpty.singleton($108));
  };
}();

var readArray = function (value) {
  if ($foreign.isArray(value)) {
    return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))($foreign.unsafeFromForeign(value));
  }

  ;

  if (Data_Boolean.otherwise) {
    return fail(new TypeMismatch("array", $foreign.tagOf(value)));
  }

  ;
  throw new Error("Failed pattern match at Foreign (line 147, column 1 - line 147, column 42): " + [value.constructor.name]);
};

var unsafeReadTagged = function (tag) {
  return function (value) {
    if ($foreign.tagOf(value) === tag) {
      return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))($foreign.unsafeFromForeign(value));
    }

    ;

    if (Data_Boolean.otherwise) {
      return fail(new TypeMismatch(tag, $foreign.tagOf(value)));
    }

    ;
    throw new Error("Failed pattern match at Foreign (line 106, column 1 - line 106, column 55): " + [tag.constructor.name, value.constructor.name]);
  };
};

var readBoolean = unsafeReadTagged("Boolean");
var readNumber = unsafeReadTagged("Number");

var readInt = function (value) {
  var error = Data_Either.Left.create(Data_List_NonEmpty.singleton(new TypeMismatch("Int", $foreign.tagOf(value))));

  var fromNumber = function () {
    var $109 = Data_Maybe.maybe(error)(Control_Applicative.pure(Data_Either.applicativeEither));
    return function ($110) {
      return $109(Data_Int.fromNumber($110));
    };
  }();

  return Control_Monad_Except.mapExcept(Data_Either.either(Data_Function["const"](error))(fromNumber))(readNumber(value));
};

var readString = unsafeReadTagged("String");

var readChar = function (value) {
  var error = Data_Either.Left.create(Data_List_NonEmpty.singleton(new TypeMismatch("Char", $foreign.tagOf(value))));

  var fromString = function () {
    var $111 = Data_Maybe.maybe(error)(Control_Applicative.pure(Data_Either.applicativeEither));
    return function ($112) {
      return $111(Data_String_CodeUnits.toChar($112));
    };
  }();

  return Control_Monad_Except.mapExcept(Data_Either.either(Data_Function["const"](error))(fromString))(readString(value));
};

var eqForeignError = new Data_Eq.Eq(function (x) {
  return function (y) {
    if (x instanceof ForeignError && y instanceof ForeignError) {
      return x.value0 === y.value0;
    }

    ;

    if (x instanceof TypeMismatch && y instanceof TypeMismatch) {
      return x.value0 === y.value0 && x.value1 === y.value1;
    }

    ;

    if (x instanceof ErrorAtIndex && y instanceof ErrorAtIndex) {
      return x.value0 === y.value0 && Data_Eq.eq(eqForeignError)(x.value1)(y.value1);
    }

    ;

    if (x instanceof ErrorAtProperty && y instanceof ErrorAtProperty) {
      return x.value0 === y.value0 && Data_Eq.eq(eqForeignError)(x.value1)(y.value1);
    }

    ;
    return false;
  };
});
var ordForeignError = new Data_Ord.Ord(function () {
  return eqForeignError;
}, function (x) {
  return function (y) {
    if (x instanceof ForeignError && y instanceof ForeignError) {
      return Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);
    }

    ;

    if (x instanceof ForeignError) {
      return Data_Ordering.LT.value;
    }

    ;

    if (y instanceof ForeignError) {
      return Data_Ordering.GT.value;
    }

    ;

    if (x instanceof TypeMismatch && y instanceof TypeMismatch) {
      var v = Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);

      if (v instanceof Data_Ordering.LT) {
        return Data_Ordering.LT.value;
      }

      ;

      if (v instanceof Data_Ordering.GT) {
        return Data_Ordering.GT.value;
      }

      ;
      return Data_Ord.compare(Data_Ord.ordString)(x.value1)(y.value1);
    }

    ;

    if (x instanceof TypeMismatch) {
      return Data_Ordering.LT.value;
    }

    ;

    if (y instanceof TypeMismatch) {
      return Data_Ordering.GT.value;
    }

    ;

    if (x instanceof ErrorAtIndex && y instanceof ErrorAtIndex) {
      var v = Data_Ord.compare(Data_Ord.ordInt)(x.value0)(y.value0);

      if (v instanceof Data_Ordering.LT) {
        return Data_Ordering.LT.value;
      }

      ;

      if (v instanceof Data_Ordering.GT) {
        return Data_Ordering.GT.value;
      }

      ;
      return Data_Ord.compare(ordForeignError)(x.value1)(y.value1);
    }

    ;

    if (x instanceof ErrorAtIndex) {
      return Data_Ordering.LT.value;
    }

    ;

    if (y instanceof ErrorAtIndex) {
      return Data_Ordering.GT.value;
    }

    ;

    if (x instanceof ErrorAtProperty && y instanceof ErrorAtProperty) {
      var v = Data_Ord.compare(Data_Ord.ordString)(x.value0)(y.value0);

      if (v instanceof Data_Ordering.LT) {
        return Data_Ordering.LT.value;
      }

      ;

      if (v instanceof Data_Ordering.GT) {
        return Data_Ordering.GT.value;
      }

      ;
      return Data_Ord.compare(ordForeignError)(x.value1)(y.value1);
    }

    ;
    throw new Error("Failed pattern match at Foreign (line 61, column 1 - line 61, column 52): " + [x.constructor.name, y.constructor.name]);
  };
});
module.exports = {
  ForeignError: ForeignError,
  TypeMismatch: TypeMismatch,
  ErrorAtIndex: ErrorAtIndex,
  ErrorAtProperty: ErrorAtProperty,
  renderForeignError: renderForeignError,
  unsafeReadTagged: unsafeReadTagged,
  readString: readString,
  readChar: readChar,
  readBoolean: readBoolean,
  readNumber: readNumber,
  readInt: readInt,
  readArray: readArray,
  readNull: readNull,
  readUndefined: readUndefined,
  readNullOrUndefined: readNullOrUndefined,
  fail: fail,
  eqForeignError: eqForeignError,
  ordForeignError: ordForeignError,
  showForeignError: showForeignError,
  unsafeToForeign: $foreign.unsafeToForeign,
  unsafeFromForeign: $foreign.unsafeFromForeign,
  typeOf: $foreign.typeOf,
  tagOf: $foreign.tagOf,
  isNull: $foreign.isNull,
  isUndefined: $foreign.isUndefined,
  isArray: $foreign.isArray
};