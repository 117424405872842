// Generated by purs version 0.13.8
"use strict";

var Data_Unit = require("../Data.Unit/index.js");

var Lazy = function (defer) {
  this.defer = defer;
};

var lazyUnit = new Lazy(function (v) {
  return Data_Unit.unit;
});
var lazyFn = new Lazy(function (f) {
  return function (x) {
    return f(Data_Unit.unit)(x);
  };
});

var defer = function (dict) {
  return dict.defer;
};

var fix = function (dictLazy) {
  return function (f) {
    var go = defer(dictLazy)(function (v) {
      return f(go);
    });
    return go;
  };
};

module.exports = {
  defer: defer,
  Lazy: Lazy,
  fix: fix,
  lazyFn: lazyFn,
  lazyUnit: lazyUnit
};