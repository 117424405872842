import React from 'react';
import styled from 'styled-components';
import { Button } from '../../components/Button';
import { getVendorLogo } from 'js/utils';
import { zenv } from 'js/constants';
import { Eff } from 'js/types';
import {
  Container,
  regFont,
  displayStyle,
  SmallVendorLogo,
  PImg,
  showMobile,
} from './Common';
import { HoldingTheme } from 'js/client/contexts/HoldingTheme';

interface Props {
  mobile: string;
  vendorId: string;
  vendorName: string;
  productName: string;
  productImg: string;
  onSubmit: Eff<void>;
  onCancel: Eff<void>;
}

const Display = styled.div`
  ${displayStyle}
  min-height: 220px;
`;

const DisplayM = styled.div`
  ${displayStyle}
  min-height: 80px;
`;

const SubH = styled.p`
  ${regFont}
  margin: 0;
  margin-bottom: 4px;
`;
const SubHM = styled.p`
  ${regFont}
  margin: 0;
  margin-bottom: 12px;
`;
const Header = styled.p`
  font-family: ProximaNova-Semibold;
  font-size: 16px;
  margin: 0;
`;

const HeaderC = styled.h3`
  font-family: ProximaNova-Semibold;
  font-size: 30px;
  margin: 0;
  margin-top: 45px;
  margin-bottom: 25px;
`;

export const ConfirmRedemption: React.FC<Props> = ({
  vendorId,
  vendorName,
  productName,
  productImg,
  mobile,
  onSubmit,
  onCancel,
}) => {
  const { color } = React.useContext(HoldingTheme);
  return (
    <Container>
      <SmallVendorLogo alt="vendor" src={getVendorLogo(zenv, vendorId)} />
      <HeaderC>Continue to redeem?</HeaderC>
      <Display>
        <SubH>{vendorName.toUpperCase()}</SubH>
        <Header>{productName}</Header>
        <PImg alt={productName} src={productImg} />
      </Display>
      <br />
      <img alt="down" src={require('assets/svgs/chevron-down.svg')} />
      <br />
      <DisplayM>
        <SubHM>Mobile Number</SubHM>
        <Header>{showMobile(mobile)}</Header>
      </DisplayM>
      <br />
      <br />
      <br />
      <Button
        title="REDEEM"
        type="primary"
        color={color}
        disabled={false}
        onPress={onSubmit}
      />
      <Button
        title="Cancel"
        type="secondary"
        color={color}
        disabled={false}
        onPress={onCancel}
      />
    </Container>
  );
};
