// Generated by purs version 0.13.8
"use strict";

var Control_Alt = require("../Control.Alt/index.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Category = require("../Control.Category/index.js");

var Control_Lazy = require("../Control.Lazy/index.js");

var Control_Monad_Rec_Class = require("../Control.Monad.Rec.Class/index.js");

var Data_Bifunctor = require("../Data.Bifunctor/index.js");

var Data_Boolean = require("../Data.Boolean/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Foldable = require("../Data.Foldable/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_FunctorWithIndex = require("../Data.FunctorWithIndex/index.js");

var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");

var Data_List_Types = require("../Data.List.Types/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_NonEmpty = require("../Data.NonEmpty/index.js");

var Data_Ord = require("../Data.Ord/index.js");

var Data_Ordering = require("../Data.Ordering/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_Traversable = require("../Data.Traversable/index.js");

var Data_Tuple = require("../Data.Tuple/index.js");

var Data_Unfoldable = require("../Data.Unfoldable/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Pattern = function (x) {
  return x;
};

var updateAt = function (v) {
  return function (v1) {
    return function (v2) {
      if (v === 0 && v2 instanceof Data_List_Types.Cons) {
        return new Data_Maybe.Just(new Data_List_Types.Cons(v1, v2.value1));
      }

      ;

      if (v2 instanceof Data_List_Types.Cons) {
        return Data_Functor.map(Data_Maybe.functorMaybe)(function (v3) {
          return new Data_List_Types.Cons(v2.value0, v3);
        })(updateAt(v - 1 | 0)(v1)(v2.value1));
      }

      ;
      return Data_Maybe.Nothing.value;
    };
  };
};

var unzip = Data_Foldable.foldr(Data_List_Types.foldableList)(function (v) {
  return function (v1) {
    return new Data_Tuple.Tuple(new Data_List_Types.Cons(v.value0, v1.value0), new Data_List_Types.Cons(v.value1, v1.value1));
  };
})(new Data_Tuple.Tuple(Data_List_Types.Nil.value, Data_List_Types.Nil.value));

var uncons = function (v) {
  if (v instanceof Data_List_Types.Nil) {
    return Data_Maybe.Nothing.value;
  }

  ;

  if (v instanceof Data_List_Types.Cons) {
    return new Data_Maybe.Just({
      head: v.value0,
      tail: v.value1
    });
  }

  ;
  throw new Error("Failed pattern match at Data.List (line 259, column 1 - line 259, column 66): " + [v.constructor.name]);
};

var toUnfoldable = function (dictUnfoldable) {
  return Data_Unfoldable.unfoldr(dictUnfoldable)(function (xs) {
    return Data_Functor.map(Data_Maybe.functorMaybe)(function (rec) {
      return new Data_Tuple.Tuple(rec.head, rec.tail);
    })(uncons(xs));
  });
};

var tail = function (v) {
  if (v instanceof Data_List_Types.Nil) {
    return Data_Maybe.Nothing.value;
  }

  ;

  if (v instanceof Data_List_Types.Cons) {
    return new Data_Maybe.Just(v.value1);
  }

  ;
  throw new Error("Failed pattern match at Data.List (line 245, column 1 - line 245, column 43): " + [v.constructor.name]);
};

var stripPrefix = function (dictEq) {
  return function (v) {
    return function (s) {
      var go = function (prefix) {
        return function (input) {
          if (prefix instanceof Data_List_Types.Cons && input instanceof Data_List_Types.Cons && Data_Eq.eq(dictEq)(prefix.value0)(input.value0)) {
            return Data_Maybe.Just.create(new Control_Monad_Rec_Class.Loop({
              a: prefix.value1,
              b: input.value1
            }));
          }

          ;

          if (prefix instanceof Data_List_Types.Nil) {
            return Data_Maybe.Just.create(new Control_Monad_Rec_Class.Done(input));
          }

          ;
          return Data_Maybe.Nothing.value;
        };
      };

      return Control_Monad_Rec_Class.tailRecM2(Control_Monad_Rec_Class.monadRecMaybe)(go)(v)(s);
    };
  };
};

var span = function (v) {
  return function (v1) {
    if (v1 instanceof Data_List_Types.Cons && v(v1.value0)) {
      var v2 = span(v)(v1.value1);
      return {
        init: new Data_List_Types.Cons(v1.value0, v2.init),
        rest: v2.rest
      };
    }

    ;
    return {
      init: Data_List_Types.Nil.value,
      rest: v1
    };
  };
};

var snoc = function (xs) {
  return function (x) {
    return Data_Foldable.foldr(Data_List_Types.foldableList)(Data_List_Types.Cons.create)(new Data_List_Types.Cons(x, Data_List_Types.Nil.value))(xs);
  };
};

var singleton = function (a) {
  return new Data_List_Types.Cons(a, Data_List_Types.Nil.value);
};

var sortBy = function (cmp) {
  var merge = function (v) {
    return function (v1) {
      if (v instanceof Data_List_Types.Cons && v1 instanceof Data_List_Types.Cons) {
        if (Data_Eq.eq(Data_Ordering.eqOrdering)(cmp(v.value0)(v1.value0))(Data_Ordering.GT.value)) {
          return new Data_List_Types.Cons(v1.value0, merge(v)(v1.value1));
        }

        ;

        if (Data_Boolean.otherwise) {
          return new Data_List_Types.Cons(v.value0, merge(v.value1)(v1));
        }

        ;
      }

      ;

      if (v instanceof Data_List_Types.Nil) {
        return v1;
      }

      ;

      if (v1 instanceof Data_List_Types.Nil) {
        return v;
      }

      ;
      throw new Error("Failed pattern match at Data.List (line 473, column 3 - line 473, column 38): " + [v.constructor.name, v1.constructor.name]);
    };
  };

  var mergePairs = function (v) {
    if (v instanceof Data_List_Types.Cons && v.value1 instanceof Data_List_Types.Cons) {
      return new Data_List_Types.Cons(merge(v.value0)(v.value1.value0), mergePairs(v.value1.value1));
    }

    ;
    return v;
  };

  var mergeAll = function ($copy_v) {
    var $tco_done = false;
    var $tco_result;

    function $tco_loop(v) {
      if (v instanceof Data_List_Types.Cons && v.value1 instanceof Data_List_Types.Nil) {
        $tco_done = true;
        return v.value0;
      }

      ;
      $copy_v = mergePairs(v);
      return;
    }

    ;

    while (!$tco_done) {
      $tco_result = $tco_loop($copy_v);
    }

    ;
    return $tco_result;
  };

  var sequences = function (v) {
    if (v instanceof Data_List_Types.Cons && v.value1 instanceof Data_List_Types.Cons) {
      if (Data_Eq.eq(Data_Ordering.eqOrdering)(cmp(v.value0)(v.value1.value0))(Data_Ordering.GT.value)) {
        return descending(v.value1.value0)(singleton(v.value0))(v.value1.value1);
      }

      ;

      if (Data_Boolean.otherwise) {
        return ascending(v.value1.value0)(function (v1) {
          return new Data_List_Types.Cons(v.value0, v1);
        })(v.value1.value1);
      }

      ;
    }

    ;
    return singleton(v);
  };

  var descending = function ($copy_a) {
    return function ($copy_as) {
      return function ($copy_v) {
        var $tco_var_a = $copy_a;
        var $tco_var_as = $copy_as;
        var $tco_done = false;
        var $tco_result;

        function $tco_loop(a, as, v) {
          if (v instanceof Data_List_Types.Cons && Data_Eq.eq(Data_Ordering.eqOrdering)(cmp(a)(v.value0))(Data_Ordering.GT.value)) {
            $tco_var_a = v.value0;
            $tco_var_as = new Data_List_Types.Cons(a, as);
            $copy_v = v.value1;
            return;
          }

          ;
          $tco_done = true;
          return new Data_List_Types.Cons(new Data_List_Types.Cons(a, as), sequences(v));
        }

        ;

        while (!$tco_done) {
          $tco_result = $tco_loop($tco_var_a, $tco_var_as, $copy_v);
        }

        ;
        return $tco_result;
      };
    };
  };

  var ascending = function ($copy_a) {
    return function ($copy_as) {
      return function ($copy_v) {
        var $tco_var_a = $copy_a;
        var $tco_var_as = $copy_as;
        var $tco_done = false;
        var $tco_result;

        function $tco_loop(a, as, v) {
          if (v instanceof Data_List_Types.Cons && Data_Eq.notEq(Data_Ordering.eqOrdering)(cmp(a)(v.value0))(Data_Ordering.GT.value)) {
            $tco_var_a = v.value0;

            $tco_var_as = function (ys) {
              return as(new Data_List_Types.Cons(a, ys));
            };

            $copy_v = v.value1;
            return;
          }

          ;
          $tco_done = true;
          return new Data_List_Types.Cons(as(singleton(a)), sequences(v));
        }

        ;

        while (!$tco_done) {
          $tco_result = $tco_loop($tco_var_a, $tco_var_as, $copy_v);
        }

        ;
        return $tco_result;
      };
    };
  };

  return function ($331) {
    return mergeAll(sequences($331));
  };
};

var sort = function (dictOrd) {
  return function (xs) {
    return sortBy(Data_Ord.compare(dictOrd))(xs);
  };
};

var tails = function (v) {
  if (v instanceof Data_List_Types.Nil) {
    return singleton(Data_List_Types.Nil.value);
  }

  ;

  if (v instanceof Data_List_Types.Cons) {
    return new Data_List_Types.Cons(v, tails(v.value1));
  }

  ;
  throw new Error("Failed pattern match at Data.List (line 626, column 1 - line 626, column 43): " + [v.constructor.name]);
};

var showPattern = function (dictShow) {
  return new Data_Show.Show(function (v) {
    return "(Pattern " + (Data_Show.show(Data_List_Types.showList(dictShow))(v) + ")");
  });
};

var reverse = function () {
  var go = function ($copy_acc) {
    return function ($copy_v) {
      var $tco_var_acc = $copy_acc;
      var $tco_done = false;
      var $tco_result;

      function $tco_loop(acc, v) {
        if (v instanceof Data_List_Types.Nil) {
          $tco_done = true;
          return acc;
        }

        ;

        if (v instanceof Data_List_Types.Cons) {
          $tco_var_acc = new Data_List_Types.Cons(v.value0, acc);
          $copy_v = v.value1;
          return;
        }

        ;
        throw new Error("Failed pattern match at Data.List (line 368, column 3 - line 368, column 19): " + [acc.constructor.name, v.constructor.name]);
      }

      ;

      while (!$tco_done) {
        $tco_result = $tco_loop($tco_var_acc, $copy_v);
      }

      ;
      return $tco_result;
    };
  };

  return go(Data_List_Types.Nil.value);
}();

var take = function () {
  var go = function ($copy_acc) {
    return function ($copy_v) {
      return function ($copy_v1) {
        var $tco_var_acc = $copy_acc;
        var $tco_var_v = $copy_v;
        var $tco_done = false;
        var $tco_result;

        function $tco_loop(acc, v, v1) {
          if (v < 1) {
            $tco_done = true;
            return reverse(acc);
          }

          ;

          if (v1 instanceof Data_List_Types.Nil) {
            $tco_done = true;
            return reverse(acc);
          }

          ;

          if (v1 instanceof Data_List_Types.Cons) {
            $tco_var_acc = new Data_List_Types.Cons(v1.value0, acc);
            $tco_var_v = v - 1 | 0;
            $copy_v1 = v1.value1;
            return;
          }

          ;
          throw new Error("Failed pattern match at Data.List (line 520, column 3 - line 520, column 35): " + [acc.constructor.name, v.constructor.name, v1.constructor.name]);
        }

        ;

        while (!$tco_done) {
          $tco_result = $tco_loop($tco_var_acc, $tco_var_v, $copy_v1);
        }

        ;
        return $tco_result;
      };
    };
  };

  return go(Data_List_Types.Nil.value);
}();

var takeWhile = function (p) {
  var go = function ($copy_acc) {
    return function ($copy_v) {
      var $tco_var_acc = $copy_acc;
      var $tco_done = false;
      var $tco_result;

      function $tco_loop(acc, v) {
        if (v instanceof Data_List_Types.Cons && p(v.value0)) {
          $tco_var_acc = new Data_List_Types.Cons(v.value0, acc);
          $copy_v = v.value1;
          return;
        }

        ;
        $tco_done = true;
        return reverse(acc);
      }

      ;

      while (!$tco_done) {
        $tco_result = $tco_loop($tco_var_acc, $copy_v);
      }

      ;
      return $tco_result;
    };
  };

  return go(Data_List_Types.Nil.value);
};

var unsnoc = function (lst) {
  var go = function ($copy_v) {
    return function ($copy_acc) {
      var $tco_var_v = $copy_v;
      var $tco_done = false;
      var $tco_result;

      function $tco_loop(v, acc) {
        if (v instanceof Data_List_Types.Nil) {
          $tco_done = true;
          return Data_Maybe.Nothing.value;
        }

        ;

        if (v instanceof Data_List_Types.Cons && v.value1 instanceof Data_List_Types.Nil) {
          $tco_done = true;
          return new Data_Maybe.Just({
            revInit: acc,
            last: v.value0
          });
        }

        ;

        if (v instanceof Data_List_Types.Cons) {
          $tco_var_v = v.value1;
          $copy_acc = new Data_List_Types.Cons(v.value0, acc);
          return;
        }

        ;
        throw new Error("Failed pattern match at Data.List (line 270, column 3 - line 270, column 23): " + [v.constructor.name, acc.constructor.name]);
      }

      ;

      while (!$tco_done) {
        $tco_result = $tco_loop($tco_var_v, $copy_acc);
      }

      ;
      return $tco_result;
    };
  };

  return Data_Functor.map(Data_Maybe.functorMaybe)(function (h) {
    return {
      init: reverse(h.revInit),
      last: h.last
    };
  })(go(lst)(Data_List_Types.Nil.value));
};

var zipWith = function (f) {
  return function (xs) {
    return function (ys) {
      var go = function ($copy_v) {
        return function ($copy_v1) {
          return function ($copy_acc) {
            var $tco_var_v = $copy_v;
            var $tco_var_v1 = $copy_v1;
            var $tco_done = false;
            var $tco_result;

            function $tco_loop(v, v1, acc) {
              if (v instanceof Data_List_Types.Nil) {
                $tco_done = true;
                return acc;
              }

              ;

              if (v1 instanceof Data_List_Types.Nil) {
                $tco_done = true;
                return acc;
              }

              ;

              if (v instanceof Data_List_Types.Cons && v1 instanceof Data_List_Types.Cons) {
                $tco_var_v = v.value1;
                $tco_var_v1 = v1.value1;
                $copy_acc = new Data_List_Types.Cons(f(v.value0)(v1.value0), acc);
                return;
              }

              ;
              throw new Error("Failed pattern match at Data.List (line 718, column 3 - line 718, column 21): " + [v.constructor.name, v1.constructor.name, acc.constructor.name]);
            }

            ;

            while (!$tco_done) {
              $tco_result = $tco_loop($tco_var_v, $tco_var_v1, $copy_acc);
            }

            ;
            return $tco_result;
          };
        };
      };

      return reverse(go(xs)(ys)(Data_List_Types.Nil.value));
    };
  };
};

var zip = zipWith(Data_Tuple.Tuple.create);

var zipWithA = function (dictApplicative) {
  return function (f) {
    return function (xs) {
      return function (ys) {
        return Data_Traversable.sequence(Data_List_Types.traversableList)(dictApplicative)(zipWith(f)(xs)(ys));
      };
    };
  };
};

var range = function (start) {
  return function (end) {
    if (start === end) {
      return singleton(start);
    }

    ;

    if (Data_Boolean.otherwise) {
      var go = function ($copy_s) {
        return function ($copy_e) {
          return function ($copy_step) {
            return function ($copy_rest) {
              var $tco_var_s = $copy_s;
              var $tco_var_e = $copy_e;
              var $tco_var_step = $copy_step;
              var $tco_done = false;
              var $tco_result;

              function $tco_loop(s, e, step, rest) {
                if (s === e) {
                  $tco_done = true;
                  return new Data_List_Types.Cons(s, rest);
                }

                ;

                if (Data_Boolean.otherwise) {
                  $tco_var_s = s + step | 0;
                  $tco_var_e = e;
                  $tco_var_step = step;
                  $copy_rest = new Data_List_Types.Cons(s, rest);
                  return;
                }

                ;
                throw new Error("Failed pattern match at Data.List (line 148, column 3 - line 149, column 65): " + [s.constructor.name, e.constructor.name, step.constructor.name, rest.constructor.name]);
              }

              ;

              while (!$tco_done) {
                $tco_result = $tco_loop($tco_var_s, $tco_var_e, $tco_var_step, $copy_rest);
              }

              ;
              return $tco_result;
            };
          };
        };
      };

      return go(end)(start)(function () {
        var $220 = start > end;

        if ($220) {
          return 1;
        }

        ;
        return -1 | 0;
      }())(Data_List_Types.Nil.value);
    }

    ;
    throw new Error("Failed pattern match at Data.List (line 144, column 1 - line 144, column 32): " + [start.constructor.name, end.constructor.name]);
  };
};

var partition = function (p) {
  return function (xs) {
    var select = function (x) {
      return function (v) {
        var $223 = p(x);

        if ($223) {
          return {
            no: v.no,
            yes: new Data_List_Types.Cons(x, v.yes)
          };
        }

        ;
        return {
          no: new Data_List_Types.Cons(x, v.no),
          yes: v.yes
        };
      };
    };

    return Data_Foldable.foldr(Data_List_Types.foldableList)(select)({
      no: Data_List_Types.Nil.value,
      yes: Data_List_Types.Nil.value
    })(xs);
  };
};

var $$null = function (v) {
  if (v instanceof Data_List_Types.Nil) {
    return true;
  }

  ;
  return false;
};

var newtypePattern = new Data_Newtype.Newtype(function (n) {
  return n;
}, Pattern);
var mapWithIndex = Data_FunctorWithIndex.mapWithIndex(Data_List_Types.functorWithIndexList);

var mapMaybe = function (f) {
  var go = function ($copy_acc) {
    return function ($copy_v) {
      var $tco_var_acc = $copy_acc;
      var $tco_done = false;
      var $tco_result;

      function $tco_loop(acc, v) {
        if (v instanceof Data_List_Types.Nil) {
          $tco_done = true;
          return reverse(acc);
        }

        ;

        if (v instanceof Data_List_Types.Cons) {
          var v1 = f(v.value0);

          if (v1 instanceof Data_Maybe.Nothing) {
            $tco_var_acc = acc;
            $copy_v = v.value1;
            return;
          }

          ;

          if (v1 instanceof Data_Maybe.Just) {
            $tco_var_acc = new Data_List_Types.Cons(v1.value0, acc);
            $copy_v = v.value1;
            return;
          }

          ;
          throw new Error("Failed pattern match at Data.List (line 419, column 5 - line 421, column 32): " + [v1.constructor.name]);
        }

        ;
        throw new Error("Failed pattern match at Data.List (line 417, column 3 - line 417, column 27): " + [acc.constructor.name, v.constructor.name]);
      }

      ;

      while (!$tco_done) {
        $tco_result = $tco_loop($tco_var_acc, $copy_v);
      }

      ;
      return $tco_result;
    };
  };

  return go(Data_List_Types.Nil.value);
};

var manyRec = function (dictMonadRec) {
  return function (dictAlternative) {
    return function (p) {
      var go = function (acc) {
        return Control_Bind.bind(dictMonadRec.Monad0().Bind1())(Control_Alt.alt(dictAlternative.Plus1().Alt0())(Data_Functor.map(dictAlternative.Plus1().Alt0().Functor0())(Control_Monad_Rec_Class.Loop.create)(p))(Control_Applicative.pure(dictAlternative.Applicative0())(new Control_Monad_Rec_Class.Done(Data_Unit.unit))))(function (aa) {
          return Control_Applicative.pure(dictAlternative.Applicative0())(Data_Bifunctor.bimap(Control_Monad_Rec_Class.bifunctorStep)(function (v) {
            return new Data_List_Types.Cons(v, acc);
          })(function (v) {
            return reverse(acc);
          })(aa));
        });
      };

      return Control_Monad_Rec_Class.tailRecM(dictMonadRec)(go)(Data_List_Types.Nil.value);
    };
  };
};

var someRec = function (dictMonadRec) {
  return function (dictAlternative) {
    return function (v) {
      return Control_Apply.apply(dictAlternative.Applicative0().Apply0())(Data_Functor.map(dictAlternative.Plus1().Alt0().Functor0())(Data_List_Types.Cons.create)(v))(manyRec(dictMonadRec)(dictAlternative)(v));
    };
  };
};

var some = function (dictAlternative) {
  return function (dictLazy) {
    return function (v) {
      return Control_Apply.apply(dictAlternative.Applicative0().Apply0())(Data_Functor.map(dictAlternative.Plus1().Alt0().Functor0())(Data_List_Types.Cons.create)(v))(Control_Lazy.defer(dictLazy)(function (v1) {
        return many(dictAlternative)(dictLazy)(v);
      }));
    };
  };
};

var many = function (dictAlternative) {
  return function (dictLazy) {
    return function (v) {
      return Control_Alt.alt(dictAlternative.Plus1().Alt0())(some(dictAlternative)(dictLazy)(v))(Control_Applicative.pure(dictAlternative.Applicative0())(Data_List_Types.Nil.value));
    };
  };
};

var length = Data_Foldable.foldl(Data_List_Types.foldableList)(function (acc) {
  return function (v) {
    return acc + 1 | 0;
  };
})(0);

var last = function ($copy_v) {
  var $tco_done = false;
  var $tco_result;

  function $tco_loop(v) {
    if (v instanceof Data_List_Types.Cons && v.value1 instanceof Data_List_Types.Nil) {
      $tco_done = true;
      return new Data_Maybe.Just(v.value0);
    }

    ;

    if (v instanceof Data_List_Types.Cons) {
      $copy_v = v.value1;
      return;
    }

    ;
    $tco_done = true;
    return Data_Maybe.Nothing.value;
  }

  ;

  while (!$tco_done) {
    $tco_result = $tco_loop($copy_v);
  }

  ;
  return $tco_result;
};

var insertBy = function (v) {
  return function (x) {
    return function (v1) {
      if (v1 instanceof Data_List_Types.Nil) {
        return singleton(x);
      }

      ;

      if (v1 instanceof Data_List_Types.Cons) {
        var v2 = v(x)(v1.value0);

        if (v2 instanceof Data_Ordering.GT) {
          return new Data_List_Types.Cons(v1.value0, insertBy(v)(x)(v1.value1));
        }

        ;
        return new Data_List_Types.Cons(x, v1);
      }

      ;
      throw new Error("Failed pattern match at Data.List (line 216, column 1 - line 216, column 68): " + [v.constructor.name, x.constructor.name, v1.constructor.name]);
    };
  };
};

var insertAt = function (v) {
  return function (v1) {
    return function (v2) {
      if (v === 0) {
        return new Data_Maybe.Just(new Data_List_Types.Cons(v1, v2));
      }

      ;

      if (v2 instanceof Data_List_Types.Cons) {
        return Data_Functor.map(Data_Maybe.functorMaybe)(function (v3) {
          return new Data_List_Types.Cons(v2.value0, v3);
        })(insertAt(v - 1 | 0)(v1)(v2.value1));
      }

      ;
      return Data_Maybe.Nothing.value;
    };
  };
};

var insert = function (dictOrd) {
  return insertBy(Data_Ord.compare(dictOrd));
};

var init = function (lst) {
  return Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
    return v.init;
  })(unsnoc(lst));
};

var index = function ($copy_v) {
  return function ($copy_v1) {
    var $tco_var_v = $copy_v;
    var $tco_done = false;
    var $tco_result;

    function $tco_loop(v, v1) {
      if (v instanceof Data_List_Types.Nil) {
        $tco_done = true;
        return Data_Maybe.Nothing.value;
      }

      ;

      if (v instanceof Data_List_Types.Cons && v1 === 0) {
        $tco_done = true;
        return new Data_Maybe.Just(v.value0);
      }

      ;

      if (v instanceof Data_List_Types.Cons) {
        $tco_var_v = v.value1;
        $copy_v1 = v1 - 1 | 0;
        return;
      }

      ;
      throw new Error("Failed pattern match at Data.List (line 281, column 1 - line 281, column 44): " + [v.constructor.name, v1.constructor.name]);
    }

    ;

    while (!$tco_done) {
      $tco_result = $tco_loop($tco_var_v, $copy_v1);
    }

    ;
    return $tco_result;
  };
};

var head = function (v) {
  if (v instanceof Data_List_Types.Nil) {
    return Data_Maybe.Nothing.value;
  }

  ;

  if (v instanceof Data_List_Types.Cons) {
    return new Data_Maybe.Just(v.value0);
  }

  ;
  throw new Error("Failed pattern match at Data.List (line 230, column 1 - line 230, column 22): " + [v.constructor.name]);
};

var transpose = function (v) {
  if (v instanceof Data_List_Types.Nil) {
    return Data_List_Types.Nil.value;
  }

  ;

  if (v instanceof Data_List_Types.Cons && v.value0 instanceof Data_List_Types.Nil) {
    return transpose(v.value1);
  }

  ;

  if (v instanceof Data_List_Types.Cons && v.value0 instanceof Data_List_Types.Cons) {
    return new Data_List_Types.Cons(new Data_List_Types.Cons(v.value0.value0, mapMaybe(head)(v.value1)), transpose(new Data_List_Types.Cons(v.value0.value1, mapMaybe(tail)(v.value1))));
  }

  ;
  throw new Error("Failed pattern match at Data.List (line 752, column 1 - line 752, column 54): " + [v.constructor.name]);
};

var groupBy = function (v) {
  return function (v1) {
    if (v1 instanceof Data_List_Types.Nil) {
      return Data_List_Types.Nil.value;
    }

    ;

    if (v1 instanceof Data_List_Types.Cons) {
      var v2 = span(v(v1.value0))(v1.value1);
      return new Data_List_Types.Cons(new Data_NonEmpty.NonEmpty(v1.value0, v2.init), groupBy(v)(v2.rest));
    }

    ;
    throw new Error("Failed pattern match at Data.List (line 605, column 1 - line 605, column 80): " + [v.constructor.name, v1.constructor.name]);
  };
};

var group = function (dictEq) {
  return groupBy(Data_Eq.eq(dictEq));
};

var group$prime = function (dictOrd) {
  var $332 = group(dictOrd.Eq0());
  var $333 = sort(dictOrd);
  return function ($334) {
    return $332($333($334));
  };
};

var fromFoldable = function (dictFoldable) {
  return Data_Foldable.foldr(dictFoldable)(Data_List_Types.Cons.create)(Data_List_Types.Nil.value);
};

var foldM = function (dictMonad) {
  return function (v) {
    return function (a) {
      return function (v1) {
        if (v1 instanceof Data_List_Types.Nil) {
          return Control_Applicative.pure(dictMonad.Applicative0())(a);
        }

        ;

        if (v1 instanceof Data_List_Types.Cons) {
          return Control_Bind.bind(dictMonad.Bind1())(v(a)(v1.value0))(function (a$prime) {
            return foldM(dictMonad)(v)(a$prime)(v1.value1);
          });
        }

        ;
        throw new Error("Failed pattern match at Data.List (line 763, column 1 - line 763, column 72): " + [v.constructor.name, a.constructor.name, v1.constructor.name]);
      };
    };
  };
};

var findIndex = function (fn) {
  var go = function ($copy_v) {
    return function ($copy_v1) {
      var $tco_var_v = $copy_v;
      var $tco_done = false;
      var $tco_result;

      function $tco_loop(v, v1) {
        if (v1 instanceof Data_List_Types.Cons) {
          if (fn(v1.value0)) {
            $tco_done = true;
            return new Data_Maybe.Just(v);
          }

          ;

          if (Data_Boolean.otherwise) {
            $tco_var_v = v + 1 | 0;
            $copy_v1 = v1.value1;
            return;
          }

          ;
        }

        ;

        if (v1 instanceof Data_List_Types.Nil) {
          $tco_done = true;
          return Data_Maybe.Nothing.value;
        }

        ;
        throw new Error("Failed pattern match at Data.List (line 301, column 3 - line 301, column 35): " + [v.constructor.name, v1.constructor.name]);
      }

      ;

      while (!$tco_done) {
        $tco_result = $tco_loop($tco_var_v, $copy_v1);
      }

      ;
      return $tco_result;
    };
  };

  return go(0);
};

var findLastIndex = function (fn) {
  return function (xs) {
    return Data_Functor.map(Data_Maybe.functorMaybe)(function (v) {
      return (length(xs) - 1 | 0) - v | 0;
    })(findIndex(fn)(reverse(xs)));
  };
};

var filterM = function (dictMonad) {
  return function (v) {
    return function (v1) {
      if (v1 instanceof Data_List_Types.Nil) {
        return Control_Applicative.pure(dictMonad.Applicative0())(Data_List_Types.Nil.value);
      }

      ;

      if (v1 instanceof Data_List_Types.Cons) {
        return Control_Bind.bind(dictMonad.Bind1())(v(v1.value0))(function (b) {
          return Control_Bind.bind(dictMonad.Bind1())(filterM(dictMonad)(v)(v1.value1))(function (xs$prime) {
            return Control_Applicative.pure(dictMonad.Applicative0())(function () {
              if (b) {
                return new Data_List_Types.Cons(v1.value0, xs$prime);
              }

              ;
              return xs$prime;
            }());
          });
        });
      }

      ;
      throw new Error("Failed pattern match at Data.List (line 403, column 1 - line 403, column 75): " + [v.constructor.name, v1.constructor.name]);
    };
  };
};

var filter = function (p) {
  var go = function ($copy_acc) {
    return function ($copy_v) {
      var $tco_var_acc = $copy_acc;
      var $tco_done = false;
      var $tco_result;

      function $tco_loop(acc, v) {
        if (v instanceof Data_List_Types.Nil) {
          $tco_done = true;
          return reverse(acc);
        }

        ;

        if (v instanceof Data_List_Types.Cons) {
          if (p(v.value0)) {
            $tco_var_acc = new Data_List_Types.Cons(v.value0, acc);
            $copy_v = v.value1;
            return;
          }

          ;

          if (Data_Boolean.otherwise) {
            $tco_var_acc = acc;
            $copy_v = v.value1;
            return;
          }

          ;
        }

        ;
        throw new Error("Failed pattern match at Data.List (line 390, column 3 - line 390, column 27): " + [acc.constructor.name, v.constructor.name]);
      }

      ;

      while (!$tco_done) {
        $tco_result = $tco_loop($tco_var_acc, $copy_v);
      }

      ;
      return $tco_result;
    };
  };

  return go(Data_List_Types.Nil.value);
};

var intersectBy = function (v) {
  return function (v1) {
    return function (v2) {
      if (v1 instanceof Data_List_Types.Nil) {
        return Data_List_Types.Nil.value;
      }

      ;

      if (v2 instanceof Data_List_Types.Nil) {
        return Data_List_Types.Nil.value;
      }

      ;
      return filter(function (x) {
        return Data_Foldable.any(Data_List_Types.foldableList)(Data_HeytingAlgebra.heytingAlgebraBoolean)(v(x))(v2);
      })(v1);
    };
  };
};

var intersect = function (dictEq) {
  return intersectBy(Data_Eq.eq(dictEq));
};

var nubBy = function (v) {
  return function (v1) {
    if (v1 instanceof Data_List_Types.Nil) {
      return Data_List_Types.Nil.value;
    }

    ;

    if (v1 instanceof Data_List_Types.Cons) {
      return new Data_List_Types.Cons(v1.value0, nubBy(v)(filter(function (y) {
        return !v(v1.value0)(y);
      })(v1.value1)));
    }

    ;
    throw new Error("Failed pattern match at Data.List (line 644, column 1 - line 644, column 59): " + [v.constructor.name, v1.constructor.name]);
  };
};

var nub = function (dictEq) {
  return nubBy(Data_Eq.eq(dictEq));
};

var eqPattern = function (dictEq) {
  return new Data_Eq.Eq(function (x) {
    return function (y) {
      return Data_Eq.eq(Data_List_Types.eqList(dictEq))(x)(y);
    };
  });
};

var ordPattern = function (dictOrd) {
  return new Data_Ord.Ord(function () {
    return eqPattern(dictOrd.Eq0());
  }, function (x) {
    return function (y) {
      return Data_Ord.compare(Data_List_Types.ordList(dictOrd))(x)(y);
    };
  });
};

var elemLastIndex = function (dictEq) {
  return function (x) {
    return findLastIndex(function (v) {
      return Data_Eq.eq(dictEq)(v)(x);
    });
  };
};

var elemIndex = function (dictEq) {
  return function (x) {
    return findIndex(function (v) {
      return Data_Eq.eq(dictEq)(v)(x);
    });
  };
};

var dropWhile = function (p) {
  var go = function ($copy_v) {
    var $tco_done = false;
    var $tco_result;

    function $tco_loop(v) {
      if (v instanceof Data_List_Types.Cons && p(v.value0)) {
        $copy_v = v.value1;
        return;
      }

      ;
      $tco_done = true;
      return v;
    }

    ;

    while (!$tco_done) {
      $tco_result = $tco_loop($copy_v);
    }

    ;
    return $tco_result;
  };

  return go;
};

var dropEnd = function (n) {
  return function (xs) {
    return take(length(xs) - n | 0)(xs);
  };
};

var drop = function ($copy_v) {
  return function ($copy_v1) {
    var $tco_var_v = $copy_v;
    var $tco_done = false;
    var $tco_result;

    function $tco_loop(v, v1) {
      if (v < 1) {
        $tco_done = true;
        return v1;
      }

      ;

      if (v1 instanceof Data_List_Types.Nil) {
        $tco_done = true;
        return Data_List_Types.Nil.value;
      }

      ;

      if (v1 instanceof Data_List_Types.Cons) {
        $tco_var_v = v - 1 | 0;
        $copy_v1 = v1.value1;
        return;
      }

      ;
      throw new Error("Failed pattern match at Data.List (line 543, column 1 - line 543, column 42): " + [v.constructor.name, v1.constructor.name]);
    }

    ;

    while (!$tco_done) {
      $tco_result = $tco_loop($tco_var_v, $copy_v1);
    }

    ;
    return $tco_result;
  };
};

var slice = function (start) {
  return function (end) {
    return function (xs) {
      return take(end - start | 0)(drop(start)(xs));
    };
  };
};

var takeEnd = function (n) {
  return function (xs) {
    return drop(length(xs) - n | 0)(xs);
  };
};

var deleteBy = function (v) {
  return function (v1) {
    return function (v2) {
      if (v2 instanceof Data_List_Types.Nil) {
        return Data_List_Types.Nil.value;
      }

      ;

      if (v2 instanceof Data_List_Types.Cons && v(v1)(v2.value0)) {
        return v2.value1;
      }

      ;

      if (v2 instanceof Data_List_Types.Cons) {
        return new Data_List_Types.Cons(v2.value0, deleteBy(v)(v1)(v2.value1));
      }

      ;
      throw new Error("Failed pattern match at Data.List (line 671, column 1 - line 671, column 67): " + [v.constructor.name, v1.constructor.name, v2.constructor.name]);
    };
  };
};

var unionBy = function (eq) {
  return function (xs) {
    return function (ys) {
      return Data_Semigroup.append(Data_List_Types.semigroupList)(xs)(Data_Foldable.foldl(Data_List_Types.foldableList)(Data_Function.flip(deleteBy(eq)))(nubBy(eq)(ys))(xs));
    };
  };
};

var union = function (dictEq) {
  return unionBy(Data_Eq.eq(dictEq));
};

var deleteAt = function (v) {
  return function (v1) {
    if (v === 0 && v1 instanceof Data_List_Types.Cons) {
      return new Data_Maybe.Just(v1.value1);
    }

    ;

    if (v1 instanceof Data_List_Types.Cons) {
      return Data_Functor.map(Data_Maybe.functorMaybe)(function (v2) {
        return new Data_List_Types.Cons(v1.value0, v2);
      })(deleteAt(v - 1 | 0)(v1.value1));
    }

    ;
    return Data_Maybe.Nothing.value;
  };
};

var $$delete = function (dictEq) {
  return deleteBy(Data_Eq.eq(dictEq));
};

var difference = function (dictEq) {
  return Data_Foldable.foldl(Data_List_Types.foldableList)(Data_Function.flip($$delete(dictEq)));
};

var concatMap = Data_Function.flip(Control_Bind.bind(Data_List_Types.bindList));

var concat = function (v) {
  return Control_Bind.bind(Data_List_Types.bindList)(v)(Control_Category.identity(Control_Category.categoryFn));
};

var catMaybes = mapMaybe(Control_Category.identity(Control_Category.categoryFn));

var alterAt = function (v) {
  return function (v1) {
    return function (v2) {
      if (v === 0 && v2 instanceof Data_List_Types.Cons) {
        return Data_Maybe.Just.create(function () {
          var v3 = v1(v2.value0);

          if (v3 instanceof Data_Maybe.Nothing) {
            return v2.value1;
          }

          ;

          if (v3 instanceof Data_Maybe.Just) {
            return new Data_List_Types.Cons(v3.value0, v2.value1);
          }

          ;
          throw new Error("Failed pattern match at Data.List (line 352, column 3 - line 354, column 23): " + [v3.constructor.name]);
        }());
      }

      ;

      if (v2 instanceof Data_List_Types.Cons) {
        return Data_Functor.map(Data_Maybe.functorMaybe)(function (v3) {
          return new Data_List_Types.Cons(v2.value0, v3);
        })(alterAt(v - 1 | 0)(v1)(v2.value1));
      }

      ;
      return Data_Maybe.Nothing.value;
    };
  };
};

var modifyAt = function (n) {
  return function (f) {
    return alterAt(n)(function ($335) {
      return Data_Maybe.Just.create(f($335));
    });
  };
};

module.exports = {
  toUnfoldable: toUnfoldable,
  fromFoldable: fromFoldable,
  singleton: singleton,
  range: range,
  some: some,
  someRec: someRec,
  many: many,
  manyRec: manyRec,
  "null": $$null,
  length: length,
  snoc: snoc,
  insert: insert,
  insertBy: insertBy,
  head: head,
  last: last,
  tail: tail,
  init: init,
  uncons: uncons,
  unsnoc: unsnoc,
  index: index,
  elemIndex: elemIndex,
  elemLastIndex: elemLastIndex,
  findIndex: findIndex,
  findLastIndex: findLastIndex,
  insertAt: insertAt,
  deleteAt: deleteAt,
  updateAt: updateAt,
  modifyAt: modifyAt,
  alterAt: alterAt,
  reverse: reverse,
  concat: concat,
  concatMap: concatMap,
  filter: filter,
  filterM: filterM,
  mapMaybe: mapMaybe,
  catMaybes: catMaybes,
  mapWithIndex: mapWithIndex,
  sort: sort,
  sortBy: sortBy,
  Pattern: Pattern,
  stripPrefix: stripPrefix,
  slice: slice,
  take: take,
  takeEnd: takeEnd,
  takeWhile: takeWhile,
  drop: drop,
  dropEnd: dropEnd,
  dropWhile: dropWhile,
  span: span,
  group: group,
  "group'": group$prime,
  groupBy: groupBy,
  partition: partition,
  nub: nub,
  nubBy: nubBy,
  union: union,
  unionBy: unionBy,
  "delete": $$delete,
  deleteBy: deleteBy,
  difference: difference,
  intersect: intersect,
  intersectBy: intersectBy,
  zipWith: zipWith,
  zipWithA: zipWithA,
  zip: zip,
  unzip: unzip,
  transpose: transpose,
  foldM: foldM,
  eqPattern: eqPattern,
  ordPattern: ordPattern,
  newtypePattern: newtypePattern,
  showPattern: showPattern
};