// Generated by purs version 0.13.8
"use strict";

var Control_Alt = require("../Control.Alt/index.js");

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Control_Category = require("../Control.Category/index.js");

var Control_Monad_Except = require("../Control.Monad.Except/index.js");

var Control_Monad_Except_Trans = require("../Control.Monad.Except.Trans/index.js");

var Control_Semigroupoid = require("../Control.Semigroupoid/index.js");

var Data_Argonaut_Core = require("../Data.Argonaut.Core/index.js");

var Data_Boolean = require("../Data.Boolean/index.js");

var Data_Bounded = require("../Data.Bounded/index.js");

var Data_Date = require("../Data.Date/index.js");

var Data_Date_Component = require("../Data.Date.Component/index.js");

var Data_Either = require("../Data.Either/index.js");

var Data_Enum = require("../Data.Enum/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");

var Data_HugeNum = require("../Data.HugeNum/index.js");

var Data_Identity = require("../Data.Identity/index.js");

var Data_Int = require("../Data.Int/index.js");

var Data_List_Types = require("../Data.List.Types/index.js");

var Data_Long = require("../Data.Long/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Semigroup = require("../Data.Semigroup/index.js");

var Data_String_Common = require("../Data.String.Common/index.js");

var Data_Symbol = require("../Data.Symbol/index.js");

var Data_Time = require("../Data.Time/index.js");

var Data_Time_Component = require("../Data.Time.Component/index.js");

var Data_Traversable = require("../Data.Traversable/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Foreign = require("../Foreign/index.js");

var Foreign_Object = require("../Foreign.Object/index.js");

var Record_Builder = require("../Record.Builder/index.js");

var Type_Data_RowList = require("../Type.Data.RowList/index.js");

var Type_Proxy = require("../Type.Proxy/index.js");

var ZRPC_ConsPrefix = require("../ZRPC.ConsPrefix/index.js");

var ZRPC_Types_LocalDateTime = require("../ZRPC.Types.LocalDateTime/index.js");

var ZRPC_Types_Millis = require("../ZRPC.Types.Millis/index.js");

var ZRPC_Types_ZJson = require("../ZRPC.Types.ZJson/index.js");

var ValF = function (x) {
  return x;
};

var Null = function () {
  function Null() {}

  ;
  Null.value = new Null();
  return Null;
}();

var ZRPCReadRep = function (zrpcReadRep) {
  this.zrpcReadRep = zrpcReadRep;
};

var ZRPCReadFields = function (zrpcReadFields) {
  this.zrpcReadFields = zrpcReadFields;
};

var ZRPCReadField = function (zrpcReadField) {
  this.zrpcReadField = zrpcReadField;
};

var ZRPCRead = function (zrpcRead) {
  this.zrpcRead = zrpcRead;
};

var Tag = function (tag) {
  this.tag = tag;
};

var zrpcReadZJson = new ZRPCRead(function () {
  var $110 = Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity));
  return function ($111) {
    return $110(ZRPC_Types_ZJson.ZJson($111));
  };
}());
var zrpcReadUnit = new ZRPCRead(function (v) {
  return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Unit.unit);
});

var zrpcReadRep = function (dict) {
  return dict.zrpcReadRep;
};

var zrpcReadFieldsNil = new ZRPCReadFields(function (v) {
  return function (v1) {
    return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Control_Category.identity(Record_Builder.categoryBuilder));
  };
});

var zrpcReadFields = function (dict) {
  return dict.zrpcReadFields;
};

var zrpcReadField = function (dict) {
  return dict.zrpcReadField;
};

var zrpcRead = function (dict) {
  return dict.zrpcRead;
};

var unValF = function (v) {
  return v;
};

var typeExpE = function (exp) {
  return function (act) {
    return Foreign.fail(new Foreign.TypeMismatch(exp, act));
  };
};

var tagTime = new Tag(function (v) {
  return "LocalTime";
});
var tagString = new Tag(function (v) {
  return "String";
});
var tagObject = new Tag(function (v) {
  return "Object";
});
var tagNumber = new Tag(function (v) {
  return "Number";
});
var tagNull = new Tag(function (v) {
  return "Null";
});
var tagLong = new Tag(function (v) {
  return "Long";
});
var tagLocalDateTime = new Tag(function (v) {
  return "LocalDateTime";
});
var tagInt = new Tag(function (v) {
  return "Int";
});
var tagHugeNum = new Tag(function (v) {
  return "BigDecimal";
});
var tagDateTime = new Tag(function (v) {
  return "DateTime";
});
var tagDate = new Tag(function (v) {
  return "LocalDate";
});
var tagBoolean = new Tag(function (v) {
  return "Boolean";
});
var tagArray = new Tag(function (v) {
  return "Array";
});

var tag = function (dict) {
  return dict.tag;
};

var readRepSum = function (dictZRPCReadRep) {
  return function (dictZRPCReadRep1) {
    return new ZRPCReadRep(function (f) {
      return function (name) {
        return Control_Alt.alt(Control_Monad_Except_Trans.altExceptT(Data_List_Types.semigroupNonEmptyList)(Data_Identity.monadIdentity))(Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Generic_Rep.Inl.create)(zrpcReadRep(dictZRPCReadRep)(f)(name)))(Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Generic_Rep.Inr.create)(zrpcReadRep(dictZRPCReadRep1)(f)(name)));
      };
    });
  };
};

var readPropWith$prime = function (name) {
  return function (r) {
    return function (o) {
      var v = Foreign_Object.lookup(name)(o);
      return Control_Monad_Except.withExcept(Data_Functor.map(Data_List_Types.functorNonEmptyList)(Foreign.ErrorAtProperty.create(name)))(r(v));
    };
  };
};

var parseTimeParts = function (h$prime) {
  return function (m$prime) {
    return function (s$prime) {
      return function (msM) {
        var msM$prime = function () {
          if (msM instanceof Data_Maybe.Just) {
            return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Int.fromString(msM.value0))(Data_Enum.toEnum(Data_Time_Component.boundedEnumMillisecond));
          }

          ;

          if (msM instanceof Data_Maybe.Nothing) {
            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)(Data_Bounded.bottom(Data_Time_Component.boundedMillisecond));
          }

          ;
          throw new Error("Failed pattern match at ZRPC.ZRPCRead (line 318, column 12 - line 320, column 29): " + [msM.constructor.name]);
        }();

        return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Time.Time.create)(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Int.fromString(h$prime))(Data_Enum.toEnum(Data_Time_Component.boundedEnumHour))))(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Int.fromString(m$prime))(Data_Enum.toEnum(Data_Time_Component.boundedEnumMinute))))(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Int.fromString(s$prime))(Data_Enum.toEnum(Data_Time_Component.boundedEnumSecond))))(msM$prime);
      };
    };
  };
};

var stringToTime = function (s) {
  var v = Data_String_Common.split(":")(s);

  if (v.length === 3) {
    var v1 = Data_String_Common.split(".")(v[2]);

    if (v1.length === 2) {
      return parseTimeParts(v[0])(v[1])(v1[0])(new Data_Maybe.Just(v1[1]));
    }

    ;
    return parseTimeParts(v[0])(v[1])(v[2])(Data_Maybe.Nothing.value);
  }

  ;
  return Data_Maybe.Nothing.value;
};

var parseDateParts = function (y$prime) {
  return function (m$prime) {
    return function (d$prime) {
      return Control_Apply.apply(Data_Maybe.applyMaybe)(Control_Apply.apply(Data_Maybe.applyMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(Data_Date.canonicalDate)(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Int.fromString(y$prime))(Data_Enum.toEnum(Data_Date_Component.boundedEnumYear))))(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Int.fromString(m$prime))(Data_Enum.toEnum(Data_Date_Component.boundedEnumMonth))))(Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Int.fromString(d$prime))(Data_Enum.toEnum(Data_Date_Component.boundedEnumDay)));
    };
  };
};

var stringToDate = function (s) {
  var v = Data_String_Common.split("-")(s);

  if (v.length === 3) {
    return parseDateParts(v[0])(v[1])(v[2]);
  }

  ;
  return Data_Maybe.Nothing.value;
};

var stringToLocalDateTime = function (s) {
  var v = Data_String_Common.split("T")(s);

  if (v.length === 2) {
    return Control_Apply.apply(Data_Maybe.applyMaybe)(Data_Functor.map(Data_Maybe.functorMaybe)(ZRPC_Types_LocalDateTime.localDateTime)(stringToDate(v[0])))(stringToTime(v[1]));
  }

  ;
  return Data_Maybe.Nothing.value;
};

var stringToDateTime = function (s) {
  var v = Data_String_Common.split("Z")(s);

  if (v.length === 2 && v[1] === "") {
    return Data_Functor.map(Data_Maybe.functorMaybe)(ZRPC_Types_LocalDateTime.toUTCDateTime)(stringToLocalDateTime(v[0]));
  }

  ;
  return Data_Maybe.Nothing.value;
};

var nullAsMaybe = function (j) {
  var $87 = Data_Argonaut_Core.isNull(j);

  if ($87) {
    return Data_Maybe.Nothing.value;
  }

  ;
  return new Data_Maybe.Just(j);
};

var zrpcReadField$prime = function (dictZRPCReadField) {
  var $112 = zrpcReadField(dictZRPCReadField);
  return function ($113) {
    return $112(nullAsMaybe($113));
  };
};

var missingError = new Foreign.ForeignError("missing");

var readPropWith = function (name) {
  return function (r) {
    return readPropWith$prime(name)(function (x) {
      if (x instanceof Data_Maybe.Just) {
        return r(x.value0);
      }

      ;

      if (x instanceof Data_Maybe.Nothing) {
        return Foreign.fail(new Foreign.ErrorAtProperty(name, missingError));
      }

      ;
      throw new Error("Failed pattern match at ZRPC.ZRPCRead (line 377, column 28 - line 380, column 47): " + [x.constructor.name]);
    });
  };
};

var zrpcReadFieldId = function (dictZRPCRead) {
  return new ZRPCReadField(function (v) {
    if (v instanceof Data_Maybe.Just) {
      return zrpcRead(dictZRPCRead)(v.value0);
    }

    ;

    if (v instanceof Data_Maybe.Nothing) {
      return Foreign.fail(missingError);
    }

    ;
    throw new Error("Failed pattern match at ZRPC.ZRPCRead (line 222, column 6 - line 224, column 45): " + [v.constructor.name]);
  });
};

var functorValF = Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity);

var failUnknownType = function (s) {
  return Foreign.fail(Foreign.ForeignError.create("unknown type: " + s));
};

var readRepNoArgs = function (dictIsSymbol) {
  return new ZRPCReadRep(function (v) {
    return function (name) {
      if (name === Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value)) {
        return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Generic_Rep.NoArguments.value);
      }

      ;

      if (Data_Boolean.otherwise) {
        return failUnknownType(name);
      }

      ;
      throw new Error("Failed pattern match at ZRPC.ZRPCRead (line 270, column 1 - line 273, column 39): " + [v.constructor.name, name.constructor.name]);
    };
  });
};

var readRepRecArg = function (dictIsSymbol) {
  return function (dictZRPCRead) {
    return new ZRPCReadRep(function (f) {
      return function (name) {
        if (name === Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value)) {
          return Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(function ($114) {
            return Data_Generic_Rep.Constructor(Data_Generic_Rep.Argument($114));
          })(zrpcRead(dictZRPCRead)(f));
        }

        ;

        if (Data_Boolean.otherwise) {
          return failUnknownType(name);
        }

        ;
        throw new Error("Failed pattern match at ZRPC.ZRPCRead (line 275, column 1 - line 278, column 39): " + [f.constructor.name, name.constructor.name]);
      };
    });
  };
};

var failInvalidFormat = function (s) {
  return Foreign.fail(Foreign.ForeignError.create("not a valid " + (s + " format")));
};

var noteFormat = function (dictTag) {
  return function (m) {
    if (m instanceof Data_Maybe.Just) {
      return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(m.value0);
    }

    ;

    if (m instanceof Data_Maybe.Nothing) {
      return failInvalidFormat(tag(dictTag)(Type_Proxy["Proxy"].value));
    }

    ;
    throw new Error("Failed pattern match at ZRPC.ZRPCRead (line 349, column 16 - line 351, column 39): " + [m.constructor.name]);
  };
};

var readFormat = function (dictZRPCRead) {
  return function (dictTag) {
    return function (r) {
      return Control_Bind.composeKleisli(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(zrpcRead(dictZRPCRead))(function () {
        var $115 = noteFormat(dictTag);
        return function ($116) {
          return $115(r($116));
        };
      }());
    };
  };
};

var caseJson$prime = function (h) {
  return Data_Argonaut_Core.caseJson(h.jreadNull)(h.jreadBoolean)(h.jreadNumber)(h.jreadString)(h.jreadArray)(h.jreadObject);
};

var bh = function (dictTag) {
  var exp = tag(dictTag)(Type_Proxy["Proxy"].value);

  var e = function (dictTag1) {
    return function (v) {
      return typeExpE(exp)(tag(dictTag1)(Type_Proxy["Proxy"].value));
    };
  };

  return {
    jreadNull: function (v) {
      return e(tagNull)(Null.value);
    },
    jreadBoolean: e(tagBoolean),
    jreadNumber: e(tagNumber),
    jreadString: e(tagString),
    jreadArray: e(tagArray),
    jreadObject: e(tagObject)
  };
};

var readArray = caseJson$prime({
  jreadNull: bh(tagArray).jreadNull,
  jreadBoolean: bh(tagArray).jreadBoolean,
  jreadNumber: bh(tagArray).jreadNumber,
  jreadString: bh(tagArray).jreadString,
  jreadArray: Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity)),
  jreadObject: bh(tagArray).jreadObject
});

var zrpcReadArray = function (dictZRPCReadField) {
  return new ZRPCRead(function (f) {
    return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readArray(f))(Data_Traversable.traverse(Data_Traversable.traversableArray)(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(zrpcReadField$prime(dictZRPCReadField)));
  });
};

var readNull = caseJson$prime({
  jreadNull: function (v1) {
    return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Null.value);
  },
  jreadBoolean: bh(tagNull).jreadBoolean,
  jreadNumber: bh(tagNull).jreadNumber,
  jreadString: bh(tagNull).jreadString,
  jreadArray: bh(tagNull).jreadArray,
  jreadObject: bh(tagNull).jreadObject
});

var zrpcReadFieldMaybe = function (dictZRPCRead) {
  return new ZRPCReadField(function (v) {
    if (v instanceof Data_Maybe.Just) {
      return Control_Alt.alt(Control_Monad_Except_Trans.altExceptT(Data_List_Types.semigroupNonEmptyList)(Data_Identity.monadIdentity))(Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Maybe.Just.create)(zrpcRead(dictZRPCRead)(v.value0)))(Data_Functor.voidLeft(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(readNull(v.value0))(Data_Maybe.Nothing.value));
    }

    ;

    if (v instanceof Data_Maybe.Nothing) {
      return Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))(Data_Maybe.Nothing.value);
    }

    ;
    throw new Error("Failed pattern match at ZRPC.ZRPCRead (line 219, column 1 - line 221, column 40): " + [v.constructor.name]);
  });
};

var readObject = caseJson$prime({
  jreadNull: bh(tagObject).jreadNull,
  jreadBoolean: bh(tagObject).jreadBoolean,
  jreadNumber: bh(tagObject).jreadNumber,
  jreadString: bh(tagObject).jreadString,
  jreadArray: bh(tagObject).jreadArray,
  jreadObject: Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity))
});

var readRecord = function (dictRowToList) {
  return function (dictZRPCReadFields) {
    return new ZRPCRead(function (f) {
      return Data_Functor.mapFlipped(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readObject(f))(zrpcReadFields(dictZRPCReadFields)(Type_Data_RowList.RLProxy.value)))(Data_Function.flip(Record_Builder.build)({}));
    });
  };
};

var zrpcReadEither = function (dictZRPCReadField) {
  return function (dictZRPCReadField1) {
    return new ZRPCRead(function (f) {
      var readRight = function (o) {
        return Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Either.Right.create)(readPropWith("Right")(zrpcReadField$prime(dictZRPCReadField1))(o));
      };

      var readLeft = function (o) {
        return Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Either.Left.create)(readPropWith("Left")(zrpcReadField$prime(dictZRPCReadField))(o));
      };

      return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readObject(f))(function (o) {
        return Control_Alt.alt(Control_Monad_Except_Trans.altExceptT(Data_List_Types.semigroupNonEmptyList)(Data_Identity.monadIdentity))(readRight(o))(readLeft(o));
      });
    });
  };
};

var zrpcReadBoolean = new ZRPCRead(caseJson$prime({
  jreadNull: bh(tagBoolean).jreadNull,
  jreadBoolean: Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity)),
  jreadNumber: bh(tagBoolean).jreadNumber,
  jreadString: bh(tagBoolean).jreadString,
  jreadArray: bh(tagBoolean).jreadArray,
  jreadObject: bh(tagBoolean).jreadObject
}));
var zrpcReadNumber = new ZRPCRead(caseJson$prime({
  jreadNull: bh(tagNumber).jreadNull,
  jreadBoolean: bh(tagNumber).jreadBoolean,
  jreadNumber: Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity)),
  jreadString: bh(tagNumber).jreadString,
  jreadArray: bh(tagNumber).jreadArray,
  jreadObject: bh(tagNumber).jreadObject
}));
var zrpcReadInt = new ZRPCRead(readFormat(zrpcReadNumber)(tagInt)(Data_Int.fromNumber));
var zrpcReadString = new ZRPCRead(caseJson$prime({
  jreadNull: bh(tagString).jreadNull,
  jreadBoolean: bh(tagString).jreadBoolean,
  jreadNumber: bh(tagString).jreadNumber,
  jreadString: Control_Applicative.pure(Control_Monad_Except_Trans.applicativeExceptT(Data_Identity.monadIdentity)),
  jreadArray: bh(tagString).jreadArray,
  jreadObject: bh(tagString).jreadObject
}));

var genericZRPCRead = function (dictConsPrefix) {
  return function (dictGeneric) {
    return function (dictZRPCReadRep) {
      return function (f) {
        return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readObject(f))(function (o) {
          return Control_Bind.bind(Control_Monad_Except_Trans.bindExceptT(Data_Identity.monadIdentity))(readPropWith("type")(zrpcRead(zrpcReadString))(o))(function (s) {
            var tpName = ZRPC_ConsPrefix.consPrefix(dictConsPrefix)(Type_Proxy["Proxy"].value) + s;
            return Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_Generic_Rep.to(dictGeneric))(zrpcReadRep(dictZRPCReadRep)(f)(tpName));
          });
        });
      };
    };
  };
};

var zrpcReadDate = new ZRPCRead(readFormat(zrpcReadString)(tagDate)(stringToDate));
var zrpcReadDateTime = new ZRPCRead(readFormat(zrpcReadString)(tagDateTime)(stringToDateTime));
var zrpcReadHugeNum = new ZRPCRead(function (f) {
  return Control_Alt.alt(Control_Monad_Except_Trans.altExceptT(Data_List_Types.semigroupNonEmptyList)(Data_Identity.monadIdentity))(Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Data_HugeNum.fromNumber)(zrpcRead(zrpcReadNumber)(f)))(readFormat(zrpcReadString)(tagHugeNum)(Data_HugeNum.fromString)(f));
});
var zrpcReadLocalDateTime = new ZRPCRead(readFormat(zrpcReadString)(tagLocalDateTime)(stringToLocalDateTime));
var zrpcReadLong = new ZRPCRead(function (f) {
  return Control_Alt.alt(Control_Monad_Except_Trans.altExceptT(Data_List_Types.semigroupNonEmptyList)(Data_Identity.monadIdentity))(readFormat(zrpcReadNumber)(tagLong)(Data_Long.fromNumber)(f))(readFormat(zrpcReadString)(tagLong)(Data_Long.fromString)(f));
});
var zrpcReadMillis = new ZRPCRead(function () {
  var $117 = Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(ZRPC_Types_Millis.Millis);
  var $118 = zrpcRead(zrpcReadLong);
  return function ($119) {
    return $117($118($119));
  };
}());
var zrpcReadTime = new ZRPCRead(readFormat(zrpcReadString)(tagTime)(stringToTime));
var applyValF = new Control_Apply.Apply(function () {
  return functorValF;
}, function (v) {
  return function (v1) {
    return ValF(Control_Monad_Except_Trans.except(Data_Identity.applicativeIdentity)(function () {
      var v2 = Control_Monad_Except.runExcept(v1);
      var v3 = Control_Monad_Except.runExcept(v);

      if (v3 instanceof Data_Either.Right && v2 instanceof Data_Either.Right) {
        return Data_Either.Right.create(v3.value0(v2.value0));
      }

      ;

      if (v3 instanceof Data_Either.Left && v2 instanceof Data_Either.Left) {
        return new Data_Either.Left(Data_Semigroup.append(Data_List_Types.semigroupNonEmptyList)(v3.value0)(v2.value0));
      }

      ;

      if (v2 instanceof Data_Either.Left) {
        return new Data_Either.Left(v2.value0);
      }

      ;

      if (v3 instanceof Data_Either.Left) {
        return new Data_Either.Left(v3.value0);
      }

      ;
      throw new Error("Failed pattern match at ZRPC.ZRPCRead (line 406, column 50 - line 410, column 35): " + [v3.constructor.name, v2.constructor.name]);
    }()));
  };
});

var zrpcReadFieldsCons = function (dictIsSymbol) {
  return function (dictZRPCReadField) {
    return function (dictZRPCReadFields) {
      return function (dictLacks) {
        return function (dictCons) {
          return new ZRPCReadFields(function (v) {
            return function (o) {
              var rest = ValF(zrpcReadFields(dictZRPCReadFields)(Type_Data_RowList.RLProxy.value)(o));
              var name = Data_Symbol.reflectSymbol(dictIsSymbol)(Data_Symbol.SProxy.value);
              var v1 = readPropWith$prime(name)(zrpcReadField(dictZRPCReadField))(o);
              var first = ValF(Data_Functor.map(Control_Monad_Except_Trans.functorExceptT(Data_Identity.functorIdentity))(Record_Builder.insert()()(dictIsSymbol)(Data_Symbol.SProxy.value))(v1));
              return unValF(Control_Apply.apply(applyValF)(Data_Functor.map(functorValF)(Control_Semigroupoid.compose(Record_Builder.semigroupoidBuilder))(first))(rest));
            };
          });
        };
      };
    };
  };
};

module.exports = {
  ZRPCRead: ZRPCRead,
  zrpcRead: zrpcRead,
  genericZRPCRead: genericZRPCRead,
  ZRPCReadFields: ZRPCReadFields,
  zrpcReadFields: zrpcReadFields,
  ZRPCReadField: ZRPCReadField,
  zrpcReadField: zrpcReadField,
  ZRPCReadRep: ZRPCReadRep,
  zrpcReadRep: zrpcReadRep,
  zrpcReadUnit: zrpcReadUnit,
  zrpcReadBoolean: zrpcReadBoolean,
  zrpcReadNumber: zrpcReadNumber,
  zrpcReadString: zrpcReadString,
  zrpcReadInt: zrpcReadInt,
  zrpcReadLong: zrpcReadLong,
  zrpcReadHugeNum: zrpcReadHugeNum,
  zrpcReadDate: zrpcReadDate,
  zrpcReadTime: zrpcReadTime,
  zrpcReadLocalDateTime: zrpcReadLocalDateTime,
  zrpcReadDateTime: zrpcReadDateTime,
  zrpcReadMillis: zrpcReadMillis,
  zrpcReadZJson: zrpcReadZJson,
  zrpcReadArray: zrpcReadArray,
  zrpcReadEither: zrpcReadEither,
  readRecord: readRecord,
  zrpcReadFieldMaybe: zrpcReadFieldMaybe,
  zrpcReadFieldId: zrpcReadFieldId,
  zrpcReadFieldsNil: zrpcReadFieldsNil,
  zrpcReadFieldsCons: zrpcReadFieldsCons,
  readRepNoArgs: readRepNoArgs,
  readRepRecArg: readRepRecArg,
  readRepSum: readRepSum
};