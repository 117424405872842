// Generated by purs version 0.13.8
"use strict";

var $foreign = require("./foreign.js");

var Control_Category = require("../Control.Category/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Functor = require("../Data.Functor/index.js");

var Apply = function (Functor0, apply) {
  this.Functor0 = Functor0;
  this.apply = apply;
};

var applyFn = new Apply(function () {
  return Data_Functor.functorFn;
}, function (f) {
  return function (g) {
    return function (x) {
      return f(x)(g(x));
    };
  };
});
var applyArray = new Apply(function () {
  return Data_Functor.functorArray;
}, $foreign.arrayApply);

var apply = function (dict) {
  return dict.apply;
};

var applyFirst = function (dictApply) {
  return function (a) {
    return function (b) {
      return apply(dictApply)(Data_Functor.map(dictApply.Functor0())(Data_Function["const"])(a))(b);
    };
  };
};

var applySecond = function (dictApply) {
  return function (a) {
    return function (b) {
      return apply(dictApply)(Data_Functor.map(dictApply.Functor0())(Data_Function["const"](Control_Category.identity(Control_Category.categoryFn)))(a))(b);
    };
  };
};

var lift2 = function (dictApply) {
  return function (f) {
    return function (a) {
      return function (b) {
        return apply(dictApply)(Data_Functor.map(dictApply.Functor0())(f)(a))(b);
      };
    };
  };
};

var lift3 = function (dictApply) {
  return function (f) {
    return function (a) {
      return function (b) {
        return function (c) {
          return apply(dictApply)(apply(dictApply)(Data_Functor.map(dictApply.Functor0())(f)(a))(b))(c);
        };
      };
    };
  };
};

var lift4 = function (dictApply) {
  return function (f) {
    return function (a) {
      return function (b) {
        return function (c) {
          return function (d) {
            return apply(dictApply)(apply(dictApply)(apply(dictApply)(Data_Functor.map(dictApply.Functor0())(f)(a))(b))(c))(d);
          };
        };
      };
    };
  };
};

var lift5 = function (dictApply) {
  return function (f) {
    return function (a) {
      return function (b) {
        return function (c) {
          return function (d) {
            return function (e) {
              return apply(dictApply)(apply(dictApply)(apply(dictApply)(apply(dictApply)(Data_Functor.map(dictApply.Functor0())(f)(a))(b))(c))(d))(e);
            };
          };
        };
      };
    };
  };
};

module.exports = {
  Apply: Apply,
  apply: apply,
  applyFirst: applyFirst,
  applySecond: applySecond,
  lift2: lift2,
  lift3: lift3,
  lift4: lift4,
  lift5: lift5,
  applyFn: applyFn,
  applyArray: applyArray
};