import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import axios from 'axios';
import { zenv } from '../../constants';
import styled from 'styled-components';
import { Spinner } from '../components/Spinner';
import { getAssetsUrl } from '../../utils';

// Types
interface Branch {
  name?: string;
  address?: string;
}
interface Region {
  name?: string;
  branches?: Branch[];
}

interface Initial {
  kind: 'Initial';
}
interface InFlight {
  kind: 'InFlight';
}

interface FetchErr {
  kind: 'FetchErr';
  message: string;
}

interface Success {
  kind: 'Success';
  regions: Region[];
}

type AsyncRegion = Initial | InFlight | FetchErr | Success;

// Functions
async function getJollibeeBranches(s3Path: string): Promise<Region[]> {
  const { data } = await axios(`${getAssetsUrl(zenv)}${s3Path}`);
  return data;
}

// Components

const Container = styled.div`
  padding: 20px 34px;
  display: flex;
  flex-direction: column;
  min-height: 60vh;
`;

const Title = styled.h3`
  font-family: ProximaNova-Semibold;
  letter-spacing: 0.05em;
  font-size: 20px;
`;
const RegionsWrapper = styled.ul`
  list-style-type: none;
  padding: 0;
`;
const RegionEntry = styled.li`
  & > h5 {
    font-size: 16px;
    letter-spacing: 0.05em;
    font-family: ProximaNova-Bold;
    margin: 0px 0px 10px 0px;
  }
  margin-bottom: 36px;
`;
const BranchEntry = styled.div`
  & > p:first-child {
    font-size: 14px;
    font-family: ProximaNova-Reg;
    letter-spacing: 0.05em;
    margin: 0;
  }
  & > p:nth-child(2) {
    font-size: 14px;
    font-family: ProximaNova-Reg;
    letter-spacing: 0.05em;
    margin: 0;
  }
  margin-bottom: 16px;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const Jollibee: React.FC<RouteComponentProps> = ({ match }) => {
  const s3Path = match.url + '/branches.json';
  const [asyncRegions, setAsyncRegions] = useState<AsyncRegion>({
    kind: 'Initial',
  });
  useEffect(() => {
    setAsyncRegions({ kind: 'InFlight' });
    getJollibeeBranches(s3Path)
      .then((regions) => setAsyncRegions({ kind: 'Success', regions }))
      .catch((err) => {
        console.error(err);
        setAsyncRegions({
          kind: 'FetchErr',
          message: 'Unable to load branches.',
        });
      });
  }, [s3Path]);
  return (
    <Container>
      <Title>Participating Jollibee Branches</Title>
      {(() => {
        switch (asyncRegions.kind) {
          case 'Initial':
            return null;
          case 'InFlight':
            return (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            );
          case 'FetchErr':
            return <h5>{asyncRegions.message}</h5>;
          case 'Success':
            return (
              <RegionsWrapper>
                {asyncRegions.regions.map((region) => (
                  <RegionEntry className="region-entry" key={region.name}>
                    <h5>{region.name}</h5>
                    {(region.branches || []).map((branch) => (
                      <BranchEntry key={branch.name}>
                        <p>{branch.name}</p>
                        <p>{branch.address}</p>
                      </BranchEntry>
                    ))}
                  </RegionEntry>
                ))}
              </RegionsWrapper>
            );
        }
      })()}
    </Container>
  );
};
