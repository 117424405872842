// Generated by purs version 0.13.8
"use strict";

var $foreign = require("./foreign.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Category = require("../Control.Category/index.js");

var Data_Function = require("../Data.Function/index.js");

var Discard = function (discard) {
  this.discard = discard;
};

var Bind = function (Apply0, bind) {
  this.Apply0 = Apply0;
  this.bind = bind;
};

var discard = function (dict) {
  return dict.discard;
};

var bindFn = new Bind(function () {
  return Control_Apply.applyFn;
}, function (m) {
  return function (f) {
    return function (x) {
      return f(m(x))(x);
    };
  };
});
var bindArray = new Bind(function () {
  return Control_Apply.applyArray;
}, $foreign.arrayBind);

var bind = function (dict) {
  return dict.bind;
};

var bindFlipped = function (dictBind) {
  return Data_Function.flip(bind(dictBind));
};

var composeKleisliFlipped = function (dictBind) {
  return function (f) {
    return function (g) {
      return function (a) {
        return bindFlipped(dictBind)(f)(g(a));
      };
    };
  };
};

var composeKleisli = function (dictBind) {
  return function (f) {
    return function (g) {
      return function (a) {
        return bind(dictBind)(f(a))(g);
      };
    };
  };
};

var discardUnit = new Discard(function (dictBind) {
  return bind(dictBind);
});

var ifM = function (dictBind) {
  return function (cond) {
    return function (t) {
      return function (f) {
        return bind(dictBind)(cond)(function (cond$prime) {
          if (cond$prime) {
            return t;
          }

          ;
          return f;
        });
      };
    };
  };
};

var join = function (dictBind) {
  return function (m) {
    return bind(dictBind)(m)(Control_Category.identity(Control_Category.categoryFn));
  };
};

module.exports = {
  Bind: Bind,
  bind: bind,
  bindFlipped: bindFlipped,
  Discard: Discard,
  discard: discard,
  join: join,
  composeKleisli: composeKleisli,
  composeKleisliFlipped: composeKleisliFlipped,
  ifM: ifM,
  bindFn: bindFn,
  bindArray: bindArray,
  discardUnit: discardUnit
};