// Generated by purs version 0.13.8
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Apply = require("../Control.Apply/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Data_Array = require("../Data.Array/index.js");

var Data_Eq = require("../Data.Eq/index.js");

var Data_Function = require("../Data.Function/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Monoid = require("../Data.Monoid/index.js");

var Data_Newtype = require("../Data.Newtype/index.js");

var Data_String_Common = require("../Data.String.Common/index.js");

var Data_Unit = require("../Data.Unit/index.js");

var Effect = require("../Effect/index.js");

var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");

var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");

var ReactRouter_DOM_History = require("../ReactRouter.DOM.History/index.js");

var Type_Equality = require("../Type.Equality/index.js");

var ZAP_Trovex_Client_Utils_PathDupe = require("../ZAP.Trovex.Client.Utils.PathDupe/index.js");

var UseScreenManager = function (x) {
  return x;
};

var ntUseScreenManager = new Data_Newtype.Newtype(function (n) {
  return n;
}, UseScreenManager);

var useScreenManager = function (dictPathDupe) {
  return function (fallbackScreen) {
    return function (baseUrl) {
      return React_Basic_Hooks_Internal.coerceHook(ntUseScreenManager)(React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(ReactRouter_DOM_History.useHistory)(function (v) {
        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(0))(function (v1) {
          var $$switch = function (scrn) {
            return Control_Apply.applySecond(Effect.applyEffect)(function () {
              return v.push(baseUrl + ("/" + (ZAP_Trovex_Client_Utils_PathDupe.toPath(dictPathDupe)(scrn) + v.location.search)), Data_Unit.unit);
            })(v1.value1(function (count) {
              return count + 1 | 0;
            }));
          };

          var replace = function (scrn) {
            return function () {
              return v.replace(baseUrl + ("/" + (ZAP_Trovex_Client_Utils_PathDupe.toPath(dictPathDupe)(scrn) + v.location.search)), Data_Unit.unit);
            };
          };

          var fromLocPath = function (s) {
            return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Array.last(Data_String_Common.split("/")(s)))(ZAP_Trovex_Client_Utils_PathDupe.fromPath(dictPathDupe));
          };

          var back = function (count) {
            var $11 = count <= 0;

            if ($11) {
              return Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit);
            }

            ;
            return Control_Apply.applySecond(Effect.applyEffect)(v.goBack)(back(count - 1 | 0));
          };

          var reset = Control_Apply.applySecond(Effect.applyEffect)(back(v1.value0))(v1.value1(Data_Function["const"](0)));
          return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useState(fromLocPath(v.location.pathname)))(function (v2) {
            return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useEffect(Data_Eq.eqUnit)(Data_Unit.unit)(function () {
              return v.listen(function (v3, v4) {
                return v2.value1(Data_Function["const"](fromLocPath(v3.pathname)))();
              });
            }))(function () {
              return React_Basic_Hooks_Internal.discard(React_Basic_Hooks_Internal.ixBindRender)(React_Basic_Hooks.useEffect(Data_Eq.eqUnit)(Data_Unit.unit)(function () {
                if (v2.value0 instanceof Data_Maybe.Nothing) {
                  return Control_Apply.applySecond(Effect.applyEffect)(replace(fallbackScreen))(Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit))));
                }

                ;

                if (v2.value0 instanceof Data_Maybe.Just) {
                  return Control_Applicative.pure(Effect.applicativeEffect)(Data_Monoid.mempty(Effect.monoidEffect(Data_Monoid.monoidUnit)));
                }

                ;
                throw new Error("Failed pattern match at ZAP.Trovex.Client.Hooks.UseScreenManager (line 65, column 13 - line 67, column 36): " + [v2.value0.constructor.name]);
              }()))(function () {
                return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))({
                  currScreen: v2.value0,
                  "switch": $$switch,
                  goBack: v.goBack,
                  reset: reset
                });
              });
            });
          });
        });
      }));
    };
  };
};

module.exports = {
  UseScreenManager: UseScreenManager,
  useScreenManager: useScreenManager,
  ntUseScreenManager: ntUseScreenManager
};