"use strict";

exports.arrayBind = function (arr) {
  return function (f) {
    var result = [];

    for (var i = 0, l = arr.length; i < l; i++) {
      Array.prototype.push.apply(result, f(arr[i]));
    }

    return result;
  };
};