// Generated by purs version 0.13.8
"use strict";

var Data_Eq = require("../Data.Eq/index.js");

var Data_Generic_Rep = require("../Data.Generic.Rep/index.js");

var Data_Generic_Rep_Show = require("../Data.Generic.Rep.Show/index.js");

var Data_Maybe = require("../Data.Maybe/index.js");

var Data_Show = require("../Data.Show/index.js");

var Data_Symbol = require("../Data.Symbol/index.js");

var ZRPC_ConsPrefix = require("../ZRPC.ConsPrefix/index.js");

var ZRPC_ZRPCRead = require("../ZRPC.ZRPCRead/index.js");

var ZRPC_ZRPCWrite = require("../ZRPC.ZRPCWrite/index.js");

var Globe = function () {
  function Globe() {}

  ;
  Globe.value = new Globe();
  return Globe;
}();

var Smart = function () {
  function Smart() {}

  ;
  Smart.value = new Smart();
  return Smart;
}();

var ValidateCodeErrInvalidCode = function () {
  function ValidateCodeErrInvalidCode() {}

  ;
  ValidateCodeErrInvalidCode.value = new ValidateCodeErrInvalidCode();
  return ValidateCodeErrInvalidCode;
}();

var ValidateCodeErrAlreadyUsed = function () {
  function ValidateCodeErrAlreadyUsed() {}

  ;
  ValidateCodeErrAlreadyUsed.value = new ValidateCodeErrAlreadyUsed();
  return ValidateCodeErrAlreadyUsed;
}();

var ValidateCodeErrInvalidUser = function () {
  function ValidateCodeErrInvalidUser() {}

  ;
  ValidateCodeErrInvalidUser.value = new ValidateCodeErrInvalidUser();
  return ValidateCodeErrInvalidUser;
}();

var TerminateCodeErrInvalidCode = function () {
  function TerminateCodeErrInvalidCode() {}

  ;
  TerminateCodeErrInvalidCode.value = new TerminateCodeErrInvalidCode();
  return TerminateCodeErrInvalidCode;
}();

var TerminateCodeErrTerminateFailed = function () {
  function TerminateCodeErrTerminateFailed() {}

  ;
  TerminateCodeErrTerminateFailed.value = new TerminateCodeErrTerminateFailed();
  return TerminateCodeErrTerminateFailed;
}();

var TerminateCodeErrInvalidStatus = function () {
  function TerminateCodeErrInvalidStatus() {}

  ;
  TerminateCodeErrInvalidStatus.value = new TerminateCodeErrInvalidStatus();
  return TerminateCodeErrInvalidStatus;
}();

var TerminateCodeErrAlreadyTerminated = function () {
  function TerminateCodeErrAlreadyTerminated() {}

  ;
  TerminateCodeErrAlreadyTerminated.value = new TerminateCodeErrAlreadyTerminated();
  return TerminateCodeErrAlreadyTerminated;
}();

var RedemptionStatusSuccess = function () {
  function RedemptionStatusSuccess() {}

  ;
  RedemptionStatusSuccess.value = new RedemptionStatusSuccess();
  return RedemptionStatusSuccess;
}();

var RedemptionStatusFailed = function () {
  function RedemptionStatusFailed() {}

  ;
  RedemptionStatusFailed.value = new RedemptionStatusFailed();
  return RedemptionStatusFailed;
}();

var RedemptionStatusPending = function () {
  function RedemptionStatusPending() {}

  ;
  RedemptionStatusPending.value = new RedemptionStatusPending();
  return RedemptionStatusPending;
}();

var RedemptionStatusRequested = function () {
  function RedemptionStatusRequested() {}

  ;
  RedemptionStatusRequested.value = new RedemptionStatusRequested();
  return RedemptionStatusRequested;
}();

var RedeemCodeErrUnauthorized = function () {
  function RedeemCodeErrUnauthorized() {}

  ;
  RedeemCodeErrUnauthorized.value = new RedeemCodeErrUnauthorized();
  return RedeemCodeErrUnauthorized;
}();

var RedeemCodeErrInvalidMobileNumber = function () {
  function RedeemCodeErrInvalidMobileNumber() {}

  ;
  RedeemCodeErrInvalidMobileNumber.value = new RedeemCodeErrInvalidMobileNumber();
  return RedeemCodeErrInvalidMobileNumber;
}();

var RedeemCodeErrInvalidCode = function () {
  function RedeemCodeErrInvalidCode() {}

  ;
  RedeemCodeErrInvalidCode.value = new RedeemCodeErrInvalidCode();
  return RedeemCodeErrInvalidCode;
}();

var RedeemCodeErrAlreadyUsed = function () {
  function RedeemCodeErrAlreadyUsed() {}

  ;
  RedeemCodeErrAlreadyUsed.value = new RedeemCodeErrAlreadyUsed();
  return RedeemCodeErrAlreadyUsed;
}();

var RedeemCodeErrSessionTimeout = function () {
  function RedeemCodeErrSessionTimeout() {}

  ;
  RedeemCodeErrSessionTimeout.value = new RedeemCodeErrSessionTimeout();
  return RedeemCodeErrSessionTimeout;
}();

var RedeemCodeErrRedeemFailed = function () {
  function RedeemCodeErrRedeemFailed() {}

  ;
  RedeemCodeErrRedeemFailed.value = new RedeemCodeErrRedeemFailed();
  return RedeemCodeErrRedeemFailed;
}();

var ExportCodesStatusSuccess = function () {
  function ExportCodesStatusSuccess() {}

  ;
  ExportCodesStatusSuccess.value = new ExportCodesStatusSuccess();
  return ExportCodesStatusSuccess;
}();

var ExportCodesStatusFailed = function () {
  function ExportCodesStatusFailed() {}

  ;
  ExportCodesStatusFailed.value = new ExportCodesStatusFailed();
  return ExportCodesStatusFailed;
}();

var CreateCodesErrInvalidProduct = function () {
  function CreateCodesErrInvalidProduct() {}

  ;
  CreateCodesErrInvalidProduct.value = new CreateCodesErrInvalidProduct();
  return CreateCodesErrInvalidProduct;
}();

var CreateCodesGenerationFailed = function () {
  function CreateCodesGenerationFailed() {}

  ;
  CreateCodesGenerationFailed.value = new CreateCodesGenerationFailed();
  return CreateCodesGenerationFailed;
}();

var Available = function () {
  function Available() {}

  ;
  Available.value = new Available();
  return Available;
}();

var Redeemed = function () {
  function Redeemed() {}

  ;
  Redeemed.value = new Redeemed();
  return Redeemed;
}();

var Pending = function () {
  function Pending() {}

  ;
  Pending.value = new Pending();
  return Pending;
}();

var Terminated = function () {
  function Terminated() {}

  ;
  Terminated.value = new Terminated();
  return Terminated;
}();

var toVendor = function (vendor) {
  return Data_Maybe.fromJust()(function () {
    if (vendor === "Smart") {
      return new Data_Maybe.Just(Smart.value);
    }

    ;

    if (vendor === "Globe") {
      return new Data_Maybe.Just(Globe.value);
    }

    ;
    return Data_Maybe.Nothing.value;
  }());
};

var toCodeStatus = function (codeStatus) {
  return Data_Maybe.fromJust()(function () {
    if (codeStatus === "AVAILABLE") {
      return new Data_Maybe.Just(Available.value);
    }

    ;

    if (codeStatus === "REDEEMED") {
      return new Data_Maybe.Just(Redeemed.value);
    }

    ;

    if (codeStatus === "PENDING") {
      return new Data_Maybe.Just(Pending.value);
    }

    ;

    if (codeStatus === "TERMINATED") {
      return new Data_Maybe.Just(Terminated.value);
    }

    ;
    return Data_Maybe.Nothing.value;
  }());
};

var showCodeStatus = new Data_Show.Show(function (v) {
  if (v instanceof Available) {
    return "AVAILABLE";
  }

  ;

  if (v instanceof Redeemed) {
    return "REDEEMED";
  }

  ;

  if (v instanceof Pending) {
    return "PENDING";
  }

  ;

  if (v instanceof Terminated) {
    return "TERMINATED";
  }

  ;
  throw new Error("Failed pattern match at ZAP.Trovex.Common (line 145, column 1 - line 149, column 33): " + [v.constructor.name]);
});
var redemptionCodeLength = 12;
var genericVendor = new Data_Generic_Rep.Generic(function (x) {
  if (x instanceof Globe) {
    return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
  }

  ;

  if (x instanceof Smart) {
    return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
  }

  ;
  throw new Error("Failed pattern match at ZAP.Trovex.Common (line 38, column 1 - line 38, column 50): " + [x.constructor.name]);
}, function (x) {
  if (x instanceof Data_Generic_Rep.Inl) {
    return Globe.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr) {
    return Smart.value;
  }

  ;
  throw new Error("Failed pattern match at ZAP.Trovex.Common (line 38, column 1 - line 38, column 50): " + [x.constructor.name]);
});
var showVendor = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericVendor)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
  return "Globe";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
  return "Smart";
})))));
var genericValidateCodeErr = new Data_Generic_Rep.Generic(function (x) {
  if (x instanceof ValidateCodeErrInvalidCode) {
    return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
  }

  ;

  if (x instanceof ValidateCodeErrAlreadyUsed) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
  }

  ;

  if (x instanceof ValidateCodeErrInvalidUser) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value));
  }

  ;
  throw new Error("Failed pattern match at ZAP.Trovex.Common (line 199, column 1 - line 199, column 68): " + [x.constructor.name]);
}, function (x) {
  if (x instanceof Data_Generic_Rep.Inl) {
    return ValidateCodeErrInvalidCode.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
    return ValidateCodeErrAlreadyUsed.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr) {
    return ValidateCodeErrInvalidUser.value;
  }

  ;
  throw new Error("Failed pattern match at ZAP.Trovex.Common (line 199, column 1 - line 199, column 68): " + [x.constructor.name]);
});
var showValidateCodeErr = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericValidateCodeErr)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
  return "ValidateCodeErrInvalidCode";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
  return "ValidateCodeErrAlreadyUsed";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
  return "ValidateCodeErrInvalidUser";
}))))));
var genericTerminateCodeErr = new Data_Generic_Rep.Generic(function (x) {
  if (x instanceof TerminateCodeErrInvalidCode) {
    return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
  }

  ;

  if (x instanceof TerminateCodeErrTerminateFailed) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
  }

  ;

  if (x instanceof TerminateCodeErrInvalidStatus) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
  }

  ;

  if (x instanceof TerminateCodeErrAlreadyTerminated) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
  }

  ;
  throw new Error("Failed pattern match at ZAP.Trovex.Common (line 228, column 1 - line 228, column 70): " + [x.constructor.name]);
}, function (x) {
  if (x instanceof Data_Generic_Rep.Inl) {
    return TerminateCodeErrInvalidCode.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
    return TerminateCodeErrTerminateFailed.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl) {
    return TerminateCodeErrInvalidStatus.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr) {
    return TerminateCodeErrAlreadyTerminated.value;
  }

  ;
  throw new Error("Failed pattern match at ZAP.Trovex.Common (line 228, column 1 - line 228, column 70): " + [x.constructor.name]);
});
var showTerminateCodeErr = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericTerminateCodeErr)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
  return "TerminateCodeErrInvalidCode";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
  return "TerminateCodeErrTerminateFailed";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
  return "TerminateCodeErrInvalidStatus";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
  return "TerminateCodeErrAlreadyTerminated";
})))))));
var genericRedemptionStatusType = new Data_Generic_Rep.Generic(function (x) {
  if (x instanceof RedemptionStatusSuccess) {
    return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
  }

  ;

  if (x instanceof RedemptionStatusFailed) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
  }

  ;

  if (x instanceof RedemptionStatusPending) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
  }

  ;

  if (x instanceof RedemptionStatusRequested) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
  }

  ;
  throw new Error("Failed pattern match at ZAP.Trovex.Common (line 275, column 1 - line 275, column 78): " + [x.constructor.name]);
}, function (x) {
  if (x instanceof Data_Generic_Rep.Inl) {
    return RedemptionStatusSuccess.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
    return RedemptionStatusFailed.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl) {
    return RedemptionStatusPending.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr) {
    return RedemptionStatusRequested.value;
  }

  ;
  throw new Error("Failed pattern match at ZAP.Trovex.Common (line 275, column 1 - line 275, column 78): " + [x.constructor.name]);
});
var showRedemptionStatusType = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericRedemptionStatusType)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
  return "RedemptionStatusSuccess";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
  return "RedemptionStatusFailed";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
  return "RedemptionStatusPending";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
  return "RedemptionStatusRequested";
})))))));
var genericRedeemCodeErr = new Data_Generic_Rep.Generic(function (x) {
  if (x instanceof RedeemCodeErrUnauthorized) {
    return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
  }

  ;

  if (x instanceof RedeemCodeErrInvalidMobileNumber) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
  }

  ;

  if (x instanceof RedeemCodeErrInvalidCode) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
  }

  ;

  if (x instanceof RedeemCodeErrAlreadyUsed) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value))));
  }

  ;

  if (x instanceof RedeemCodeErrSessionTimeout) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)))));
  }

  ;

  if (x instanceof RedeemCodeErrRedeemFailed) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)))));
  }

  ;
  throw new Error("Failed pattern match at ZAP.Trovex.Common (line 297, column 1 - line 297, column 64): " + [x.constructor.name]);
}, function (x) {
  if (x instanceof Data_Generic_Rep.Inl) {
    return RedeemCodeErrUnauthorized.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
    return RedeemCodeErrInvalidMobileNumber.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl) {
    return RedeemCodeErrInvalidCode.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inl) {
    return RedeemCodeErrAlreadyUsed.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inl) {
    return RedeemCodeErrSessionTimeout.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0.value0.value0 instanceof Data_Generic_Rep.Inr) {
    return RedeemCodeErrRedeemFailed.value;
  }

  ;
  throw new Error("Failed pattern match at ZAP.Trovex.Common (line 297, column 1 - line 297, column 64): " + [x.constructor.name]);
});
var showRedeemCodeErr = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericRedeemCodeErr)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
  return "RedeemCodeErrUnauthorized";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
  return "RedeemCodeErrInvalidMobileNumber";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
  return "RedeemCodeErrInvalidCode";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
  return "RedeemCodeErrAlreadyUsed";
})))(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
  return "RedeemCodeErrSessionTimeout";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
  return "RedeemCodeErrRedeemFailed";
})))))))));
var genericExportCodesStatus = new Data_Generic_Rep.Generic(function (x) {
  if (x instanceof ExportCodesStatusSuccess) {
    return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
  }

  ;

  if (x instanceof ExportCodesStatusFailed) {
    return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
  }

  ;
  throw new Error("Failed pattern match at ZAP.Trovex.Common (line 56, column 1 - line 56, column 72): " + [x.constructor.name]);
}, function (x) {
  if (x instanceof Data_Generic_Rep.Inl) {
    return ExportCodesStatusSuccess.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr) {
    return ExportCodesStatusFailed.value;
  }

  ;
  throw new Error("Failed pattern match at ZAP.Trovex.Common (line 56, column 1 - line 56, column 72): " + [x.constructor.name]);
});
var showExportCodesStatus = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericExportCodesStatus)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
  return "ExportCodesStatusSuccess";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
  return "ExportCodesStatusFailed";
})))));
var genericCreateCodesErr = new Data_Generic_Rep.Generic(function (x) {
  if (x instanceof CreateCodesErrInvalidProduct) {
    return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
  }

  ;

  if (x instanceof CreateCodesGenerationFailed) {
    return new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value);
  }

  ;
  throw new Error("Failed pattern match at ZAP.Trovex.Common (line 104, column 1 - line 104, column 66): " + [x.constructor.name]);
}, function (x) {
  if (x instanceof Data_Generic_Rep.Inl) {
    return CreateCodesErrInvalidProduct.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr) {
    return CreateCodesGenerationFailed.value;
  }

  ;
  throw new Error("Failed pattern match at ZAP.Trovex.Common (line 104, column 1 - line 104, column 66): " + [x.constructor.name]);
});
var showCreateCodesErr = new Data_Show.Show(Data_Generic_Rep_Show.genericShow(genericCreateCodesErr)(Data_Generic_Rep_Show.genericShowSum(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
  return "CreateCodesErrInvalidProduct";
})))(Data_Generic_Rep_Show.genericShowConstructor(Data_Generic_Rep_Show.genericShowArgsNoArguments)(new Data_Symbol.IsSymbol(function () {
  return "CreateCodesGenerationFailed";
})))));
var genericCodeStatus = new Data_Generic_Rep.Generic(function (x) {
  if (x instanceof Available) {
    return new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value);
  }

  ;

  if (x instanceof Redeemed) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value));
  }

  ;

  if (x instanceof Pending) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inl(Data_Generic_Rep.NoArguments.value)));
  }

  ;

  if (x instanceof Terminated) {
    return new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(new Data_Generic_Rep.Inr(Data_Generic_Rep.NoArguments.value)));
  }

  ;
  throw new Error("Failed pattern match at ZAP.Trovex.Common (line 124, column 1 - line 124, column 58): " + [x.constructor.name]);
}, function (x) {
  if (x instanceof Data_Generic_Rep.Inl) {
    return Available.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inl) {
    return Redeemed.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inl) {
    return Pending.value;
  }

  ;

  if (x instanceof Data_Generic_Rep.Inr && x.value0 instanceof Data_Generic_Rep.Inr && x.value0.value0 instanceof Data_Generic_Rep.Inr) {
    return Terminated.value;
  }

  ;
  throw new Error("Failed pattern match at ZAP.Trovex.Common (line 124, column 1 - line 124, column 58): " + [x.constructor.name]);
});
var eqVendor = new Data_Eq.Eq(function (x) {
  return function (y) {
    if (x instanceof Globe && y instanceof Globe) {
      return true;
    }

    ;

    if (x instanceof Smart && y instanceof Smart) {
      return true;
    }

    ;
    return false;
  };
});
var eqCodeStatus = new Data_Eq.Eq(function (x) {
  return function (y) {
    if (x instanceof Available && y instanceof Available) {
      return true;
    }

    ;

    if (x instanceof Redeemed && y instanceof Redeemed) {
      return true;
    }

    ;

    if (x instanceof Pending && y instanceof Pending) {
      return true;
    }

    ;

    if (x instanceof Terminated && y instanceof Terminated) {
      return true;
    }

    ;
    return false;
  };
});
var consPrefixVendor = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
  return "";
});
var zrpcReadVendor = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixVendor)(genericVendor)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "Globe";
})))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "Smart";
})))));
var zrpcWriteVendor = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixVendor)(genericVendor)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "Globe";
})))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "Smart";
})))));
var consPrefixValidateCodeErr = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
  return "ValidateCodeErr";
});
var zrpcReadValidateCodeErr = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixValidateCodeErr)(genericValidateCodeErr)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "ValidateCodeErrInvalidCode";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "ValidateCodeErrAlreadyUsed";
})))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "ValidateCodeErrInvalidUser";
}))))));
var zrpcWriteValidateCodeErr = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixValidateCodeErr)(genericValidateCodeErr)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "ValidateCodeErrInvalidCode";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "ValidateCodeErrAlreadyUsed";
})))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "ValidateCodeErrInvalidUser";
}))))));
var consPrefixTerminateCodeErr = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
  return "TerminateCodeErr";
});
var zrpcReadTerminateCodeErr = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixTerminateCodeErr)(genericTerminateCodeErr)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "TerminateCodeErrInvalidCode";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "TerminateCodeErrTerminateFailed";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "TerminateCodeErrInvalidStatus";
})))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "TerminateCodeErrAlreadyTerminated";
})))))));
var zrpcWriteTerminateCodeErr = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixTerminateCodeErr)(genericTerminateCodeErr)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "TerminateCodeErrInvalidCode";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "TerminateCodeErrTerminateFailed";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "TerminateCodeErrInvalidStatus";
})))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "TerminateCodeErrAlreadyTerminated";
})))))));
var consPrefixRedemptionStatusType = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
  return "RedemptionStatus";
});
var zrpcReadRedemptionStatusType = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixRedemptionStatusType)(genericRedemptionStatusType)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "RedemptionStatusSuccess";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "RedemptionStatusFailed";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "RedemptionStatusPending";
})))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "RedemptionStatusRequested";
})))))));
var zrpcWriteRedemptionStatusType = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixRedemptionStatusType)(genericRedemptionStatusType)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "RedemptionStatusSuccess";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "RedemptionStatusFailed";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "RedemptionStatusPending";
})))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "RedemptionStatusRequested";
})))))));
var consPrefixRedeemCodeErr = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
  return "RedeemCodeErr";
});
var zrpcReadRedeemCodeErr = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixRedeemCodeErr)(genericRedeemCodeErr)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "RedeemCodeErrUnauthorized";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "RedeemCodeErrInvalidMobileNumber";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "RedeemCodeErrInvalidCode";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "RedeemCodeErrAlreadyUsed";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "RedeemCodeErrSessionTimeout";
})))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "RedeemCodeErrRedeemFailed";
})))))))));
var zrpcWriteRedeemCodeErr = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixRedeemCodeErr)(genericRedeemCodeErr)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "RedeemCodeErrUnauthorized";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "RedeemCodeErrInvalidMobileNumber";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "RedeemCodeErrInvalidCode";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "RedeemCodeErrAlreadyUsed";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "RedeemCodeErrSessionTimeout";
})))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "RedeemCodeErrRedeemFailed";
})))))))));
var consPrefixExportCodesStatus = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
  return "ExportCodesStatus";
});
var zrpcReadExportCodesStatus = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixExportCodesStatus)(genericExportCodesStatus)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "ExportCodesStatusSuccess";
})))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "ExportCodesStatusFailed";
})))));
var zrpcWriteExportCodesStatus = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixExportCodesStatus)(genericExportCodesStatus)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "ExportCodesStatusSuccess";
})))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "ExportCodesStatusFailed";
})))));
var consPrefixCreateCodesErr = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
  return "CreateCodesErr";
});
var zrpcReadCreateCodesErr = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixCreateCodesErr)(genericCreateCodesErr)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "CreateCodesErrInvalidProduct";
})))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "CreateCodesGenerationFailed";
})))));
var zrpcWriteCreateCodesErr = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixCreateCodesErr)(genericCreateCodesErr)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "CreateCodesErrInvalidProduct";
})))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "CreateCodesGenerationFailed";
})))));
var consPrefixCodeStatus = new ZRPC_ConsPrefix.ConsPrefix(function (v) {
  return "CodeStatus";
});
var zrpcReadCodeStatus = new ZRPC_ZRPCRead.ZRPCRead(ZRPC_ZRPCRead.genericZRPCRead(consPrefixCodeStatus)(genericCodeStatus)(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "Available";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "Redeemed";
})))(ZRPC_ZRPCRead.readRepSum(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "Pending";
})))(ZRPC_ZRPCRead.readRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "Terminated";
})))))));
var zrpcWriteCodeStatus = new ZRPC_ZRPCWrite.ZRPCWrite(ZRPC_ZRPCWrite.genericZRPCWrite(consPrefixCodeStatus)(genericCodeStatus)(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "Available";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "Redeemed";
})))(ZRPC_ZRPCWrite.writeRepSum(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "Pending";
})))(ZRPC_ZRPCWrite.writeRepNoArgs(new Data_Symbol.IsSymbol(function () {
  return "Terminated";
})))))));
module.exports = {
  redemptionCodeLength: redemptionCodeLength,
  Globe: Globe,
  Smart: Smart,
  toVendor: toVendor,
  ExportCodesStatusSuccess: ExportCodesStatusSuccess,
  ExportCodesStatusFailed: ExportCodesStatusFailed,
  CreateCodesErrInvalidProduct: CreateCodesErrInvalidProduct,
  CreateCodesGenerationFailed: CreateCodesGenerationFailed,
  Available: Available,
  Redeemed: Redeemed,
  Pending: Pending,
  Terminated: Terminated,
  toCodeStatus: toCodeStatus,
  ValidateCodeErrInvalidCode: ValidateCodeErrInvalidCode,
  ValidateCodeErrAlreadyUsed: ValidateCodeErrAlreadyUsed,
  ValidateCodeErrInvalidUser: ValidateCodeErrInvalidUser,
  TerminateCodeErrInvalidCode: TerminateCodeErrInvalidCode,
  TerminateCodeErrTerminateFailed: TerminateCodeErrTerminateFailed,
  TerminateCodeErrInvalidStatus: TerminateCodeErrInvalidStatus,
  TerminateCodeErrAlreadyTerminated: TerminateCodeErrAlreadyTerminated,
  RedemptionStatusSuccess: RedemptionStatusSuccess,
  RedemptionStatusFailed: RedemptionStatusFailed,
  RedemptionStatusPending: RedemptionStatusPending,
  RedemptionStatusRequested: RedemptionStatusRequested,
  RedeemCodeErrUnauthorized: RedeemCodeErrUnauthorized,
  RedeemCodeErrInvalidMobileNumber: RedeemCodeErrInvalidMobileNumber,
  RedeemCodeErrInvalidCode: RedeemCodeErrInvalidCode,
  RedeemCodeErrAlreadyUsed: RedeemCodeErrAlreadyUsed,
  RedeemCodeErrSessionTimeout: RedeemCodeErrSessionTimeout,
  RedeemCodeErrRedeemFailed: RedeemCodeErrRedeemFailed,
  eqVendor: eqVendor,
  genericVendor: genericVendor,
  consPrefixVendor: consPrefixVendor,
  zrpcReadVendor: zrpcReadVendor,
  zrpcWriteVendor: zrpcWriteVendor,
  showVendor: showVendor,
  genericExportCodesStatus: genericExportCodesStatus,
  consPrefixExportCodesStatus: consPrefixExportCodesStatus,
  zrpcReadExportCodesStatus: zrpcReadExportCodesStatus,
  zrpcWriteExportCodesStatus: zrpcWriteExportCodesStatus,
  showExportCodesStatus: showExportCodesStatus,
  genericCreateCodesErr: genericCreateCodesErr,
  consPrefixCreateCodesErr: consPrefixCreateCodesErr,
  zrpcReadCreateCodesErr: zrpcReadCreateCodesErr,
  zrpcWriteCreateCodesErr: zrpcWriteCreateCodesErr,
  showCreateCodesErr: showCreateCodesErr,
  genericCodeStatus: genericCodeStatus,
  consPrefixCodeStatus: consPrefixCodeStatus,
  zrpcReadCodeStatus: zrpcReadCodeStatus,
  zrpcWriteCodeStatus: zrpcWriteCodeStatus,
  showCodeStatus: showCodeStatus,
  eqCodeStatus: eqCodeStatus,
  genericValidateCodeErr: genericValidateCodeErr,
  consPrefixValidateCodeErr: consPrefixValidateCodeErr,
  zrpcReadValidateCodeErr: zrpcReadValidateCodeErr,
  zrpcWriteValidateCodeErr: zrpcWriteValidateCodeErr,
  showValidateCodeErr: showValidateCodeErr,
  genericTerminateCodeErr: genericTerminateCodeErr,
  consPrefixTerminateCodeErr: consPrefixTerminateCodeErr,
  zrpcReadTerminateCodeErr: zrpcReadTerminateCodeErr,
  zrpcWriteTerminateCodeErr: zrpcWriteTerminateCodeErr,
  showTerminateCodeErr: showTerminateCodeErr,
  genericRedemptionStatusType: genericRedemptionStatusType,
  consPrefixRedemptionStatusType: consPrefixRedemptionStatusType,
  zrpcReadRedemptionStatusType: zrpcReadRedemptionStatusType,
  zrpcWriteRedemptionStatusType: zrpcWriteRedemptionStatusType,
  showRedemptionStatusType: showRedemptionStatusType,
  genericRedeemCodeErr: genericRedeemCodeErr,
  consPrefixRedeemCodeErr: consPrefixRedeemCodeErr,
  zrpcReadRedeemCodeErr: zrpcReadRedeemCodeErr,
  zrpcWriteRedeemCodeErr: zrpcWriteRedeemCodeErr,
  showRedeemCodeErr: showRedeemCodeErr
};