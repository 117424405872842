import styled, { css } from 'styled-components';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const Container = styled.div`
  padding: 45px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const regFont = css`
  font-family: ProximaNova-Regular;
  font-size: 16px;
`;

export const displayStyle = css`
  border-radius: 10px;
  padding: 30px 32px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(243, 243, 243);
  box-sizing: border-box;
`;

export const SmallVendorLogo = styled.img`
  max-height: 50px;
  max-width: 100px;
`;

export const PImg = styled.img`
  margin-top: 20px;
  max-height: 100px;
  max-width: 160px;
`;

export const showMobile = (mobile: string) => {
  const pn = parsePhoneNumberFromString(mobile);
  if (pn) {
    return pn.formatInternational();
  }
  return mobile;
};
