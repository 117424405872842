// Generated by purs version 0.13.8
"use strict";

var Control_Applicative = require("../Control.Applicative/index.js");

var Control_Bind = require("../Control.Bind/index.js");

var Data_Tuple = require("../Data.Tuple/index.js");

var MonadTell = function (Monad0, tell) {
  this.Monad0 = Monad0;
  this.tell = tell;
};

var MonadWriter = function (MonadTell0, listen, pass) {
  this.MonadTell0 = MonadTell0;
  this.listen = listen;
  this.pass = pass;
};

var tell = function (dict) {
  return dict.tell;
};

var pass = function (dict) {
  return dict.pass;
};

var listen = function (dict) {
  return dict.listen;
};

var listens = function (dictMonadWriter) {
  return function (f) {
    return function (m) {
      return Control_Bind.bind(dictMonadWriter.MonadTell0().Monad0().Bind1())(listen(dictMonadWriter)(m))(function (v) {
        return Control_Applicative.pure(dictMonadWriter.MonadTell0().Monad0().Applicative0())(new Data_Tuple.Tuple(v.value0, f(v.value1)));
      });
    };
  };
};

var censor = function (dictMonadWriter) {
  return function (f) {
    return function (m) {
      return pass(dictMonadWriter)(Control_Bind.bind(dictMonadWriter.MonadTell0().Monad0().Bind1())(m)(function (a) {
        return Control_Applicative.pure(dictMonadWriter.MonadTell0().Monad0().Applicative0())(new Data_Tuple.Tuple(a, f));
      }));
    };
  };
};

module.exports = {
  listen: listen,
  pass: pass,
  tell: tell,
  MonadTell: MonadTell,
  MonadWriter: MonadWriter,
  listens: listens,
  censor: censor
};